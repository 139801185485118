import introJs from 'intro.js';
import type { Options } from 'intro.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getUserFromLocalStorage } from '../useAuth';

type IntroKeys = 'intro-solar-proposal' | 'intro-storybook-showcase';

export const getIsIntroCompleted = (introKey: IntroKeys) => {
  const user = getUserFromLocalStorage();
  const localStorageKey = `${user?.id}-${introKey}`;
  const introStatus = localStorage.getItem(localStorageKey);
  return introStatus ? !!JSON.parse(introStatus) : false;
};

export const setIntroStatus = (introKey: IntroKeys, isCompleted = true) => {
  const user = getUserFromLocalStorage();
  const localStorageKey = `${user?.id}-${introKey}`;
  localStorage.setItem(localStorageKey, JSON.stringify(isCompleted));
};

interface UseIntroParams {
  introKey: IntroKeys;
  targetElement?: string;
  start?: boolean;
}

const useIntro = ({ introKey, targetElement }: UseIntroParams) => {
  const intro = useMemo(
    () => (targetElement ? introJs(targetElement) : introJs()),
    [targetElement]
  );

  useEffect(() => {
    const onCompleteOrExit = () => setIntroStatus(introKey, true);
    intro.oncomplete(onCompleteOrExit);
    intro.onexit(onCompleteOrExit);
  }, [intro, introKey]);

  const exit = useCallback(() => {
    intro.exit();
  }, [intro]);

  const [startOnNextRender, setStartOnNextRender] = useState(false);

  const startIntro = useCallback(
    (options?: Options) => {
      intro.refresh();
      if (options) intro.setOptions(options);
      setStartOnNextRender(true);
    },
    [intro]
  );

  useEffect(() => {
    if (startOnNextRender) {
      intro.start();
      setStartOnNextRender(false);
    }
  }, [startOnNextRender, setStartOnNextRender]);

  const startFirstTimeIntro = useCallback(
    (options?: Options) => {
      intro.refresh();
      const isIntroCompleted = getIsIntroCompleted(introKey);
      if (!isIntroCompleted) startIntro(options);
    },
    [startIntro, introKey]
  );

  useEffect(() => {
    window.addEventListener('popstate', exit);
    document.addEventListener('scroll', exit);
    return () => {
      document.removeEventListener('scroll', exit);
      window.addEventListener('popstate', exit);
    };
  }, [exit]);

  return {
    startIntro,
    startFirstTimeIntro,
  };
};

export default useIntro;
