import React from 'react';
import { useForm } from 'antd/es/form/Form';
import {
  ButtonAnt,
  Divider,
  Form,
  FormItemLabel,
  InputNumber,
  Popover,
} from '~/UI';
import PSP_INPUT_CONFIG from '~/constants/inputs/project/projectSpecificPricing';
import styled from '@emotion/styled';
import { SolarProject } from '~/store/project';
import { ModuleData } from './ModuleCard';
import { SmallLabel } from '~/UI/Typography';
import { IconInfo } from '~/UI/Icons';

export type ModuleProjectSpecificPricingFormValues = {
  ddp_east_USD_per_W: number;
};

type ModuleProjectSpecificPricingFormProps = {
  onSave: (values: ModuleProjectSpecificPricingFormValues) => void;
  onCancel: () => void;
  currentQuarter: string;
};

const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 20px;
`;

const ModulePSPForm = ({
  onCancel,
  currentQuarter,
  onSave,
}: ModuleProjectSpecificPricingFormProps) => {
  const [form] = useForm<ModuleProjectSpecificPricingFormValues>();

  const onSubmit = (values: ModuleProjectSpecificPricingFormValues) => {
    onSave(values);
    // TODO: call api to save custom price
  };

  const currentSelectedQuarter = Number(currentQuarter.split('-')[1]);
  const currentSelectedYear = Number(currentQuarter.split('-')[0]);
  const quarterYearDisplay = `Q${currentSelectedQuarter} ${currentSelectedYear}`;

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item
        name="custom_price"
        label={
          <FormItemLabel>
            Custom Custom Price
            <Popover
              zIndex={1500}
              title="Custom Custom Price"
              content={
                <span style={{ maxWidth: 350 }}>
                  This price represents the full cost to deliver a module to
                  site, including any purchase price, shipping costs, tariffs,
                  and/or adders. Sales tax excluded.
                </span>
              }
            >
              <IconInfo width={25} />
            </Popover>
          </FormItemLabel>
        }
        labelCol={{ span: 24 }}
        rules={PSP_INPUT_CONFIG.custom_price.rules}
      >
        <InputNumber
          data-testid="custom_price"
          {...PSP_INPUT_CONFIG.custom_price.inputNumberProps}
        />
      </Form.Item>
      <Divider />
      <Footer>
        <SmallLabel
          style={{
            marginRight: 'auto',
          }}
        >{`* Effective for ${quarterYearDisplay}`}</SmallLabel>
        <ButtonAnt size="small" htmlType="submit" onClick={onCancel}>
          Cancel
        </ButtonAnt>
        <ButtonAnt size="small" type="primary" htmlType="submit">
          Save
        </ButtonAnt>
      </Footer>
    </Form>
  );
};

export default ModulePSPForm;
