import React from 'react';
import styled from '@emotion/styled';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Checkbox, Form } from '~/UI';

import { colors, fonts } from '~/utils';
import {
  GTM_EVENTS,
  GTM_SOURCE_TYPES,
  maybeSendPlatformEventToGTM,
} from '~/utils/gtm';

import { FilterConfig } from '../Filters/FilterForm';

export const StyledCheckBoxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ant-checkbox-wrapper {
    margin-left: 0;
    font-weight: 400;
    font-size: ${fonts.sizes.button};
    line-height: 22px;
    color: ${colors.gray800};
    max-width: 100%;
    white-space: normal;
    text-align: left;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-disabled + span {
    color: ${colors.gray400};
  }
  .ant-checkbox-inner {
    border-radius: 2px;
    border: 2px solid ${colors.gray600};
    min-width: 18px;
    min-height: 18px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: 2px;
    border: 2px solid ${colors.primary};
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: transparent;
  }
`;

export type Option = {
  label: React.ReactNode;
  value: string;
  dataTestId?: string;
};

export type FilterProps = {
  filter: FilterConfig;
  includeGtmProperties?: boolean;
  className?: string;
};

const Filter = ({
  filter,
  includeGtmProperties = false,
  className,
}: FilterProps) => {
  const { name, options } = filter;
  return (
    <Form.Item className={className} name={name} labelCol={{ span: 24 }}>
      <StyledCheckBoxGroup>
        {options.map(({ label, value, dataTestId }) => {
          const infoFilterAnalytics = includeGtmProperties
            ? {
                'data-gtm-type': GTM_SOURCE_TYPES.FILTER,
                'data-gtm-id': value,
              }
            : {};
          return (
            <div key={value} {...infoFilterAnalytics}>
              <StyledCheckbox
                value={value}
                data-testid={dataTestId}
                onChange={
                  includeGtmProperties
                    ? (e: CheckboxChangeEvent) => {
                        const event = e.target.checked
                          ? GTM_EVENTS.CHECK_BOX_CHECKED
                          : GTM_EVENTS.CHECK_BOX_UNCHECKED;
                        maybeSendPlatformEventToGTM(event, value);
                      }
                    : undefined
                }
              >
                {label}
              </StyledCheckbox>
            </div>
          );
        })}
      </StyledCheckBoxGroup>
    </Form.Item>
  );
};

export default Filter;
