import React, { useEffect, useState, memo } from 'react';
import * as R from 'ramda';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import type { ColumnsType } from 'antd/lib/table';
import type { CompanyModulesData, QuartersData } from '~/services/api/anza';
import { theme, generateTitlePopover } from '~/utils';
import { Alert, Table, Tag, Typography, FormInstance } from '~/UI';
import {
  greaterThanValidator,
  inRangeValidator,
  lesserThanValidator,
} from '~/utils/numberValidators';
import EditableInput from '~/components/Anza/EditableInput';
import NoDataAvailable from '~/components/NoDataAvailable';
import ModulesAddersFormGroup from '~/components/ModulesAddersFormGroup';

type QuartersWithYearDisplayAndKey = QuartersData & {
  yearDisplay: string;
  key: string;
};

export type CompanyModulesDataWithYearsDisplayAndKey = Omit<
  CompanyModulesData,
  'quarters'
> & {
  quarters: QuartersWithYearDisplayAndKey[];
  pricing_or_availability_last_updated_at?: string;
  pricing_availability_confirmed_at?: string;
};

interface ModulePricingFormGroupProps {
  moduleData: CompanyModulesDataWithYearsDisplayAndKey;
  lastUpdated: Date;
  isActive: boolean;
  priceRequiredFormNames?: string[];
  availabilityRequiredFormNames?: string[];
  form: FormInstance;
}

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  min-height: 485px;
`;

const ModulePricingContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 2.5fr;
  grid-template-rows: 2fr;
  width: 100%;
  grid-gap: 1rem;
  margin-top: 10px;
`;

const QuarterlyPriceTable = styled(Table<QuartersWithYearDisplayAndKey>)`
  .ant-table-row {
    cursor: pointer;
  }
  .ant-input-number {
    cursor: initial;
  }

  .ant-table-thead > tr > th {
    font-weight: 600;
    height: 40px;
    border: none;
  }

  .ant-table-cell {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .ant-table-cell .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledAlert = styled(Alert)`
  border: none;
  .ant-alert-message {
    color: ${theme.colors.warning};
  }
`;

const BoxAdders = styled.div`
  padding: 0 24px;
`;

const moduleContainerTagStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  marginLeft: '10px',
};

const iconContainerStyles = {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  marginLeft: '20px',
  color: `${theme.colors.warning} !important`,
};

const isModulePricingUpdateOverdue = (lastUpdatedDate: Date) => {
  if (R.isNil(lastUpdatedDate)) {
    return false;
  }
  const twoWeeksAgo = dayjs(new Date()).subtract(14, 'days');
  return twoWeeksAgo > dayjs(lastUpdatedDate);
};

const pricingToolTipText = 'Price ($/W) is based on DDP East Coast port.';
const availabilityToolTipText =
  'Availability (MW) should reflect capacity that is available to be delivered in the specified quarter.';

const modulePricingGroupDataIndex = {
  price: 'ddp_east_coast_port',
  availability: 'availability_mw',
} as const;

export const modulePricingGroupFormNamePrefix = {
  price: `${modulePricingGroupDataIndex.price}_`,
  availability: `${modulePricingGroupDataIndex.availability}_`,
} as const;

const ModulePricingFormGroup: React.FC<ModulePricingFormGroupProps> = ({
  moduleData,
  lastUpdated,
  isActive,
  priceRequiredFormNames,
  availabilityRequiredFormNames,
  form,
}) => {
  const columns: ColumnsType<QuartersWithYearDisplayAndKey> = [
    {
      title: 'Year',
      dataIndex: 'year',
      width: 50,
      render: (_, record) => record.yearDisplay,
    },
    {
      title: 'Quarter',
      dataIndex: 'quarter',
      width: 75,
      align: 'center',
      render: (quarter) => `Q${quarter}`,
    },
    {
      title: generateTitlePopover('Price', pricingToolTipText),
      dataIndex: modulePricingGroupDataIndex.price,
      width: 250,
      render: (_, record) => {
        const priceKeyName = `${modulePricingGroupFormNamePrefix.price}${record.key}`;
        const availabilityKeyName = `${modulePricingGroupFormNamePrefix.availability}${record.key}`;
        const rules = priceRequiredFormNames?.includes(priceKeyName)
          ? [{ required: true, message: 'Clear Availability or add Price' }]
          : [];
        return (
          <EditableInput
            rules={[...rules, inRangeValidator(0, 9, true, '', false)]}
            dataIndex={priceKeyName}
            precision={4}
            displayPrecision={3}
            step={0.0001}
            max={9}
            style={{ padding: '0' }}
            editing
            suffix="$/W"
            onBlur={() => form.validateFields([availabilityKeyName])}
          />
        );
      },
    },
    {
      title: generateTitlePopover('Availability', availabilityToolTipText),
      dataIndex: modulePricingGroupDataIndex.availability,
      width: 250,
      render: (_, record) => {
        const availabilityKeyName = `${modulePricingGroupFormNamePrefix.availability}${record.key}`;
        const priceKeyName = `${modulePricingGroupFormNamePrefix.price}${record.key}`;
        const rules = availabilityRequiredFormNames?.includes(
          availabilityKeyName
        )
          ? [{ required: true, message: 'Clear Price or add Availability' }]
          : [];
        return (
          <EditableInput
            dataIndex={availabilityKeyName}
            precision={1}
            step={0.1}
            style={{ padding: '0' }}
            editing
            suffix="MW"
            rules={[
              ...rules,
              greaterThanValidator(0, false, 'Value cannot be zero.', false),
              lesserThanValidator(
                10000,
                true,
                'Value cannot be greater than 10,000 MW.',
                false
              ),
            ]}
            onBlur={() => form.validateFields([priceKeyName])}
          />
        );
      },
    },
  ];

  const [isPricingOverdue, setIsPricingOverdue] = useState(
    isModulePricingUpdateOverdue(lastUpdated)
  );

  useEffect(() => {
    setIsPricingOverdue(isModulePricingUpdateOverdue(lastUpdated));
  }, [lastUpdated]);

  return (
    <Container>
      <div style={{ display: 'flex', marginBottom: 20 }}>
        <div style={{ display: 'flex' }}>
          <div>
            <Typography.AnzaHeaderTitle style={{ fontSize: '20px' }}>
              {moduleData.name}{' '}
            </Typography.AnzaHeaderTitle>
            {R.isNil(lastUpdated) ? (
              ''
            ) : (
              <div
                style={{
                  fontSize: '14px',
                  lineHeight: '16px',
                  color: theme.colors.anotherGray,
                }}
              >
                Last Updated:{' '}
                {dayjs(lastUpdated, 'YYYY/MM/DD').format('MM/D/YY')}
              </div>
            )}
          </div>
          {!R.isNil(isActive) && !isActive ? (
            <div style={moduleContainerTagStyles}>
              <Tag
                text="inactive"
                color="error"
                style={{
                  fontSize: '1rem',
                }}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div style={iconContainerStyles}>
          {isPricingOverdue ? (
            <StyledAlert
              showIcon
              type="warning"
              message="This module's pricing and availability needs to be confirmed."
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <ModulePricingContainer>
        <div>
          <QuarterlyPriceTable
            rowKey="key"
            columns={columns}
            dataSource={moduleData.quarters}
            pagination={false}
            className="alternate-rows-even"
            locale={{
              emptyText: (
                <NoDataAvailable content="Loading pricing and availability data." />
              ),
            }}
          />
        </div>
        <BoxAdders>
          <ModulesAddersFormGroup />
        </BoxAdders>
      </ModulePricingContainer>
    </Container>
  );
};

export default memo(ModulePricingFormGroup);
