import React from 'react';
import * as R from 'ramda';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/lib/table';
import {
  columnJoinStringSorter,
  columnSorter,
  rankColumnSorter,
  decimalFormatter,
  generateTitlePopover,
  formatPrice,
} from '~/utils';

import { REGION, maxSafeDate, minSafeDate } from '~/utils/constants';
import { RACKING_TYPE } from '~/utils/rackingTypes';
import {
  convertDateToQuarter,
  getQuarterRangeFromToday,
} from '~/utils/quarter-utils';
import { ActiveProjectsData } from '~/services/api/anza';
import { ROUTER_PATHS } from '~/router/Paths';
import { ProjectResume } from '~/store/project';

// Filters ranges with format <min>:<max>
const projectSizeFilters = [
  {
    text: '< 10 MW',
    value: ':10000',
  },
  {
    text: '10-25 MW',
    value: '10000:25000',
  },
  {
    text: '25-50 MW',
    value: '25000:50000',
  },
  {
    text: '50-100 MW',
    value: '50000:100000',
  },
  {
    text: '> 100 MW',
    value: '100000:',
  },
];

const topRankFilters = [
  {
    text: '1',
    // The min/max value is the same since this is the only filter for top rank that is not a range.
    value: '1:1',
  },
  {
    text: '2-5',
    value: '2:5',
  },
  {
    text: '6-10',
    value: '6:10',
  },
  {
    text: '> 10',
    value: '11:',
  },
];

const getDeliveryDateFilters = () => {
  const filters: Array<ColumnFilterItem> = [];

  getQuarterRangeFromToday(10).forEach(
    (quarter: { quarter: number; year: number }) => {
      const filterText = `Q${quarter.quarter} ${quarter.year}`;
      filters.push({ text: filterText, value: filterText });
    }
  );
  return filters;
};

const filterOnRange = (valueToCheck: number, filterRange: string) => {
  const [min, max] = filterRange.split(':');
  const minVal = min ? Number.parseInt(min, 10) : null;
  const maxVal = max ? Number.parseInt(max, 10) : null;
  if (minVal && maxVal) {
    return valueToCheck >= minVal && valueToCheck <= maxVal;
  }

  if (minVal && !maxVal) {
    return valueToCheck > minVal;
  }

  if (maxVal) {
    return valueToCheck < maxVal;
  }

  return true;
};

const formatQuarterYear = (project: ProjectResumeColumn) => {
  if (R.isNil(project) || R.isNil(project.quarterSelection)) {
    return '-';
  }

  return `Q${R.pathOr('', ['quarterSelection', 'quarter'], project)} ${R.pathOr(
    '',
    ['quarterSelection', 'year'],
    project
  )}`;
};

const sortByYearThenQuarter = (
  projectA: ActiveProjectsData,
  projectB: ActiveProjectsData
) => {
  return columnJoinStringSorter(['year', 'quarter'])(
    projectA.quarterSelection,
    projectB.quarterSelection
  );
};

const topRankDeltaToolTipText =
  'Reach the top rank for this module by reducing your price by the displayed amount.';

export interface ProjectResumeColumn extends ProjectResume, ActiveProjectsData {
  project_details_uuid: string;
}

export const getColumns = (
  sortKey: string | null,
  sortValue: 'ascend' | 'descend' | null,
  filterMap: Record<string, string[]>,
  actions?: {
    render: (_: unknown, record: ProjectResumeColumn) => JSX.Element;
    isSortable?: boolean;
  },
  isAdmin = false
): ColumnsType<ProjectResumeColumn> | undefined => {
  return (
    [
      {
        title: 'Project Size',
        dataIndex: 'projectSizeKw',
        key: 'projectSizeKw',
        width: 300,
        isSortable: true,
        sorter: columnSorter(['projectSizeKw']),
        isFilterable: true,
        render: (text: string | number, record) => {
          const displayText = `${decimalFormatter(+text)} kW DC`;
          return isAdmin ? (
            <Link
              to={ROUTER_PATHS.projects.solar.PROJECT_DETAILS(record.uuid)}
              target="_blank"
            >
              {displayText}
            </Link>
          ) : (
            <span>{displayText}</span>
          );
        },
        filters: projectSizeFilters,
        onFilter: (value, record) => {
          return filterOnRange(
            R.pathOr(0, ['projectSizeKw'], record),
            value.toString()
          );
        },
      },
      {
        title: 'Purchase Date',
        dataIndex: 'anticipatedPurchaseDate',
        key: 'anticipatedPurchaseDate',
        render: (_, record) => (
          <span>
            {!record.anticipatedPurchaseDate ||
            dayjs(record.anticipatedPurchaseDate).isBefore(dayjs(), 'day')
              ? '-'
              : dayjs(record.anticipatedPurchaseDate).format('MM/YYYY')}
          </span>
        ),
        width: 200,
        isSortable: true,
        sorter: (a, b, sortOrder) => {
          let dateA = dayjs(a.anticipatedPurchaseDate);
          let dateB = dayjs(b.anticipatedPurchaseDate);
          if (dateA.isBefore(dayjs(), 'day')) {
            dateA = dayjs(sortOrder === 'ascend' ? maxSafeDate : minSafeDate);
          }
          if (dateB.isBefore(dayjs(), 'day')) {
            dateB = dayjs(sortOrder === 'ascend' ? maxSafeDate : minSafeDate);
          }
          if (dateA.isBefore(dateB)) return -1;
          if (dateA.isAfter(dateB)) return 1;
          return 0;
        },
        isFilterable: true,
        filterSearch: true,
        filters: getDeliveryDateFilters(),
        onFilter: (value, record) => {
          const { year, quarter } = convertDateToQuarter(
            new Date(record.anticipatedPurchaseDate)
          );
          return `Q${quarter} ${year}` === value;
        },
      },
      {
        title: 'Delivery Date',
        dataIndex: 'deliveryDate',
        key: 'deliveryDate',
        render: (_, record) => <span>{formatQuarterYear(record)}</span>,
        width: 200,
        isSortable: true,
        sorter: (a: ActiveProjectsData, b: ActiveProjectsData) =>
          sortByYearThenQuarter(a, b),
        isFilterable: true,
        filterSearch: true,
        filters: getDeliveryDateFilters(),
        onFilter: (value, record) => {
          return formatQuarterYear(record).includes(value.toString());
        },
      },
      {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        width: 200,
        isSortable: true,
        sorter: columnSorter(['region']),
        isFilterable: true,
        filterSearch: true,
        onFilter: (value, record) => record.region.includes(value.toString()),
        filters: [
          {
            text: REGION.MIDWEST,
            value: REGION.MIDWEST,
          },
          {
            text: REGION.SOUTH,
            value: REGION.SOUTH,
          },
          {
            text: REGION.NORTHEAST,
            value: REGION.NORTHEAST,
          },
          {
            text: REGION.WEST,
            value: REGION.WEST,
          },
        ],
      },
      {
        title: 'Racking Type',
        dataIndex: 'rackingType',
        key: 'rackingType',
        width: 250,
        isSortable: true,
        sorter: columnSorter(['rackingType']),
        isFilterable: true,
        onFilter: (value, record) =>
          record.rackingType.includes(value.toString()),
        filters: [
          {
            text: RACKING_TYPE.FIXED_TILT,
            value: RACKING_TYPE.FIXED_TILT,
          },
          {
            text: RACKING_TYPE.SINGLE_AXIS,
            value: RACKING_TYPE.SINGLE_AXIS,
          },
        ],
      },
      {
        title: 'Top Module',
        dataIndex: 'topModule',
        key: 'topModule',
        width: 450,
        isSortable: true,
        sorter: columnSorter(['topModule', 'name']),
        render: (_, record) => {
          const displayText = R.pathOr('-', ['topModule', 'name'], record);
          const rank = record.topModule?.rank;
          const topModuleVendorUuid = record.topModule?.vendorId;

          return isAdmin && rank && topModuleVendorUuid !== undefined ? (
            <Link
              to={ROUTER_PATHS.vendors.PROJECT_PRICING(
                topModuleVendorUuid,
                record.project_details_uuid
              )}
              target="_blank"
            >
              {displayText}
            </Link>
          ) : (
            <span>{displayText}</span>
          );
        },
      },
      {
        title: generateTitlePopover(
          'Top Rank Delta',
          topRankDeltaToolTipText,
          undefined,
          true
        ),
        dataIndex: 'topRankDelta',
        key: 'topRankDelta',
        width: 170,
        isSortable: true,
        sorter: rankColumnSorter(['topModule', 'topRankDelta']),
        render: (_, record) => (
          <span>
            {formatPrice(
              R.pathOr(null, ['topModule', 'topRankDelta'], record)
            ) || '-'}
          </span>
        ),
      },
      {
        title: 'Top Rank',
        dataIndex: 'topRank',
        key: 'topRank',
        isSortable: true,
        sorter: rankColumnSorter(['topModule', 'rank']),
        filters: topRankFilters,
        onFilter: (value, record) => {
          return filterOnRange(
            R.pathOr(0, ['topModule', 'rank'], record),
            value.toString()
          );
        },
        ...actions,
      },
    ] as ColumnsType<ProjectResumeColumn>
  ).map((column) => {
    const customColumn = { ...column };
    const key = column.key || '';
    if (sortKey === key) {
      customColumn.sortOrder = sortValue;
    }
    if (filterMap[key]) {
      customColumn.defaultFilteredValue = filterMap[key];
    }
    return customColumn;
  });
};
