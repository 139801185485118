import React, { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import styled from '@emotion/styled';
import { breakpoints, colors, isUserAdmin, theme } from '~/utils';
import { Affix, ProjectDetailsAlert, Tooltip } from '~/UI';
import { BaseProject } from '~/store/project';
import { UserType } from '~/types/users';
import {
  TEST_DATA_COMPONENTS,
  TestIdProps,
  generateTestId,
} from '~/utils/dataTestProps';

import CollapsibleHeroModule, { Item } from './CollapsibleHeroModule';
import { H5 } from './Typography';

dayjs.extend(isToday);
dayjs.extend(advancedFormat);

interface Props<TProject> {
  showToggle?: boolean;
  user: UserType;
  dataTestIdConfig: TestIdProps;
  project: TProject;
  heroModuleItems: Array<Item>;
  heroModuleAdditionalItems?: Array<Item>;
  children?: React.ReactNode;
  offsetTop?: number;
  lastUpdated?: string;
  setOffsetMenu?: (offset: number) => void;
  setBanner: (banner: React.ReactNode) => void;
  hideBottomShadow?: boolean;
  onAffixChange?: (value: boolean) => void;
  footer?: React.ReactNode;
}

const StyledProjectTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 800;
  color: ${colors.charcoal};
  line-height: 1.3;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const ProjectHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${theme.colors.greyPageBackground};
  padding-top: 20px;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 1px;
    z-index: -1;
    transform: scale(0.995);
    box-shadow: ${(props: { bottomShadow: boolean }) =>
      props.bottomShadow ? '0 0 10px 1px' : 'none'};
  }
`;
const StyledProjectHeader = styled.div<{ responsive: boolean }>`
  ${({ responsive }) => {
    if (!responsive) {
      return `
        display: flex;
        gap: 12px;
      `;
    }
    return `
      display: grid;
      grid:
        'Header CTAs'
        'Hero Hero';
      justify-content: space-between;
      gap: 12px;
      @media (min-width: ${breakpoints.laptop}) {
        display: flex;
      }
    `;
  }}
`;
const StyledProjectTitleWrapper = styled.div`
  width: 280px;
  min-width: 280px;
  grid-area: Header;
`;

const StyledHeaderActionButtons = styled.div`
  display: flex;
  gap: 16px;
  grid-area: CTAs;
  align-items: center;
`;

const StyledCollapsibleHeroModule = styled(CollapsibleHeroModule)`
  padding: 0;
  width: 100%;
  grid-area: Hero;
  @media (min-width: ${breakpoints.laptop}) {
    ${({ showToggle }) => (!showToggle ? 'margin-right: 6%' : '')}
  }
`;
const StyledCollapsibleHeroModuleSolar = styled(CollapsibleHeroModule)`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  gap: 10em;
`;

const ProjectDetailsHeader = <TProject extends BaseProject>({
  user,
  dataTestIdConfig,
  project,
  heroModuleItems,
  children,
  offsetTop,
  showToggle = true,
  heroModuleAdditionalItems = [],
  lastUpdated,
  setOffsetMenu,
  setBanner,
  hideBottomShadow = false,
  onAffixChange,
  footer,
}: Props<TProject>) => {
  const headerRef = useCallback((headerNode: HTMLDivElement) => {
    if (!headerNode) return;
    const { height } = headerNode.getBoundingClientRect();
    const totalOffset = isUserAdmin(user)
      ? height +
        theme.elements.header.heightNumber +
        theme.elements.secondaryHeader.heightNumber
      : height + theme.elements.header.heightNumber;
    if (setOffsetMenu) setOffsetMenu(totalOffset);
  }, []);

  const lastUpdatedText = dayjs(lastUpdated).isToday()
    ? 'today'
    : dayjs(lastUpdated).format('MMMM Do, YYYY');

  useEffect(() => {
    const showProjectDetailsAlert = ['created', 'proposal'].includes(
      project.project_details.status
    );

    const banner = showProjectDetailsAlert ? (
      <ProjectDetailsAlert
        project={project}
        status={project.project_details.status}
        dataTestIdConfig={dataTestIdConfig}
      />
    ) : null;
    setBanner(banner);
  }, [user, project]);
  return (
    <>
      <Affix
        offsetTop={offsetTop}
        style={{ zIndex: 101, position: 'relative' }}
        onChange={(value) => onAffixChange && onAffixChange(Boolean(value))}
      >
        <ProjectHeaderWrapper ref={headerRef} bottomShadow={!hideBottomShadow}>
          <StyledProjectHeader
            responsive={project.project_details.project_type === 'solar'}
          >
            <StyledProjectTitleWrapper
              data-testid={generateTestId({
                ...dataTestIdConfig,
                component: TEST_DATA_COMPONENTS.TEXT,
                identifier: 'project-name',
              })}
            >
              <Tooltip placement="topLeft" title={project.name}>
                <StyledProjectTitle>{project.name}</StyledProjectTitle>
              </Tooltip>
              <H5>Pricing as of {lastUpdatedText}</H5>
            </StyledProjectTitleWrapper>
            {project.project_details.project_type === 'solar' ? (
              <StyledCollapsibleHeroModuleSolar
                showToggle={showToggle}
                items={heroModuleItems}
                additionalItems={heroModuleAdditionalItems}
                testIdData={{
                  ...dataTestIdConfig,
                  component: TEST_DATA_COMPONENTS.TEXT,
                }}
              />
            ) : (
              <StyledCollapsibleHeroModule
                showToggle={showToggle}
                items={heroModuleItems}
                additionalItems={heroModuleAdditionalItems}
                testIdData={{
                  ...dataTestIdConfig,
                  component: TEST_DATA_COMPONENTS.TEXT,
                }}
              />
            )}
            <StyledHeaderActionButtons>{children}</StyledHeaderActionButtons>
          </StyledProjectHeader>
          {footer}
        </ProjectHeaderWrapper>
      </Affix>
    </>
  );
};

export default ProjectDetailsHeader;
