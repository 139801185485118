import {
  ANZA_ENGINE_STATES,
  ANZA_ENGINE_STEP_STATES,
} from '~/constants/engine';
import {
  SOLAR_CALCULATED_FIELDS,
  STORAGE_CALCULATED_FIELDS,
} from '~/constants/rankingTable';
import { Filter, FilterSet } from '~/utils/filters';
import { ModuleFilters } from '~/components/Projects/solar/RankingFilter';
import { ModuleTag } from '~/types/modules';
import type { PaginatedQueryData } from '~/types/rtkApi';
import { FileTypes } from '~/types/file';
import { OwnerType } from '../../utils';

export interface ProjectState {
  uuid?: string;
  name?: string;
  owner_id?: string | null;
  owner?: OwnerType | null;
  sf_opportunity_id?: string | null;
  isLoading?: boolean;
  isFetched?: boolean;
  filters?: ModuleFilters;
}
interface CreateProjectBase {
  name: string;
  project_address: string;
  type: 'solar' | 'storage';
  state: string;
  latitude: number;
  longitude: number;
  google_place_id: string;
  anticipated_purchase_date: string;
}
export type CreateSolarProjectArgs = CreateProjectBase & {
  arrival_date: string;
  size_in_kw: number;
  racking_type: string;
  current_purchase_volume_kw?: number | null;
};

export type CreateStorageProjectArgs = CreateProjectBase & {
  annual_cycle_count: number;
  capacity_maintenance_term: number;
  anticipated_purchase_date: string;
  duration_hours: number;
  metering_location: string;
  power_factor: number;
  storage_arrival_date: string;
  target_ac_power_mw: number;
};

export type UpdateProjectArgs = {
  name: string;
  uuid: string;
  owner_id: number;
  sf_opportunity_id?: string;
  project_details: Partial<ProjectDetails>;
  advancedInputs?: SolarAdvancedInputs;
};
export type UpdateProjectStatusArgs = {
  uuid: string;
  status: ProjectStatus;
};
export type UpdateProjectStatusResponse = {
  uuid: string;
  status: ProjectStatus;
  projectUuid: string;
};
export type DeleteProjectArgs = {
  id: string;
};

export type UpdatedSolarProjectTransformResultType = CreateSolarProjectArgs;
export type UpdatedProjectType = BaseProject & {
  project_details: ProjectDetails;
};
export type UpdatedStorageProjectTransformResultType = CreateStorageProjectArgs;

export interface CreateSolarProjectResultType {
  uuid: string;
  name: string;
  owner_id: number;
  updated_at: Date;
  created_at: Date;
  sf_opportunity_id?: number;
  project_type: string;
}

export type CreateStorageProjectResultType = CreateStorageProjectArgs & {
  uuid: string;
};

export interface ProjectEngineData {
  moduleId: string;
  field: EngineOutputFields;
  value: string | number | null | boolean;
  quarter: string;
}

export type LogoData = {
  file_name: string;
  content_type: string;
  file_location_path: string;
};

export type SolarAnalyticsData = {
  china_adcvd_risk: string;
  cell_technology: string;
  efficiency: number;
  factory_audit_qc: string;
  frame_width: number;
  frontside_glass_mm: string;
  long_edge: number;
  name: string;
  non_chinese_components: string;
  non_chinese_poly: string;
  non_chinese_wafers: string;
  quality_assurance_report_qc: string;
  short_edge: number;
  tier_1: boolean;
  traceability_audit_qc: string;
  uflpa_risk: string;
  vendor: string;
  vertically_integrated: string;
  watts: number;
  yearly_degradation: number;
};

export interface EngineResultModule {
  availability: number;
  manufacturer?: string;
  name?: string;
  price: number;
  wattClassKw: number;
  tags?: { tag: ModuleTag }[];
  logoData?: LogoData;
  quarterAvailability: {
    availability_mw: number;
    quarter: number;
    year: number;
  }[];
  analytics_data: SolarAnalyticsData;
}

export interface EngineResultSizingArchitecture {
  ac_data: AcData;
  augmentation_data: AugmentationData;
  capital_expenditures: CapitalExpenditures;
  dc_block: DcBlock;
  dc_block_allocation: DcBlockAllocation;
  dc_data: DcData;
  pcs_module: PcsModule;
  engineInputData: EngineInputData;
  discountRatePercent: number;
}

export interface AcData {
  pcs_apparent_power_kVA: number;
  pcs_module_count: number;
  pcs_real_power_kW: number;
  power_loss_factor_dictionary: PowerLossFactorDictionary;
  loss_factor_display: {
    'DC Cabling Loss'?: number;
    'DC Converter Loss'?: number;
    'DC Collection Loss'?: number;
    'LV Cabling Loss'?: number;
    'MV Transformer Loss'?: number;
    'MV Cabling Loss'?: number;
    'MV Collection Loss'?: number;
    'HV Transformer Loss'?: number;
  };
}

export interface CapitalExpenditures {
  dc_block_commissioning_cost_USD: number;
  dc_block_commissioning_price_USD: number;
  dc_block_cost_USD: number;
  dc_block_cost_USD_per_kWh: number;
  dc_block_price_USD: number;
  dc_block_shipping_cost_USD: number;
  dc_block_tariff_cost_USD: number;
  ems_cost_USD: number;
  ems_price_USD: number;
  pcs_comissioning_cost_USD: number;
  pcs_comissioning_price_USD: number;
  pcs_cost_USD: number;
  pcs_price_USD: number;
  pcs_shipping_cost_USD: number;
  storage_capex_USD: number;
  storage_capex_USD_per_kWh: number;
  ul9540_cost_USD: number;
  ul9540_price_USD: number;
}

export interface DcBlock {
  // TODO: break out types for different engine versions i.e. storage_v1 | storage_v2
  uuid: string;
  product_name: string;
  manufacturer: string;
  product_archetype: string;

  AugmentationProcess_: string | number;
  AuxiliaryMaximumPower_kW_: number;
  AuxiliaryStandbyPower_kW_: number | null;
  CellChemistry: string;
  CellManufacturer: string;
  CleanAgent: number | null;
  ClearanceFromBack_mm_: number;
  ClearanceFromFace_mm_: number;
  ClearanceFromSide_to_side_mm_: number;
  Commissioning: number;
  ConcreteFoundation: number;
  CorrosivityRating: number;
  Cost_1: number | null;
  Cost_2: number;
  Cost_3: number;
  DCPower_RatedDuration_kW_: number;
  // storage_v1
  DCRoundTripEfficiency_RTE_?: number;
  // storage_v2
  round_trip_efficiency_percent?: number;
  // storage_v3 and on
  ac_block_round_trip_efficiency_percent?: number | null;
  dc_block_round_trip_efficiency_percent?: number | null;
  DeflagrationPanels: number;
  DryPipesIncluded: number;
  EMSRequired: boolean;
  EarliestAvailability_DG: string | null;
  EarliestAvailability_Utility: string;
  ExactEnergyDensity_kWh_m__: number;
  FireRatedInsulation: number | null;
  GasDetectors: number;
  HeatDetectors: number;
  Height_mm_: number;
  IngressProtectionRating: string;
  Length_mm_: number;
  MSA: number;
  MWh_1: number;
  MWh_2: number;
  MWh_3: number;
  'Maintenance Y1': number;
  'Maintenance Y10': number;
  'Maintenance Y11': number;
  'Maintenance Y12': number;
  'Maintenance Y13': number;
  'Maintenance Y14': number;
  'Maintenance Y15': number;
  'Maintenance Y16': number;
  'Maintenance Y17': number;
  'Maintenance Y18': number;
  'Maintenance Y19': number;
  'Maintenance Y2': number;
  'Maintenance Y20': number;
  'Maintenance Y3': number;
  'Maintenance Y4': number;
  'Maintenance Y5': number;
  'Maintenance Y6': number;
  'Maintenance Y7': number;
  'Maintenance Y8': number;
  'Maintenance Y9': number;
  Manufacturer: string;
  MaturityLevel: number;
  MaximumDCVoltage_V_: number;
  MaximumOperatingAltitude_ft_: number;
  MaximumSnowLoad_psf_: number;
  MaximumWindSpeed_mph_: number;
  MinimumDCVoltage_V_: number;
  MinimumDurations_hours_: number;
  NameplateCapacity_kWhDC_: number;
  NoiseLevel_dB_: number;
  NominalDCVoltage_V_: number;
  OperatingAmbientMaximum__C_: number;
  OperatingAmbientMinimum__C_: number;
  PileFoundation: string | number;
  PotentialForDC_Coupling: number;
  PowerElectronicsPCSCompatible: number;
  'Preventative Maintenance - Percent of CapEx': boolean | null;
  'Preventative Maintenance - USD per kWh': boolean;
  ProductName: string;
  RealisticEnergyDensity_kWh_m__: number;
  SMAPCSCompatible: number;
  SeismicRatingCategory: number | string;
  SelfDischargeOnStandby_: number | string;
  ShippingCost_per_kWh: number;
  ShortCircuitRating: number | string;
  SmokeDetectors: number;
  SpecificMaximumAuxiliaryDraw_W_kWh_: number;
  SpecificStandbyAuxiliaryDraw_W_kWh_: number | null;
  SungrowPCSCompatible: number;
  Tariff: number;
  TeslaPCSCompatible: boolean;
  ThermalRegulationMethod: string;
  UUID: string;
  UseableCapacity_kWhDC: number;
  'Warranty - Percent of CapEx': boolean;
  'Warranty - USD per kWh': boolean;
  'Warranty Y1': number;
  'Warranty Y10': number;
  'Warranty Y11': number;
  'Warranty Y12': number;
  'Warranty Y13': number;
  'Warranty Y14': number;
  'Warranty Y15': number;
  'Warranty Y16': number;
  'Warranty Y17': number;
  'Warranty Y18': number;
  'Warranty Y19': number;
  'Warranty Y2': number;
  'Warranty Y20': number;
  'Warranty Y3': number;
  'Warranty Y4': number;
  'Warranty Y5': number;
  'Warranty Y6': number;
  'Warranty Y7': number;
  'Warranty Y8': number;
  'Warranty Y9': number;
  'Warranty includes PM': boolean;
  Weight_lbs_: number;
  Width_mm_: number;
}

export interface DcBlockAllocation {
  dc_blocks_per_pcs_list: number[];
  pcs_module_count_list: number[];
}
export interface DcData {
  beginning_of_life_energy_kWh: number;
  dc_block_count: number;
  dc_blocks_per_pcs: number;
  required_dc_energy_MWh: number;
  required_dc_power_MW: number;
  useable_beginning_of_life_energy_kWh: number;
}

export interface PcsModule {
  // TODO: break out types for different engine versions i.e. storage_v1 | storage_v2
  uuid: string;
  product_name: string;
  manufacturer: string;

  AC_CoupledCompatible: boolean;
  AuxiliaryMaximumPower_kW_: number;
  AuxiliaryStandbyPower_kW_: number;
  BlackStartCapability: number;
  ClearanceFromBack_mm_: number;
  ClearanceFromBottom_mm_: number;
  ClearanceFromFace_mm_: number;
  ClearanceFromSide_to_side_mm_: number;
  Clearances: number;
  ConcreteFoundation: number;
  ControlResponseTime_ms_: number;
  CorrosivityRating: number;
  DC_CoupledCompatible: boolean;
  DynamicPQCurve: boolean | number | string;
  EMSIncluded: boolean | number | string;
  // storage_v1
  Efficiency?: number;
  // storage_v2 and on
  efficiency_percent?: number;
  EstimatedCostForInverterOnly: number;
  EstimatedCostForMVSkid: number;
  FastFrequencyResponse: number | string;
  FrequencyRideThrough: number;
  GroundFaultProtection: number | string;
  Height_mm_: number;
  HighVoltageRideThrough: number;
  IEEE1547: number;
  IngressProtectionRating: string;
  IslandingCapability: number;
  Length_mm_: number;
  LowVoltageRideThrough: number;
  'Maintenance Purchase Required for Warranty': number | string;
  'Maintenance Y1': number | string;
  'Maintenance Y10': number | string;
  'Maintenance Y11': number | string;
  'Maintenance Y12': number | string;
  'Maintenance Y13': number | string;
  'Maintenance Y14': number | string;
  'Maintenance Y15': number | string;
  'Maintenance Y16': number | string;
  'Maintenance Y17': number | string;
  'Maintenance Y18': number | string;
  'Maintenance Y19': number | string;
  'Maintenance Y2': number | string;
  'Maintenance Y20': number | string;
  'Maintenance Y3': number | string;
  'Maintenance Y4': number | string;
  'Maintenance Y5': number | string;
  'Maintenance Y6': number | string;
  'Maintenance Y7': number | string;
  'Maintenance Y8': number | string;
  'Maintenance Y9': number | string;
  Manufacturer: string;
  MaturityLevel: number;
  MaxRampRate: number;
  MaximumDCVoltage_V_: number;
  MaximumNumberOfInputsSource_Terminal: number;
  MaximumOperatingAltitude_m_: number;
  MaximumReactivePower_kVAr_: number;
  MaximumShortCircuitCurrent_A_: number;
  MaximumSnowLoad_psf_: number;
  MaximumWindSpeed_mph_: number;
  MinimumDCVoltage_V_: number;
  MinimumReactivePower_kVAr_: number;
  NameplateCapacity_kVA_: number;
  NoiseLevel_dB_: string;
  OperatingAmbientMaximum__C_: number;
  OperatingAmbientMinimum__C_: number;
  OutputACVoltage_V_: number;
  PVCompatible: boolean;
  PileFoundation: string | number;
  PowerAt25_C_kVA_: number;
  PowerAt35_C_kVA_: number;
  PowerAt40_C_kVA_: number;
  PowerAt45_C_kVA_: number;
  PowerAt50_C_kVA_: number;
  PowerAt60_C_kVA_: number;
  PrimaryFrequencyResponse: number;
  ProductName: string;
  QNight: string | number;
  RatedDCVoltage_V_: number;
  RealPower_QMax_kW_: number;
  RealPower_QMin_kW_: number;
  SeismicRatingCategory: string;
  ShortCircuitCurrent_A_: string | number;
  ShortCircuitCurrent_A__Upgrade: string | number;
  SkiddedTransformerOption: number;
  UL1741: number;
  UUID: string;
  UnitCommissioningCost: number;
  UnitCost: number;
  UnitShippingCost: number;
  VoltageSupportService_AVR_VSS_: number;
  'Warranty Y1': string | number;
  'Warranty Y10': string | number;
  'Warranty Y11': string | number;
  'Warranty Y12': string | number;
  'Warranty Y13': string | number;
  'Warranty Y14': string | number;
  'Warranty Y15': string | number;
  'Warranty Y16': string | number;
  'Warranty Y17': string | number;
  'Warranty Y18': string | number;
  'Warranty Y19': string | number;
  'Warranty Y2': string | number;
  'Warranty Y20': string | number;
  'Warranty Y3': string | number;
  'Warranty Y4': string | number;
  'Warranty Y5': string | number;
  'Warranty Y6': string | number;
  'Warranty Y7': string | number;
  'Warranty Y8': string | number;
  'Warranty Y9': string | number;
  'Warranty and PM Lumped Together': string | number;
  Weight_lbs_: number;
  Width_mm_: number;
  ZeroVoltageRideThrough: number;
}

export interface PowerLossFactorDictionary {
  combined_loss_factor: number;
  dc_cabling_loss_factor: number;
  dc_collection_loss_factor: number;
  dc_converter_loss_factor: number;
  dc_loss_factor: number;
  hv_cabling_loss_factor: number;
  hv_transformer_loss_factor: number;
  lv_cabling_loss_factor: number;
  meter_to_pcs_loss_factor: number;
  mv_cabling_loss_factor: number;
  mv_collection_loss_factor: number;
  mv_transformer_loss_factor: number;
  reactive_power_loss_factor: number;
}

export type NetPresentValueDict = {
  storage_capex_USD: number;
};

export interface Schedule {
  added_dc_blocks: number[];
  augmentation_strategy: string;
  augmentation_capex_NPV_USD: number;
  augmentation_mobilization_cost_list_USD: number[];
  augmentation_years: number[];
  beginning_of_life_capex_USD: number;
  beginning_of_life_capex_USD_per_kWh: number;
  beginning_of_life_dc_block_count: number;
  beginning_of_life_nameplate_energy_kWh: number;
  beginning_of_life_useable_energy_kWh: number;
  cumulative_deployment_schedule_list: number[];
  dc_block_commissioning_cost_USD: number;
  dc_block_commissioning_cost_USD_per_kWh: number;
  dc_block_commissioning_price_USD: number;
  dc_block_cost_USD: number;
  dc_block_cost_USD_per_kWh: number;
  dc_block_life_cycle_cost_list_USD: number[];
  dc_block_pm_life_cycle_cost_list_USD: number[];
  dc_block_pm_type_USD_per_kWh_boolean: boolean;
  dc_block_price_USD: number;
  dc_block_shipping_cost_USD: number;
  dc_block_shipping_cost_USD_per_kWh: number;
  dc_block_warranty_and_pm_lumped_together: boolean;
  dc_block_warranty_life_cycle_cost_list_USD: number[];
  dc_block_warranty_type_USD_per_kWh_boolean: boolean;
  degredation_list: number[][];
  deployed_energy_list: number[];
  deployed_mat_list: number[][];
  deployment_schedule_list: number[];
  ems_cost_USD: number;
  ems_cost_USD_per_kWh: number;
  ems_price_USD: number;
  net_present_value_dict: NetPresentValueDict;
  pcs_comissioning_cost_USD: number;
  pcs_comissioning_cost_USD_per_kWh: number;
  pcs_cost_USD: number;
  pcs_cost_USD_per_kWh: number;
  pcs_deployment_schedule: number[];
  pcs_cumulative_deployment_schedule_list: number[];
  pcs_maintenance_purchase_required_for_warranty: number;
  pcs_pm_life_cycle_cost_list_USD: number[];
  pcs_price_USD: number;
  pcs_shipping_cost_USD: number;
  pcs_shipping_cost_USD_per_kWh: number;
  pcs_warranty_and_pm_lumped_together: number;
  pcs_warranty_life_cycle_cost_list_USD: number[];
  preventative_maintenance_net_present_value_USD: number;
  scenario_list: number[];
  system_round_trip_efficiency_percent: number;
  // >= storage_v3 number[]
  // < storage_v2 number
  system_power_kVA: number[] | number;
  tariff: number;
  tariff_USD: number;
  tariff_USD_per_kWh: number;
  ul9540_cost_USD: number;
  ul9540_cost_USD_per_kWh: number;
  ul9540_price_USD: number;
  unweighted_dc_block_pm_life_cycle_cost_list_USD: number[];
  unweighted_dc_block_warranty_life_cycle_cost_list_USD: number[];
  unweighted_pcs_pm_life_cycle_cost_list_USD: number[];
  unweighted_pcs_warranty_life_cycle_cost_list_USD: number[];
  unweighted_augmentation_cost_list_USD: number[];
  warranty_net_present_value_USD: number;
  unweighted_life_cycle_cost_list_USD: number[];
  weighted_life_cycle_cost_USD: number;
  weighted_life_cycle_cost_USD_per_kWh: number;
  weighted_life_cycle_cost_list_USD: number[];
  weighted_life_cycle_cost_list_USD_per_kWh: number[];
  pcs_module_count: number;
}

export interface EngineInputData {
  allow_dissimilar_esus: boolean;
  augmentation: boolean;
  capacity_maintenance_term_years: number;
  annual_cycle_count: number;
  target_ac_power_MW: number;
  duration_hours: number;
  metering_location: string;
  power_factor: number;
  depth_of_discharge_percent: number;
  resting_state_of_charge_percent: number;
  loss_severity: string;
  project_topology: string;
  delivery_date: string;
  pcs_warranty_years: number;
  pcs_maintenance_years: number;
  dc_block_warranty_years: number;
  dc_block_maintenance_years: number;
  lithium_carbonate_index_RMB_per_mt: number;
  lithium_iron_phospate_index_RMB_per_mt: number;
  lithium_iron_phospate_electrolyte_index_RMB_per_mt: number;
  synthetic_graphite_index_RMB_per_mt: number;
  copper_cathode_index_RMB_per_mt: number;
  aluminum_ingot_index_RMB_per_mt: number;
  USD_to_RMB_currency_conversion_factor: number;
  uuid: string;
  id: number;
  anticipated_purchase_date: string;
  capacity_maintenance_term: number;
  latitude: number;
  longitude: number;
  target_ac_power_mw: number;
  state: string;
  storage_arrival_date: string;
  dc_block_warranty_purchase: boolean;
  dc_block_maintenance_purchase: boolean;
  pcs_maintenance_purchase: boolean;
  pcs_warranty_purchase: boolean;
  resting_state_of_charge: number;
  depth_of_discharge: number;
  status: string;
  project_address: string;
  google_place_id: string;
  project_type: string;
  capacity_maintenance_strategy: string;
  interconnection_voltage: number;
  include_auxiliary_load_in_calculation: boolean;
  is_larger_purchase: boolean;
}

export type AugmentationData = Record<string, Schedule>;

export type EngineOutputBasicData = Omit<ProjectEngineData, 'field' | 'value'> &
  Omit<EngineResultModule, 'price' | 'name'> & {
    moduleName: string;
  };

// Update this if the backend sends more fields
export type EngineOutputFields =
  | 'bifaciality'
  | 'module_degredation_rate_percent'
  | 'post_unavailability_yield_kWh_per_kWp'
  | 'project_value_USD'
  | 'dc_size_kW'
  | 'epc_price_USD'
  | 'epc_price_USD_per_W'
  | 'net_present_value_USD'
  | 'BifacialityFactor'
  | 'module_cost_USD_per_W'
  | 'module_margin_percent'
  | 'temp_loss'
  | 'irr_loss'
  | 'lid_loss'
  | 'iam_loss'
  | 'non_module_bos_cost_USD_per_W'
  | 'revenue_USD_per_W'
  | 'module_customer_price_USD_per_W'
  | 'relative_revenue_USD_per_W'
  | 'relative_epc_cost_USD_per_W'
  | 'module_effective_price_USD_per_W'
  | 'rank'
  // Not available yet
  | 'modulePriceUsdPerW'
  | 'modulePriceUsd'
  | 'bosPriceUsdPerW'
  | 'bosPriceUsd'
  | 'totalPriceUsdPerW'
  | 'totalPriceUsd'
  | 'moduleLabor'
  | 'rackMaterial'
  | 'foundMaterial'
  | 'mechLabor'
  | 'dcElecMaterial'
  | 'dcElecLabor'
  | 'moduleCount'
  | 'modsPerString'
  | 'stringCount'
  | 'totalSourceCircuitLengthFt'
  | 'wireSize'
  | 'modsPerRack'
  | 'rackCount'
  | 'foundationInstallCount'
  | 'moduleLengthMm'
  | 'moduleWidthMm'
  | 'moduleDepthMm'
  | 'weight'
  | 'modulePowerW'
  | 'moduleVocV'
  | 'moduleVmpV'
  | 'moduleIscA'
  | 'moduleImpA'
  | 'moduleEfficiency'
  | 'tiltDeg'
  | 'dcAcRatio'
  | 'pitchM'
  | 'groundCoverageRatio'
  | 'module_availability_MW';

type TypedEngineOutputData = {
  module_effective_price_USD_per_W: number;
};

type KeysOfTypedEngineOutputData = keyof TypedEngineOutputData;

export type EngineOutputData = EngineOutputBasicData &
  Omit<
    {
      [key in EngineOutputFields]: any;
    },
    KeysOfTypedEngineOutputData
  > &
  TypedEngineOutputData;

export type SolarEngineOutputCalculatedFields =
  (typeof SOLAR_CALCULATED_FIELDS)[number]['field'];

export type SolarRankingData = EngineOutputData & {
  isModuleHidden?: boolean;
} & {
  [key in SolarEngineOutputCalculatedFields]: number;
};

export type StorageEngineOutputCalculatedFields =
  (typeof STORAGE_CALCULATED_FIELDS)[number]['field'];

export type StorageRanking = EngineResultSizingArchitecture & {
  [key in StorageEngineOutputCalculatedFields]: number;
};

export type ProjectType = 'solar' | 'storage';

export type ProjectStatus =
  | 'created'
  | 'proposal'
  | 'awarded'
  | 'lost'
  | 'inactive';
export interface ProjectDetails {
  id: string;
  uuid: string;
  racking_type: string;
  arrival_date: string;
  size_in_kw: number;
  project_address: string;
  project_type: ProjectType;
  anticipated_purchase_date: string;
  latitude: number;
  longitude: number;
  state: string;
  location_id: number;
  google_place_id: string;
  status: ProjectStatus;
  current_purchase_volume_kw?: number | null;
  storage_arrival_date?: string;
  is_storage_approved?: boolean;
  user_id: string;
  metering_location: string;
  target_ac_power_mw: number;
  duration_hours: number;
  power_factor: number;
  annual_cycle_count: number;
  project_topology: string;
  capacity_maintenance_term: number;
  storage_notes: string;
  depth_of_discharge: number;
  resting_state_of_charge: number;
  includes_augmentation: boolean;
  pcs_warranty_purchase: boolean;
  pcs_maintenance_purchase: boolean;
  dc_block_warranty_purchase: boolean;
  dc_block_maintenance_purchase: boolean;
  capacity_maintenance_strategy: string;
  is_test: boolean;
  is_itc: boolean;
  availability_override: boolean;
  locationModifier: LocationModifier;
  tax_credits: TaxCredit[];
}

export interface Company {
  id: number;
  uuid: string;
  is_deleted: boolean;
  name: string;
  company_type: string;
  account_manager_user_id?: string;
  vendor_module_notifications_last_sent_at: '2023-03-07T15:02:57.009Z';
  previous_purchase_volume_mw?: number;
  is_automatic_approval: boolean;
  created_at: string;
  updated_at: string;
}

export interface Domain {
  company: Company;
  company_id: number;
  created_at: string;
  domain: string;
  domain_status: string;
  id: number;
  is_deleted: boolean;
  updated_at: string;
  uuid: string;
}

export interface User {
  auth_provider_subject: string;
  created_at: string;
  domain: Domain;
  domain_id: number;
  email: string;
  family_name: string;
  first_name: string;
  given_name: string;
  id: number;
  is_deactivated: boolean;
  is_deleted: boolean;
  job_title?: string;
  last_name: string;
  module_digest_frequency_days: number;
  module_digest_last_sent_at: string;
  module_digest_opt_out: boolean;
  phone_number: string;
  updated_at: string;
  uuid: string;
  vendor_module_pricing_notifications_opt_out: boolean;
}

export type CreditType = 'ENERGY_TERM' | 'ITC' | 'PTC';
export type TaxCredit = {
  base_credit: number;
  duration?: number;
  escalation_rate?: number;
  project_id: number;
  credit_type: CreditType;
  start_year?: number;
  uuid: string;
};
export type EnergyTerm = Omit<TaxCredit, 'uuid' | 'credit_type'>;
export interface BaseProject {
  id: number;
  uuid: string;
  name: string;
  owner_id: number;
  updated_at: Date;
  created_at: Date;
  sf_opportunity_id?: number;
  project_details: ProjectDetails;
  previous_purchase_volume_kw: number;
  owner: User;
  module_filter_sets: FilterSet[];
  tax_credits: TaxCredit[];
}

export interface ProjectResume {
  arrival_date: string;
  created_at: Date;
  name: string;
  owner: User;
  owner_id: number;
  previous_purchase_volume_kw: number;
  project_address: string;
  project_type: ProjectType;
  sf_opportunity_id?: number;
  storage_arrival_date?: string;
  status: ProjectStatus;
  updated_at: Date;
  uuid: string;
}

export interface SolarAdvancedInputs {
  energy_rate_value_USD_per_kWh: number;
  energy_rate_escalator_percent: number;
  target_rate_of_return_percent: number;
  project_term_years: number;
  baseline_ac_size_kW: number;
  baseline_ground_coverage_ratio: number;
}

export type SolarProject = BaseProject & {
  advancedInputs: SolarAdvancedInputs;
};

export interface StorageAdvancedInputs {
  resting_state_of_charge_percent: number;
  depth_of_discharge_percent: number;
  include_auxiliary_load_in_calculation: boolean;
  interconnection_voltage: number;
  pcs_warranty_years: number;
  pcs_maintenance_years: number;
  dc_block_warranty_years: number;
  dc_block_maintenance_years: number;
}
export type StorageProject = BaseProject & {
  advancedInputs: StorageAdvancedInputs;
};

export type StepState =
  (typeof ANZA_ENGINE_STEP_STATES)[keyof typeof ANZA_ENGINE_STEP_STATES];

export type EngineResult =
  (typeof ANZA_ENGINE_STATES)[keyof typeof ANZA_ENGINE_STATES];

export enum AnalyticsKeyType {
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  DOUBLE = 'double',
  STRING = 'string',
  FLOAT = 'float',
  DATE = 'date',
}

export enum AnalyticsSchemaUnits {
  PERCENTAGE = '%',
  NA = 'NA',
  mm = 'mm',
  W = 'W',
  PA = 'Pa',
  NONE = '',
  YEARS = 'yrs',
}

export type AnalyticsSchema = {
  db_group: string;
  decimal_places?: number;
  rfvi_id: string;
  type: AnalyticsKeyType;
  ui_group: string;
  ui_name: string;
  units: AnalyticsSchemaUnits;
  /*
    include_in_analysis_view is going to be deprecated once all the data for
    this is ingested from ReTool
  */
  include_in_analysis_view?: 'true' | 'false' | null;
  is_advanced_view_data?: boolean | null;
  tooltip: string;
  display_order: number | string | null;
  is_filter_option: boolean;
  is_display_option: boolean;
};

export interface GetSolarProjectResultType {
  project: SolarProject;
  modules?: Record<string, EngineResultModule>;
  data?: ProjectEngineData[];
  engine?: EngineResult;
  lastUpdated?: string;
  permissions: SolarPermissions;
  available_analytics_data: Record<string, AnalyticsSchema>;
}

export interface GetProjectResultType {
  project: BaseProject & {
    advancedInputs: SolarAdvancedInputs | StorageAdvancedInputs;
  };
  engine?: StorageEngineResult;
  data?: ProjectEngineData[];
}

type TriggeredByType = 'project_creation' | 'project_update' | 're_ranking';
export interface GetProjectResultTypeTransformed {
  project: BaseProject & {
    advancedInputs: SolarAdvancedInputs | StorageAdvancedInputs;
  };
  rankingData?: SolarRankingData[];
  engineResultsList: SolarEngineResultType[];
}

export interface GetProjectListResultType {
  items: ProjectResume[];
  count: number;
}

export type GetAdminProjectListResultType = PaginatedQueryData;

export interface StorageProjectDetails {
  id: string;
  uuid: string;
}

export interface ReadPermissions {
  read: boolean;
}

export interface WritePermissions {
  write: boolean;
}

export type ReadWritePermissions = ReadPermissions & WritePermissions;

export interface CreatePermissions {
  create: boolean;
}

export type AdvancedInputsPermissions = ReadWritePermissions;

export interface SolarPermissions {
  advancedInputs: AdvancedInputsPermissions;
  analyticsData: ReadWritePermissions;
}

export interface StorageModulesPermissions {
  properties: {
    dc_block: {
      ProductName: ReadWritePermissions;
    };
    pcs_module: {
      ProductName: ReadWritePermissions;
    };
    bol_architecture: {
      Table: ReadWritePermissions;
    };
    life_cycle_cost: {
      Table: ReadWritePermissions;
    };
    capacity_maintenance: {
      Table: ReadWritePermissions;
    };
    augmentation_csv: {
      EnableDownload: ReadWritePermissions;
    };
  };
}

export interface StoragePermissions {
  advancedInputs: AdvancedInputsPermissions;
  modules: StorageModulesPermissions;
  actions: any;
}

export interface SolarProjectTransformedResultType {
  project: SolarProject;
  rankingData?: Record<string, SolarRankingData[]>;
  engine?: EngineResult;
  lastUpdated?: string;
  permissions: SolarPermissions;
  modules: Record<string, EngineResultModule>;
  available_analytics_data: Record<string, AnalyticsSchema>;
}

export interface StorageProjectResultType {
  project: StorageProject;
  permissions: StoragePermissions;
  available_analytics_data: Record<string, AnalyticsSchema>;
  modulesP360: Record<string, any>;
}

export type StorageEngineResult = Record<
  string,
  EngineResultSizingArchitecture
>;

export interface StorageEngineResultType {
  projectId: string;
  engine: string;
  engineInputData: EngineInputData;
  discountRatePercent: number;
  analytics_data_schema: Record<string, AnalyticsSchema>;
}
export interface StorageEngineTransformedResultType {
  projectId: string;
  storageRanking: StorageRanking[];
  analytics_data_schema: Record<string, AnalyticsSchema>;
}

export type ProjectPayload = ProjectState;

interface SolarEngineError {
  message: string;
  traceback: string;
  failed_node: 'cost' | 'finance' | 'iteration' | 'performance';
  is_exception: boolean;
}
export interface SolarEngineResultType {
  request_id: string;
  state: EngineResult;
  error: SolarEngineError;
  version: string;
  triggered_by: TriggeredByType;
  created_at: Date;
}

export interface ModuleFilePermissions {
  read: boolean;
  write: boolean;
}

export type ModuleFilesPermissions = Record<FileTypes, ModuleFilePermissions>;

export interface ModulePermissions {
  files: ModuleFilesPermissions;
  moduleDetailsPage: {
    solar: {
      isVisible: boolean;
    };
  };
}

export enum PORT {
  EAST = 'EAST',
  WEST = 'WEST',
  MIDWEST = 'MIDWEST',
}
export enum SHIPPING_COST {
  STANDARD = 'STANDARD',
  HIGH = 'HIGH',
}

export interface LocationModifier {
  city: string;
  created_at: string;
  energy_price: string;
  fixed_tilt_a: number;
  fixed_tilt_b: number;
  fixed_tilt_c: number;
  fixed_tilt_d: number;
  fixed_tilt_e: number;
  id: number;
  is_deleted: false;
  latitude: number;
  longitude: number;
  modifier: string;
  port: PORT;
  retail_price: string;
  shipping: SHIPPING_COST;
  single_axis_tracker_a: number;
  single_axis_tracker_b: number;
  single_axis_tracker_c: number;
  single_axis_tracker_d: number;
  single_axis_tracker_e: number;
  state: string;
  tax: string;
  updated_at: string;
  uuid: string;
}

export type DCBlockQuote = {
  tariff_percent: number;
  east_coast_shipping_USD_per_kWh: number;
  midwest_coast_shipping_USD_per_kWh: number;
  west_coast_shipping_USD_per_kWh: number;
  default_shipping_cost_USD_per_kWh: number;
};

export type DCBlockQuoteConstraint = {
  uuid: string;
  dc_block_uuid: string;
  dc_block_quote_uuid: string;
  commissioning_cost_USD_per_kWh: number;
  fob_cost_USD_per_kWh: number;
  type: string;
  numeric_value: number;
  updated_at: string;
  created_at: string;
};

export type DCBlocks = {
  name: string;
  created_at: string;
  updated_at: string;
  uuid: string;
  active: boolean;
}[];

export interface DCBlock {
  name: string;
  created_at: string;
  updated_at: string;
  uuid: string;
  active: boolean;
  dc_block_quote_data: DCBlockQuote;
  dc_block_quote_constraints: DCBlockQuoteConstraint[];
}

export type UpsertProjectFilterArgs = {
  projectId: string;
  filters: Filter[];
  module_filter_set_uuid?: string;
  selected_columns?: Record<string, any[]>;
};

export type UpsertProjectFilterResponse = {
  filters: Filter[];
  module_filter_set_uuid: string;
};
