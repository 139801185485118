import React, { useState, useEffect } from 'react';
import { Option } from 'antd/lib/mentions';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { RouteComponentProps, useParams } from 'react-router';
import type { UploadFile } from 'antd/es/upload/interface';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';

import {
  Form,
  InputAnt as Input,
  ButtonAnt as Button,
  Table,
  Overlay,
  Spinner,
  Switch,
  DatePicker,
  FormItemLabel,
} from '~/UI';
import { IconEdit } from '~/UI/Icons';
import { blockEnterKeyEvents } from '~/utils/events';
import {
  theme,
  requiredRule,
  catchError,
  formErrorScroll,
  colors,
  COMPANY_TYPES,
  notRequiredRules,
  optionalEmailRule,
} from '~/utils';
import { AdminFormLayout } from '~/components/Admin/AdminLayout/AdminFormLayout';
import ImageFileInput from '~/UI/ImageFileInput';
import { getUploadUrl, uploadFile } from '~/services/api/files';
import { LogoData } from '~/store/project';
import {
  useCreateCompanyMutation,
  useGetProductOfferingsQuery,
  useUpdateCompanyMutation,
} from '~/store/api';
import { SelectFormItem } from '~/UI/Select';
import { DomainType } from '~/types/users';
import { rtkService, CompanyResponse } from '~/types/rtkApi';
import { Company, CreateCompanyResultType } from '~/store/api/companies/types';
import FormFooter from '~/components/FormFooter';
import useFormDirty from '~/hooks/useFormDirty';
import ButtonWithAnalytics from '~/UI/ButtonWithAnalytics';
import { GTM_SOURCE_IDS } from '~/utils/gtm';
import { ADMIN_ROUTES } from '../../../router/AdminRoutes';
import { allCompanyTypes } from '../../../routes/AdminCompanies';
import AdminProjectUserDropdown from '../Projects/AdminProjectsForm/AdminProjectUserDropdown';

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 46px;
  .ant-picker-input input {
    height: 48px;
    color: ${theme.colors.charcoal};
    &::placeholder {
      color: ${theme.colors.stone};
    }
    font-size: 16px;
  }
`;

export const StyledAdminForm = styled(AdminFormLayout)<{ isInModal?: boolean }>`
  ${({ isInModal }) =>
    isInModal &&
    `
      margin-top: 20px;
      overflow-y: auto;
      max-height: 65vh;
      padding-right: 20px;
    `}
`;

export const StyledBox = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

const allowedLogoSizeInMBs = 2;

const AdminCompaniesForm: React.FC<
  RouteComponentProps & {
    service: rtkService;
    onFinishCallback: (value?: CreateCompanyResultType) => void;
    createMode?: boolean;
    isInModal?: boolean;
  }
> = ({
  service,
  history,
  onFinishCallback = () => {},
  createMode,
  isInModal,
}) => {
  const { id }: { id?: string | null } = createMode
    ? { id: null }
    : useParams() || {};
  const [form] = Form.useForm();
  const [companyDomains, setCompanyDomains] = useState<DomainType[]>([]);
  const [accountManagerEmail, setAccountManagerEmail] = useState<string>();
  const [isUsersDataLoading, setUsersDataIsLoading] = useState<boolean>(true);
  const [logoData, setLogoData] =
    useState<Omit<LogoData, 'file_location_path'>>();
  const [fileToDisplay, setFileToDisplay] = useState<UploadFile>({
    uid: '',
    name: '',
  });
  const [fileError, setFileError] = useState('');
  const [createCompany] = useCreateCompanyMutation({});
  const [updateCompany] = useUpdateCompanyMutation();
  const { data = null, isLoading = false } = id
    ? (service.get({ id }) as CompanyResponse)
    : {};
  const { data: productOfferings } = useGetProductOfferingsQuery();

  const setUsersDataLoadingState = (userDataLoading: boolean) =>
    setUsersDataIsLoading(userDataLoading);

  const domainColumns = [
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      isSortable: false,
      isFilterable: false,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (domainId: string) => (
        <Button
          size="small"
          color={theme.colors.primary}
          onClick={() => history.push(ADMIN_ROUTES.DOMAINS.path.edit(domainId))}
        >
          <IconEdit />
        </Button>
      ),
    },
  ];

  const checkInvalid = async () => {
    try {
      await form.validateFields(['name', 'company_type']);
    } catch (error) {
      formErrorScroll();
    }
  };

  const companyType = Form.useWatch('company_type', form);

  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        domain: '',
        status: 'pending',
        engaged_solar: false,
        engaged_storage: false,
      });
    } else if (data) {
      const {
        domains,
        accountManagerUser,
        nda_expiration_date,
        logoData: logoFetched,
        company_products,
        ...other
      } = data;

      if (accountManagerUser && accountManagerUser.email) {
        setAccountManagerEmail(accountManagerUser.email);
      }

      if (logoFetched) {
        const { content_type, file_name, file_location_path } = logoFetched;
        setFileToDisplay((current) => ({
          ...current,
          thumbUrl: file_location_path,
          name: file_name,
        }));
        setLogoData({ content_type, file_name });
      }

      setCompanyDomains(domains);
      form.setFieldsValue({
        nda_expiration_date: nda_expiration_date
          ? dayjs(nda_expiration_date)
          : null,
        company_products: company_products.map(({ product_id }) => product_id),
        ...other,
      });
      checkInvalid();
    }
  }, [isLoading]);

  const goBack = (value: CreateCompanyResultType) => {
    if (!createMode) {
      history.push(ADMIN_ROUTES.COMPANIES.path.default);
    } else {
      onFinishCallback(value);
    }
  };

  const onFinish = async ({
    domains,
    ...values
  }: Company & { domains: DomainType }) => {
    const companyData: Company = {
      ...values,
      ...(logoData && { file_data: { ...logoData } }),
    };
    if (id) {
      updateCompany({
        ...companyData,
        id: +id,
      })
        .unwrap()
        .then(goBack)
        .then(() => form.resetFields());
    } else {
      createCompany({
        ...companyData,
      })
        .unwrap()
        .then(goBack)
        .then(() => form.resetFields());
    }
  };

  const onAssignAccountManager = (userId: number) => {
    form.setFieldsValue({ account_manager_user_id: userId });
  };

  const handleImageChange = async ({
    file,
    fileList,
  }: UploadChangeParam<UploadFile>) => {
    try {
      if (file && fileList.length && !fileError) {
        const {
          data: { url, fileName },
        } = await getUploadUrl(file.name, 'companyLogo');

        await uploadFile(url, file as RcFile);

        setFileToDisplay((current) => ({
          ...current,
          status: 'done',
          name: fileName,
          uid: file.uid,
        }));

        setLogoData({
          file_name: fileName,
          content_type: file?.type || '',
        });
      } else {
        setFileToDisplay((current) => ({
          ...current,
          thumbUrl: undefined,
        }));
        setLogoData(undefined);
      }
    } catch (error: unknown) {
      setFileToDisplay((current) => ({
        ...current,
        thumbUrl: undefined,
      }));
      setFileError('Error uploading file');
      catchError({
        location: 'Admin/Companies/AdminCompaniesForm',
        method: 'handleImageUpload',
        error,
      });
    }
  };

  const { isDirty, onValuesChange } = useFormDirty();

  return (
    <StyledAdminForm isInModal={isInModal}>
      <Form
        form={form}
        onFinish={onFinish}
        style={{ width: '100%' }}
        onKeyDown={blockEnterKeyEvents}
        onValuesChange={onValuesChange}
      >
        {(isLoading || isUsersDataLoading) && (
          <Overlay style={{ position: 'fixed', height: '100vh', zIndex: 9999 }}>
            <Spinner />
          </Overlay>
        )}
        <Form.Item
          name="name"
          label={<FormItemLabel>Name</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[requiredRule]}
        >
          <Input required name="name" placeholder="Insert Company Name" />
        </Form.Item>
        <SelectFormItem
          name="company_type"
          label={<FormItemLabel>Company Type</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[requiredRule]}
          selectProps={{
            placeholder: 'Company Type',
          }}
        >
          {allCompanyTypes?.map(({ text, value }) => (
            <Option value={value} key={`${Math.random()}`}>
              {text}
            </Option>
          ))}
        </SelectFormItem>
        {companyType === COMPANY_TYPES.vendor.code && (
          <Form.Item
            name="customer_facing_contact"
            label={
              <FormItemLabel>Email - Customer Facing Contact</FormItemLabel>
            }
            labelCol={{ span: 24 }}
            rules={[...notRequiredRules, optionalEmailRule]}
          >
            <Input
              name="customer_facing_contact"
              placeholder="Enter Supplier email address for Customers to contact Supplier"
              onChange={(e) => {
                if (e.target.value === '') {
                  form.setFieldValue('customer_facing_contact', null)
                }
              }}
            />
          </Form.Item>
        )}
        <AdminProjectUserDropdown
          name="account_manager_user_id"
          label="Account Manager"
          placeholder="Assign an Account Manager"
          initialValue={accountManagerEmail}
          selectedUser={form.getFieldValue('account_manager_user_id')}
          onAssignUser={onAssignAccountManager}
          setLoadingState={setUsersDataLoadingState}
        />
        <Form.Item
          name="nda_expiration_date"
          label={<FormItemLabel>NDA Expiration Date</FormItemLabel>}
          labelCol={{ span: 24 }}
        >
          <StyledDatePicker
            name="nda_expiration_date"
            placeholder="Select date"
            format="MM/DD/YYYY"
          />
        </Form.Item>
        <SelectFormItem
          name="company_products"
          label={<FormItemLabel>Product Offerings</FormItemLabel>}
          labelCol={{ span: 24 }}
          selectProps={{
            mode: 'multiple',
            placeholder: 'Select Product Offerings',
            options: productOfferings?.map(({ id, product }) => ({
              label: product,
              value: id,
            })),
          }}
        />
        <Form.Item
          name="is_automatic_approval"
          label={<FormItemLabel>Automatic Approval</FormItemLabel>}
          labelCol={{ span: 24 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="logo_image"
          label={<FormItemLabel>Logo</FormItemLabel>}
          labelCol={{ span: 24 }}
        >
          <ImageFileInput
            allowedLogoSizeInMBs={allowedLogoSizeInMBs}
            fileToDisplay={fileToDisplay}
            fileError={fileError}
            handleImageChange={handleImageChange}
            updateFile={(file) => setFileToDisplay(file)}
            updateFileError={(error) => setFileError(error)}
          />
        </Form.Item>
        <Form.Item
          name="domains"
          label={<FormItemLabel>Domains</FormItemLabel>}
          labelCol={{ span: 24 }}
        >
          <Table columns={domainColumns} dataSource={companyDomains} />
        </Form.Item>
        <Form.Item
          name="engaged_solar"
          label={<FormItemLabel>Engaged Solar</FormItemLabel>}
          labelCol={{ span: 24 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="engaged_storage"
          label={<FormItemLabel>Engaged Storage</FormItemLabel>}
          labelCol={{ span: 24 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="salesforce_id"
          label={<FormItemLabel>Salesforce ID</FormItemLabel>}
          labelCol={{ span: 24 }}
        >
          <Input name="salesforce_id" placeholder="Insert Salesforce ID" />
        </Form.Item>
        <Form.Item
          name="netsuite_account_id"
          label={<FormItemLabel>Netsuite Account ID</FormItemLabel>}
          labelCol={{ span: 24 }}
        >
          <Input
            name="netsuite_account_id"
            placeholder="Insert Netsuite Account ID"
          />
        </Form.Item>
        {isInModal ? (
          <StyledBox>
            <ButtonWithAnalytics
              gtmId={GTM_SOURCE_IDS.ADMIN.COMPANIES.SAVE_BUTTON}
              color={colors.primary}
              htmlType="submit"
            >
              Save
            </ButtonWithAnalytics>
          </StyledBox>
        ) : (
          <FormFooter
            isDirty={isDirty}
            saveButtonProps={{
              onClick: checkInvalid,
            }}
          />
        )}
      </Form>
    </StyledAdminForm>
  );
};

AdminCompaniesForm.defaultProps = {
  createMode: false,
};

export default AdminCompaniesForm;
