import { Formatter } from '~/components/Admin/LocationModifiers/AdminLocationModifiersForm';
import { InputNumberConfig, SelectConfig } from '~/types/inputs';
import {
  IntegerNoRoundSettings,
  PercentSettings,
  requiredRuleNoMessage,
} from '~/utils';
import {
  discontinuousRangeValidator,
  inRangeValidator,
  integerValidator,
} from '~/utils/numberValidators';

const targetAcPowerMw: InputNumberConfig = {
  rules: [
    { required: true, message: 'Target AC Power is required' },
    inRangeValidator(1, 5000, true),
  ],
  inputNumberProps: {
    suffix: 'MW AC',
    placeholder: 'Enter Target AC Power',
    precision: 2,
  },
};

const powerFactor: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    discontinuousRangeValidator({
      range1: [0.5, 1],
      range2: [-1, -0.5],
      inclusive: true,
    }),
  ],
  inputNumberProps: {
    step: 0.05,
    precision: 2,
    displayPrecision: 2,
    placeholder: 'Enter Power Factor',
  },
};

const durationHours: InputNumberConfig = {
  rules: [
    { required: true, message: 'Duration is required' },
    inRangeValidator(1, 10, true),
  ],
  inputNumberProps: {
    suffix: 'Hours',
    placeholder: 'Enter Duration',
    precision: 2,
  },
};

const meteringLocation: SelectConfig = {
  rules: [],
  selectProps: {
    placeholder: 'Choose Metering Location',
  },
};

const projectTopology: SelectConfig = {
  rules: [],
  selectProps: {
    placeholder: 'Choose Project Topology',
  },
};

const capacityMaintenanceTerm: InputNumberConfig = {
  rules: [
    {
      required: true,
      message: 'Capacity Maintenance Term is required',
    },
    inRangeValidator(0, 20, true),
    integerValidator(),
  ],
  inputNumberProps: {
    suffix: 'Years',
    placeholder: 'Enter Capacity Maintenance Term',
    formatter: IntegerNoRoundSettings.formatter as Formatter,
  },
};

const annualCycleCount: InputNumberConfig = {
  rules: [
    { required: true, message: 'Annual Cycle Count is required' },
    inRangeValidator(10, 720, true),
    integerValidator(),
  ],
  inputNumberProps: {
    suffix: 'Cycles',
    placeholder: 'Enter Annual Cycle Count',
    formatter: IntegerNoRoundSettings.formatter as Formatter,
  },
};

const capacityMaintenanceStrategy: SelectConfig = {
  rules: [],
  selectProps: {
    placeholder: 'Choose Capacity Maintenance Strategy',
  },
};

const resting_state_of_charge_percent: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      0,
      1,
      true,
      'Please enter Average Resting State of Charge in between 0% to 100%.'
    ),
  ],
  inputNumberProps: {
    suffix: '%',
    parser: PercentSettings.parser,
    formatter: PercentSettings.formatterWithFractionDigits(0),
  },
};

const depth_of_discharge_percent: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      0,
      1,
      true,
      'Please enter Depth of Discharge in between 0% to 100%.'
    ),
  ],
  inputNumberProps: {
    suffix: '%',
    parser: PercentSettings.parser,
    formatter: PercentSettings.formatterWithFractionDigits(0),
  },
};

const interconnection_voltage: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      2.3,
      500,
      true,
      'Please enter Interconnection Voltage in between 2.3 to 500.'
    ),
  ],
  inputNumberProps: {
    suffix: 'kV',
    precision: 2,
  },
};

const pcs_warranty_years: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      0,
      20,
      true,
      'Please enter PCS Extended Warranty Term in between 0 to 20.'
    ),
  ],
  inputNumberProps: {
    suffix: 'Years',
    precision: 0,
  },
};

const pcs_maintenance_years: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      0,
      20,
      true,
      'Please enter PCS Preventative Maintenance Term in between 0 to 20.'
    ),
  ],
  inputNumberProps: {
    suffix: 'Years',
    precision: 0,
  },
};

const dc_block_warranty_years: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      0,
      20,
      true,
      'Please enter AC / DC Block Extended Warranty Term in between 0 to 20.'
    ),
  ],
  inputNumberProps: {
    suffix: 'Years',
    precision: 0,
  },
};

const dc_block_maintenance_years: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      0,
      20,
      true,
      'Please enter AC / DC Block Preventative Maintenance Term in between 0 to 20.'
    ),
  ],
  inputNumberProps: {
    suffix: 'Years',
    precision: 0,
  },
};

const STORAGE_INPUT_CONFIG = {
  targetAcPowerMw,
  powerFactor,
  durationHours,
  meteringLocation,
  projectTopology,
  capacityMaintenanceTerm,
  annualCycleCount,
  capacityMaintenanceStrategy,
  advancedInputs: {
    resting_state_of_charge_percent,
    depth_of_discharge_percent,
    interconnection_voltage,
    pcs_warranty_years,
    pcs_maintenance_years,
    dc_block_warranty_years,
    dc_block_maintenance_years,
  },
};

export default STORAGE_INPUT_CONFIG;
