import * as R from 'ramda';

import {
  MAX_CURRENT_PURCHASE_VOLUME_KW,
  MIN_CURRENT_PURCHASE_VOLUME_KW,
} from '~/constants/projects';
import { Formatter } from '~/components/Admin/LocationModifiers/AdminLocationModifiersForm';
import { InputNumberConfig, RadioConfig, SelectConfig } from '~/types/inputs';
import {
  IntegerNoRoundSettings,
  PercentSettings,
  PercentSettingsFloor,
  integerFormatter,
  requiredRuleNoMessage,
} from '~/utils';
import {
  greaterThanValidator,
  inRangeValidator,
  integerValidator,
  lesserThanValidator,
} from '~/utils/numberValidators';
import { EnergyTerm } from '~/store/project';
import { Rule } from 'antd/lib/form';
import { InputNumberProps } from '~/UI/InputNumber';

const sizeInKw: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      100,
      10_000_000,
      true,
      'Please enter project size in between 100 to 10,000,000'
    ),
    integerValidator(),
  ],
  inputNumberProps: {
    placeholder: 'Enter project size in kW',
    suffix: 'kW DC',
    formatter: IntegerNoRoundSettings.formatter as Formatter,
  },
};

const rackingType: SelectConfig = {
  rules: [{ required: true, message: 'Racking Type is required' }],
  selectProps: {
    placeholder: 'Choose one',
  },
};

const isLargerPurchase: RadioConfig = {
  rules: [],
  radioProps: {
    buttonStyle: 'solid',
    size: 'large',
  },
};

const currentPurchaseVolumeKw = {
  applyRules: (
    getOptions: () => {
      showCurrentPurchaseVolume: boolean;
      sizeInKw: number;
    }
  ) => {
    const { showCurrentPurchaseVolume, sizeInKw } = getOptions();
    return [
      {
        required: showCurrentPurchaseVolume,
        message: 'Annual Purchase Volume is required.',
      },
      greaterThanValidator(
        sizeInKw > MIN_CURRENT_PURCHASE_VOLUME_KW
          ? sizeInKw
          : MIN_CURRENT_PURCHASE_VOLUME_KW,
        true,
        `Transaction volume cannot be less than ${
          sizeInKw > MIN_CURRENT_PURCHASE_VOLUME_KW
            ? 'Project Size'
            : `${MIN_CURRENT_PURCHASE_VOLUME_KW} kW DC`
        }.`,
        false
      ),
      lesserThanValidator(
        MAX_CURRENT_PURCHASE_VOLUME_KW,
        true,
        `Transaction cannot exceed ${integerFormatter(
          MAX_CURRENT_PURCHASE_VOLUME_KW
        )} kW DC`,
        false
      ),
      integerValidator(),
    ];
  },
  inputNumberProps: {
    suffix: 'kW DC',
    formatter: IntegerNoRoundSettings.formatter as Formatter,
  },
};

const energy_rate_value_USD_per_kWh: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      0,
      1,
      true,
      'Please enter energy value in between 0 to 1.'
    ),
  ],
  inputNumberProps: {
    suffix: '$/kWh',
    precision: 4,
    displayPrecision: 4,
  },
};

const energy_rate_escalator_percent: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      0,
      1,
      true,
      'Please enter escalation rate in between 0% to 100%, e.g. 30%.'
    ),
  ],
  inputNumberProps: {
    suffix: '%',
    precision: 4,
    displayPrecision: 2,
    parser: PercentSettings.parser,
    formatter: PercentSettings.formatterWithFractionDigits(2),
  },
};
const target_rate_of_return_percent: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      0,
      0.5,
      true,
      'Please enter discount rate in between 0% to 50%, e.g. 30%.'
    ),
  ],
  inputNumberProps: {
    suffix: '%',
    precision: 4,
    displayPrecision: 2,
    parser: PercentSettings.parser,
    formatter: PercentSettings.formatterWithFractionDigits(2),
  },
};

const project_term_years: {
  generateRules: (currentEnergyTerms: EnergyTerm[]) => Rule[];
  inputNumberProps: InputNumberProps;
} = {
  generateRules: (currentEnergyTerms: EnergyTerm[]): Rule[] => {
    return [
      requiredRuleNoMessage,
      {
        validator: (_, value) => {
          const foundInvalidEnergyTerm = currentEnergyTerms.find(
            ({ duration, start_year }) => {
              return (
                R.is(Number, duration) &&
                R.is(Number, start_year) &&
                duration + start_year > value + 1
              );
            }
          );
          if (foundInvalidEnergyTerm)
            return Promise.reject(
              new Error(
                'Project term incompatible with existing energy term(s). Please revise before saving.'
              )
            );
          return Promise.resolve();
        },
      },
      inRangeValidator(
        10,
        50,
        true,
        'Please enter project term in between 10 to 50.'
      ),
      integerValidator(),
    ];
  },
  inputNumberProps: {
    suffix: 'years',
  },
};

const baseline_ac_size_kW: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      50,
      10_000_000,
      true,
      'Enter a AC Size between 50kW AC - 10,000,000kW AC.'
    ),
  ],
  inputNumberProps: {
    suffix: 'kW AC',
    precision: 3,
    displayPrecision: 3,
  },
};

const baseline_ground_coverage_ratio: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      0.2,
      0.8,
      true,
      'Enter a Ground Coverage Ratio between 20% to 80%.'
    ),
  ],
  inputNumberProps: {
    suffix: '%',
    precision: 2,
    displayPrecision: 2,
    step: 0.01,
    parser: PercentSettings.parser,
    formatter: PercentSettingsFloor.formatterWithFractionDigits(2),
  },
};

const base_credit: InputNumberConfig = {
  rules: [
    inRangeValidator(
      0,
      1,
      true,
      'Please enter percentage in between 0% to 100%',
      true
    ),
  ],
  inputNumberProps: {
    suffix: '%',
    precision: 4,
    displayPrecision: 2,
    parser: PercentSettings.parser,
    formatter: PercentSettings.formatterWithFractionDigits(2),
  },
};
const ptc_base_credit: InputNumberConfig = {
  rules: [
    inRangeValidator(
      0,
      1,
      false,
      'Please enter a value greater than 0 and less than 1 $/kwh',
      true,
      false
    ),
  ],
  inputNumberProps: {
    suffix: '$ / kWh',
    precision: 4,
    displayPrecision: 4,
  },
};

const escalation_rate: InputNumberConfig = {
  rules: [
    requiredRuleNoMessage,
    inRangeValidator(
      0,
      1,
      true,
      'Please enter percentage in between 0% to 100%',
      true
    ),
  ],
  inputNumberProps: {
    suffix: '%',
    precision: 4,
    displayPrecision: 2,
    parser: PercentSettings.parser,
    formatter: PercentSettings.formatterWithFractionDigits(2),
  },
};

const SOLAR_INPUT_CONFIG = {
  sizeInKw,
  rackingType,
  isLargerPurchase,
  currentPurchaseVolumeKw,
  base_credit,
  escalation_rate,
  ptc_base_credit,
  advancedInputs: {
    energy_rate_value_USD_per_kWh,
    energy_rate_escalator_percent,
    target_rate_of_return_percent,
    project_term_years,
    baseline_ac_size_kW,
    baseline_ground_coverage_ratio,
  },
};

export default SOLAR_INPUT_CONFIG;
