import React from 'react';

import { Form, Option, InputNumber, FormInstance } from '~/UI';
import { StyledDatePicker } from '~/UI/DatePicker';
import { rackingTypes } from '~/utils/rackingTypes';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TestIdProps,
} from '~/utils/dataTestProps';
import SOLAR_INPUT_CONFIG from '~/constants/inputs/project/solarInputs';
import PROJECT_INPUT_CONFIG from '~/constants/inputs/project/projectInputs';
import { SelectFormItem } from '~/UI/Select';
import { disabledDateByQuarter } from '~/utils';

import FormGroup, { GenericLabel } from './FormGroup';

interface SolearFormGroupProps {
  form: FormInstance;
  testIdData?: TestIdProps;
}

const SolarFormGroup: React.FC<SolearFormGroupProps> = ({
  form,
  testIdData,
}) => {
  return (
    <>
      <FormGroup>
        <div>
          <Form.Item
            name="arrival_date"
            label={
              <GenericLabel
                title="Delivery Date"
                popoverContent="The date you expect products to arrive onsite."
                testIdData={
                  { ...testIdData, identifier: 'delivery-date' } as TestIdProps
                }
              />
            }
            rules={PROJECT_INPUT_CONFIG.arrivalDate.rules}
            required={false}
          >
            <StyledDatePicker
              data-testid={generateTestId({
                component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.DATE_PICKER}`,
                identifier: 'delivery-date',
              })}
              disabledDate={disabledDateByQuarter}
              {...PROJECT_INPUT_CONFIG.arrivalDate.datePickerProps}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name="size_in_kw"
            label={
              <GenericLabel
                title="Project Size"
                testIdData={
                  { ...testIdData, identifier: 'project-size' } as TestIdProps
                }
              />
            }
            rules={SOLAR_INPUT_CONFIG.sizeInKw.rules}
            required={false}
          >
            <InputNumber
              data-testid={generateTestId({
                component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'project-size',
              })}
              {...SOLAR_INPUT_CONFIG.sizeInKw.inputNumberProps}
            />
          </Form.Item>
        </div>

        <div style={{ gridColumn: '1/3' }}>
          <SelectFormItem
            name="racking_type"
            label={
              <GenericLabel
                title="Racking Type"
                testIdData={
                  { ...testIdData, identifier: 'racking-type' } as TestIdProps
                }
              />
            }
            labelCol={{ span: 24 }}
            rules={SOLAR_INPUT_CONFIG.rackingType.rules}
            selectProps={{
              ...SOLAR_INPUT_CONFIG.rackingType.selectProps,
              'data-testid': generateTestId({
                component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.SELECT}`,
                identifier: 'racking-type',
              }),
            }}
            required={false}
          >
            {rackingTypes.map((rackingType, index) => (
              <Option
                data-testid={generateTestId({
                  component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.SELECT_OPTION}`,
                  identifier: 'racking-type',
                  indices: index.toString(),
                })}
                key={rackingType}
                value={rackingType}
              >
                {rackingType}
              </Option>
            ))}
          </SelectFormItem>
        </div>
      </FormGroup>
      <Form.Item
        name="anticipated_purchase_date"
        label={
          <GenericLabel
            title="Anticipated Purchase Date"
            popoverContent="The date you expect to execute a contract with Anza."
            testIdData={
              {
                ...testIdData,
                identifier: 'anticipated-purchase-date',
              } as TestIdProps
            }
          />
        }
        rules={PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyRules(
          () => form.getFieldsValue(['arrival_date']).arrival_date
        )}
        required={false}
      >
        <StyledDatePicker
          data-testid={generateTestId({
            component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.DATE_PICKER}`,
            identifier: 'anticipated-date',
          })}
          {...PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyDatePickerProps(
            () => form.getFieldsValue(['arrival_date']).arrival_date
          )}
          inputReadOnly
        />
      </Form.Item>
    </>
  );
};

export default SolarFormGroup;
