import * as R from 'ramda';

import type {
  ProjectStatus,
  SolarProject,
  StorageProject,
} from '~/store/project';
import { Item } from '~/UI/CollapsibleHeroModule';
import { IntegerSettings, decimalFormatter } from '~/utils';

interface MappingValues {
  [key: string]: Mapping;
}

interface Mapping {
  key: string;
  clientFacingValue: string;
}

const getMappingByKeyOrDefault = (
  key: string,
  mappings: MappingValues,
  defaultValue: string
) => {
  if (R.isNil(key) || R.isEmpty(key)) {
    return defaultValue;
  }

  const mappingForKey = Object.values(mappings).find(
    (mapping: Mapping) => mapping.key === key
  ) as Mapping;

  return R.isNil(mappingForKey)
    ? defaultValue
    : mappingForKey.clientFacingValue;
};

export const PROJECT_TYPES: MappingValues = {
  solar: { key: 'solar', clientFacingValue: 'Solar' },
  storage: { key: 'storage', clientFacingValue: 'Storage' },
  solarStorage: { key: 'solar_storage', clientFacingValue: 'Solar + Storage' },
};

export const getProjectTypeClientFacingValueOrDefault = (
  projectType: string,
  defaultValue: string
) => {
  return getMappingByKeyOrDefault(projectType, PROJECT_TYPES, defaultValue);
};

export const PROJECT_STATUSES_BUYER: MappingValues = {
  created: { key: 'created', clientFacingValue: 'Created' },
  proposal: { key: 'proposal', clientFacingValue: 'Proposal' },
  inactive: { key: 'inactive', clientFacingValue: 'Closed' },
};

export const PROJECT_STATUSES: MappingValues = {
  ...PROJECT_STATUSES_BUYER,
  approved: { key: 'approved', clientFacingValue: 'Approved' },
  awarded: { key: 'awarded', clientFacingValue: 'Closed' },
  lost: { key: 'lost', clientFacingValue: 'Closed' },
};

export const projectStatusesBuyerArray = Object.values(
  PROJECT_STATUSES_BUYER
).map((item) => ({
  text: item.clientFacingValue,
  value: item.key,
}));

export const isStatusClosed = (status: ProjectStatus) => {
  switch (status) {
    case PROJECT_STATUSES.awarded.key:
    case PROJECT_STATUSES.lost.key:
    case PROJECT_STATUSES.inactive.key:
      return true;
    default:
      return false;
  }
};

export const PROJECT_STATUS_STYLES = {
  [PROJECT_STATUSES.created.key]: {
    color: '#FFC107',
    backgroundColor: '#FFFAEB',
  },
  [PROJECT_STATUSES.proposal.key]: {
    color: '#5259FB',
    backgroundColor: '#EAEBFF',
  },
  [PROJECT_STATUSES.inactive.key]: {
    color: '#6C757D',
    backgroundColor: '#E9EBEC',
  },
  [PROJECT_STATUSES.awarded.key]: {
    color: '#6C757D',
    backgroundColor: '#E9EBEC',
  },
  [PROJECT_STATUSES.lost.key]: {
    color: '#6C757D',
    backgroundColor: '#E9EBEC',
  },
};

export const getSolarProjectDetailsItems = (project: SolarProject): Item[] => [
  {
    key: 'project-size',
    label: 'Project Size',
    value: `${IntegerSettings.formatter(
      project.project_details.size_in_kw
    )} kW DC`,
  },
  {
    key: 'racking-type',
    label: 'Racking Type',
    value: project.project_details.racking_type,
  },
];

export const getStorageProjectDetailsItems = (
  project: StorageProject
): Item[] => {
  return [
    {
      key: 'target-ac-power',
      label: 'Target AC Power',
      value: `${decimalFormatter(
        project.project_details.target_ac_power_mw
      )} MW AC`,
    },
    {
      key: 'duration',
      label: 'Duration',
      value: `${project.project_details.duration_hours} Hours`,
    },
    {
      key: 'power-factor',
      label: 'Power Factor',
      value: `${project.project_details.power_factor}`,
    },
    {
      key: 'annual-cycle-count',
      label: 'Annual Cycle Count',
      value: `${project.project_details.annual_cycle_count} Cycles`,
    },
    {
      key: 'metering-location',
      label: 'Metering Location',
      value: project.project_details.metering_location,
    },
    {
      key: 'capacity-maintenance-term',
      label: 'Capacity Maintenance Term',
      value: `${project.project_details.capacity_maintenance_term} Years`,
    },
    {
      key: 'capacity-maintenance-strategy',
      label: 'Capacity Maintenance Strategy',
      value: project.project_details.capacity_maintenance_strategy,
    },
    {
      key: 'project-topology',
      label: 'Project Topology',
      value: project.project_details.project_topology,
    },
  ];
};

export const MIN_CURRENT_PURCHASE_VOLUME_KW = 100;

export const MAX_CURRENT_PURCHASE_VOLUME_KW = 10_000_000;

export const MAX_AVAILABILITY_MW = {
  min: 0,
  max: 10_000,
};

export const DDP_EAST_USD_PER_W_MIN = {
  min: 0,
  max: 1,
};

export const SHIPPING_ADDER_PER_W = {
  min: 0,
  max: 0.2,
  example: 0.015,
};

export const PORT_ADDER_USD_PER_W = {
  min: -0.1,
  max: 0.1,
  example: 0.015,
};
