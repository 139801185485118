import { RuleObject } from 'antd/lib/form';
import { decimalFormatter } from '~/utils/formatters';

export const requiredRule = {
  required: true,
  message: 'Please input a value for this field',
};

export const requiredFieldRule = {
  required: true,
  message: 'This field is required.',
};

export const notRequiredRules = [{ required: false }];

export const int4Rule = {
  validator: (_: RuleObject, value: string | number, limit: number) => {
    if (value > limit) {
      return Promise.reject(
        new Error(`Value cannot be greater than ${decimalFormatter(limit)}.`)
      );
    }

    return Promise.resolve();
  },
};

export const phoneNumberRule = {
  validator: (_: RuleObject, value: string) => {
    if (
      value.length > 0 &&
      !/^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        value
      )
    ) {
      return Promise.reject(new Error(`Please input a valid phone number.`));
    }
    return Promise.resolve();
  },
};

export const requiredRuleNoMessage = {
  required: true,
  message: '',
};

/**
  ^: Start of the string.
  [a - zA - Z0 - 9] +: Recipient must start with a letter or number.
  ([._ -][a - zA - Z0 - 9] +) *: Allows underscores, periods, or hyphens followed by a letter or number, repeated any number of times.
  @: Literal "@" symbol separating the recipient and domain.
  [a - zA - Z0 - 9 -] +: Domain part with letters, numbers, and hyphens.
  \.: Literal dot separating the domain and TLD.
  [a - zA - Z]: TLD must start with a letter.
  ([a - zA - Z0 - 9 -] * [a - zA - Z]){ 1,}: Allows letters, numbers, and hyphens in the TLD but must end with a letter.The { 1,} ensures the TLD is at least 2 characters long.
  $: End of the string.
 */
export const optionalEmailRule = {
  validator: (_: RuleObject, value: string) => {
    if (!value) {
      return Promise.resolve();
    }

    if (
      value.length > 0 &&
      !/^[a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z]){1,}$/.test(
        value
      )
    ) {
      return Promise.reject(new Error(`Please input a valid email.`));
    }

    return Promise.resolve();
  },
};
