import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { DefaultOptionType } from 'antd/lib/select';
import { useWatch } from 'antd/es/form/Form';
import styled from '@emotion/styled';
import { Form, notification, FormItemLabel, InputNumber } from '~/UI';
import {
  DecimalSettingsFloor,
  getQuarterRangeFromToday,
  integerFormatter,
  requiredFieldRule,
} from '~/utils';
import Modal from '~/UI/ModalAnt';
import {
  useAddProjectSpecificPricingMutation,
  useEditProjectSpecificPricingMutation,
} from '~/store/api/admin/projectSpecificPricingApi';
import type {
  AddProjectSpecificPricingPayload,
  ProjectSpecificPricingResponse,
} from '~/types/projectSpecificPricing';
import type { ModuleType } from '~/types/modules';
import { SelectFormItem } from '~/UI/Select';
import { inRangeValidator } from '~/utils/numberValidators';
import {
  DDP_EAST_USD_PER_W_MIN,
  MAX_AVAILABILITY_MW,
  PORT_ADDER_USD_PER_W,
  SHIPPING_ADDER_PER_W,
} from '~/constants/projects';
import { PORT, SHIPPING_COST } from '~/store/project';
import {
  getModuleById,
  getModulePortAdderByPort,
  getModuleQuarter,
  getModuleShippingAdderByShippingCost,
} from '~/utils/modules';
import confirmModal from '~/utils/confirmModal';
import { Formatter } from '../../LocationModifiers/AdminLocationModifiersForm';

type ProjectSpecificPricingModalProps = {
  projectUuid: string;
  onCancel: () => void;
  modules: ModuleType[];
  open: boolean;
  pspAlreadyExists: (module_uuid: string, quarter: string) => boolean;
  selectedModule?: ProjectSpecificPricingResponse;
  projectPort?: PORT;
  projectShippingCost?: SHIPPING_COST;
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ProjectSpecificPricingModal = ({
  open,
  onCancel,
  projectUuid,
  modules,
  selectedModule,
  pspAlreadyExists,
  projectPort,
  projectShippingCost,
}: ProjectSpecificPricingModalProps) => {
  const [existingPsp, setExistingPsp] = useState(false);
  const [addProjectSpecificPricing, { isLoading: isLoadingCreateAction }] =
    useAddProjectSpecificPricingMutation();
  const [editProjectSpecificPricing, { isLoading: isLoadingEditAction }] =
    useEditProjectSpecificPricingMutation();
  const [form] = Form.useForm<AddProjectSpecificPricingPayload>();
  const selectedQuarter = useWatch('quarter', form);
  const selectedModuleUuid = useWatch('module_uuid', form);
  const selectedModuleData = getModuleById(selectedModuleUuid, modules);
  const selectedModuleQuarter = getModuleQuarter(
    selectedModuleUuid,
    selectedQuarter,
    modules
  );

  const isEditing = !!selectedModule;

  useEffect(() => {
    if (selectedModule) {
      form.setFieldsValue(selectedModule);
    } else {
      form.resetFields();
    }
  }, [selectedModule]);
  const handleAddProjectSpecificPricing = () => {
    const projectSpecificPricingValues = form.getFieldsValue();
    addProjectSpecificPricing({
      projectUuid,
      moduleUuid: projectSpecificPricingValues.module_uuid,
      ...projectSpecificPricingValues,
    })
      .unwrap()
      .then(() => {
        onCancel?.();
      })
      .catch(() => {
        notification.error({
          message: 'Something went wrong.',
          description:
            'It looks like there was an error while adding the project specific pricing. Please refresh your browser and try again.',
        });
      });
  };

  const handleEditProjectSpecificPricing = () => {
    const { module_uuid, ...projectSpecificPricingValues } =
      form.getFieldsValue();
    editProjectSpecificPricing({
      projectUuid,
      ...projectSpecificPricingValues,
      uuid: selectedModule!.uuid,
    })
      .unwrap()
      .then(() => {
        onCancel?.();
      })
      .catch(() => {
        notification.error({
          message: 'Something went wrong.',
          description:
            'It looks like there was an error while editing the project specific pricing. Please refresh your browser and try again.',
        });
      });
  };

  const onCreate = () => {
    form.validateFields().then(() => {
      if (isEditing) {
        handleEditProjectSpecificPricing();
      } else {
        handleAddProjectSpecificPricing();
      }
    });
  };

  const handleModuleFilter = (
    input: string,
    option: DefaultOptionType | undefined
  ) => {
    return (option?.label?.toString() ?? '')
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleModuleSort = (
    optionA: DefaultOptionType,
    optionB: DefaultOptionType
  ) =>
    (optionA?.label?.toString() ?? '')
      .toLowerCase()
      .localeCompare((optionB?.label?.toString() ?? '').toLowerCase());

  const validateExistence = (isModule = false) => {
    const { module_uuid, quarter } = form.getFieldsValue();
    const isDuplicated = pspAlreadyExists(module_uuid, quarter);
    setExistingPsp(isDuplicated);
    if (isModule && quarter) form.validateFields();
  };

  const quarterRuleValidator = () => {
    if (existingPsp) {
      return Promise.reject(
        new Error('This quarter has been selected for this module.')
      );
    }
    return Promise.resolve();
  };

  return (
    <Modal
      destroyOnClose
      title={
        isEditing
          ? 'Edit Project Specific Pricing'
          : 'Add Project Specific Pricing'
      }
      open={open}
      onCancel={() => {
        const fieldsToCheck = Object.keys(form.getFieldsValue() || {});
        const formIsDirty = form.isFieldsTouched(fieldsToCheck);
        if (formIsDirty) {
          confirmModal({
            title: 'You have unsaved changes in your Project Specific Pricing',
            onOk: () => {
              form.resetFields();
              setExistingPsp(false);
              onCancel?.();
            },
          });
        } else {
          form.resetFields();
          setExistingPsp(false);
          onCancel?.();
        }
      }}
      onOk={onCreate}
      okButtonProps={{
        loading: isLoadingCreateAction || isLoadingEditAction,
        disabled: existingPsp,
      }}
    >
      <StyledForm form={form} preserve={false}>
        <SelectFormItem
          name="module_uuid"
          rules={[requiredFieldRule]}
          label={<FormItemLabel>Module</FormItemLabel>}
          labelCol={{ span: 24 }}
          selectProps={{
            placeholder: 'Choose a module',
            allowClear: true,
            options: modules
              .filter((item) => !item.is_anza_deactivated)
              .map((item) => ({
                label: `${item.company.name} ${item.name}`,
                value: item.uuid,
              })),
            disabled: isEditing,
            showSearch: true,
            filterOption: handleModuleFilter,
            onChange: () => validateExistence(true),
            filterSort: handleModuleSort,
          }}
        />
        <SelectFormItem
          name="quarter"
          rules={[requiredFieldRule, { validator: quarterRuleValidator }]}
          label={<FormItemLabel>Quarter</FormItemLabel>}
          labelCol={{ span: 24 }}
          selectProps={{
            placeholder: 'Choose a quarter',
            allowClear: true,
            options: getQuarterRangeFromToday(10).map(({ label }) => ({
              label,
              value: label,
            })),
            onChange: validateExistence,
          }}
        />
        <Form.Item
          name="ddp_east_USD_per_W"
          label={<FormItemLabel>Price</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[
            inRangeValidator(
              DDP_EAST_USD_PER_W_MIN.min,
              DDP_EAST_USD_PER_W_MIN.max,
              true,
              `Introduce a value between ${DDP_EAST_USD_PER_W_MIN.min} and ${DDP_EAST_USD_PER_W_MIN.max}`,
              false
            ),
          ]}
          extra={
            selectedModuleQuarter &&
            `Module Price: ${
              R.isNil(selectedModuleQuarter.ddp_east_coast_port)
                ? '—'
                : selectedModuleQuarter.ddp_east_coast_port
            }`
          }
        >
          <InputNumber
            name="ddp_east_USD_per_W"
            data-testid="ddp_east_USD_per_W"
            precision={3}
            suffix="$/W"
          />
        </Form.Item>
        <Form.Item
          name="port_adder_USD_per_W"
          label={<FormItemLabel>Port Adder</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[
            {
              required: false,
              validator: inRangeValidator(
                PORT_ADDER_USD_PER_W.min,
                PORT_ADDER_USD_PER_W.max,
                true,
                `Please enter a number between ${PORT_ADDER_USD_PER_W.min} and ${PORT_ADDER_USD_PER_W.max}, ex. ${PORT_ADDER_USD_PER_W.example}`,
                false
              ).validator,
            },
          ]}
          extra={
            selectedModuleQuarter &&
            projectPort &&
            selectedModuleData &&
            `Module ${projectPort} Port Adder: ${getModulePortAdderByPort(
              projectPort,
              selectedModuleData
            )}`
          }
        >
          <InputNumber
            name="port_adder_USD_per_W"
            data-testid="port_adder_USD_per_W"
            precision={3}
            suffix="$/W"
          />
        </Form.Item>
        <Form.Item
          name="shipping_adder_per_W"
          label={<FormItemLabel>Shipping Adder</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[
            {
              required: false,
              validator: inRangeValidator(
                SHIPPING_ADDER_PER_W.min,
                SHIPPING_ADDER_PER_W.max,
                true,
                `Please enter a number between ${SHIPPING_ADDER_PER_W.min} and ${SHIPPING_ADDER_PER_W.max}, ex. ${SHIPPING_ADDER_PER_W.example}`,
                false
              ).validator,
            },
          ]}
          extra={
            selectedModuleQuarter &&
            projectShippingCost &&
            selectedModuleData &&
            `Module ${projectShippingCost} Shipping Adder: ${getModuleShippingAdderByShippingCost(
              projectShippingCost,
              selectedModuleData
            )}`
          }
        >
          <InputNumber
            name="shipping_adder_per_W"
            data-testid="shipping_adder_per_W"
            precision={3}
            suffix="$/W"
          />
        </Form.Item>
        <Form.Item
          name="availability_MW"
          label={<FormItemLabel>Availability</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[
            inRangeValidator(
              MAX_AVAILABILITY_MW.min,
              MAX_AVAILABILITY_MW.max,
              true,
              `Enter a value between ${
                MAX_AVAILABILITY_MW.min
              } and ${integerFormatter(MAX_AVAILABILITY_MW.max)} MW DC.`,
              false
            ),
          ]}
          extra={
            selectedModuleQuarter &&
            `Module Availability: ${
              R.isNil(selectedModuleQuarter.availability_mw)
                ? '—'
                : selectedModuleQuarter.availability_mw
            }`
          }
        >
          <InputNumber
            name="availability_MW"
            formatter={DecimalSettingsFloor.formatter as Formatter}
            parser={DecimalSettingsFloor.parser}
            precision={3}
            step={0.1}
            suffix="MW DC"
          />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
};

export default ProjectSpecificPricingModal;
