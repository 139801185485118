import React, { useState } from 'react';
import { Popover, Tag } from 'antd';
import { FaArrowUp } from 'react-icons/fa6';
import styled from '@emotion/styled';
import { Radio } from '~/UI';
import { RadioGroup } from '~/UI/Radio';
import { theme } from '~/utils';

const StyledRadioContainer = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
`;
const StyledRadio = styled(Radio)`
  margin: 0.5em 0;
`;
const StyledTag = styled(Tag)`
  border-radius: 15px;
  background-color: ${theme.colors.grey};
  cursor: pointer;
  color: ${theme.fonts.colors.primary};
  font-size: 0.9em;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border: 1px solid ${theme.colors.primary300};
  svg {
    transition: transform 0.3s ease-in-out;
  }
  &:hover {
    background-color: ${theme.colors.primary}29;
    svg {
      transform: rotate(180deg);
    }
  }
`;

const StyledArrow = styled(FaArrowUp)`
  margin-left: 0.6em;
`;

const sortingOptions = [
  {
    value: 1,
    name: 'Effective $/W',
  },
  {
    value: 2,
    name: 'Purchase Price',
  },
  {
    value: 3,
    name: 'BOS Savings',
  },
  {
    value: 4,
    name: 'Production Benefit',
  },
  {
    value: 5,
    name: 'BOS Savings + Production Benefit',
  },
];

export const SortingMenu = () => {
  const [selected, setSelected] = useState(sortingOptions[0]);
  const [open, setOpen] = useState(false);
  return (
    <Popover
      content={
        <StyledRadioContainer
          onChange={(e) => {
            setSelected(
              sortingOptions.find((val) => val.value === e.target.value) ??
                sortingOptions[1]
            );
          }}
          value={selected.value}
        >
          {sortingOptions.map((option) => (
            <StyledRadio
              value={option.value}
              key={`sorting-option-${option.value}`}
              onClick={() => setOpen(false)}
            >
              {option.name}
            </StyledRadio>
          ))}
        </StyledRadioContainer>
      }
      trigger="click"
      placement="bottomRight"
      open={open}
      onOpenChange={(e) => {
        setOpen(e);
      }}
    >
      <StyledTag onClick={() => setOpen(true)}>
        Sort by: {selected.name} <StyledArrow size={11} />
      </StyledTag>
    </Popover>
  );
};
