import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useFlags } from '~/utils/launchdarkly';

import {
  ButtonAnt as Button,
  ButtonAnt,
  Form,
  ProjectDetailsHeader,
  notification,
} from '~/UI';
import {
  useUpdateProjectStatusMutation,
  useGetSolarProjectV3Query,
  useUpsertFilterMutation,
  useGetSolarProjectQuery as useGetSolarProjectV2Query,
  useUpdateSolarProjectMutation,
} from '~/store/api';
import { SpinnerMask } from '~/UI/Spinner';
import {
  GTM_EVENTS,
  GTM_SOURCE_IDS,
  gtmSetCurrentProjectState,
} from '~/utils/gtm';
import {
  ProjectStatuses,
  isUserAdmin,
  omitProjectDetailsValues,
  pickProjectDetailsValues,
  theme,
} from '~/utils';
import { getSolarProjectDetailsItems } from '~/constants/projects';
import { EngineResult, TaxCredit, UpdateProjectArgs } from '~/store/project';
import { IconPencil } from '~/UI/Icons';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TEST_DATA_PAGES,
  TestIdProps,
} from '~/utils/dataTestProps';
import useAuth from '~/hooks/useAuth';
import useIntro from '~/hooks/intro/useIntro';
import IntroWrapper from '~/components/IntroWrapper';
import { introSolarProposal } from '~/constants/intro';
import { LoadingCardState } from '~/components/LoadingCard';
import useHandleProjectError from '~/hooks/useHandleProjectError';
import useSolarPermissions from '~/hooks/Permissions/useSolarPermissions';
import {
  ClientFilters,
  Filter,
  generateHiddenModuleFilter,
  getHiddenModuleFilters,
  transformClientFiltersToFilterSets,
} from '~/utils/filters';

import SolarDetails, {
  getCurrentValuesFromParam,
} from '../components/Projects/solar/SolarDetails';
import SolarProjectDrawer from '../components/Projects/solar/SolarProjectDrawer';
import SolarActions from '../components/Projects/solar/SolarActions';
import SolarProjectEditForm from '../components/Projects/solar/SolarProjectEditForm';
import { transformAdvancedInputs } from '../components/Projects/shared/helpers';
import { AdvancedViewSwitchbox } from '../components/Projects/solar/AdvancedViewSwitchbox';

const dataTestIdConfig: TestIdProps = {
  page: TEST_DATA_PAGES.CUSTOMER.SOLAR_PROJECT_DETAILS,
  component: TEST_DATA_COMPONENTS.BUTTON,
};

const introConstants = {
  introOptions: {
    exitOnOverlayClick: false,
  },
  hookParams: {
    introKey: 'intro-solar-proposal',
  },
} as const;

export type ProjectSolarDetailsProps = {
  setBanner: (banner: React.ReactNode) => void;
};

const ProjectSolarDetails = ({ setBanner }: ProjectSolarDetailsProps) => {
  const [isEditingProject, setIsEditingProject] = useState<boolean>(false);
  const { handleProjectError } = useHandleProjectError();
  const { user } = useAuth();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [viewHiddenModules, setViewHiddenModules] = useState(false);
  const [engineState, setEngineState] = useState<EngineResult>();
  const [currentLoadingCardState, setCurrentLoadingCardState] =
    useState<LoadingCardState>('pending');
  const [currentModuleFilterSetId, setCurrentModuleFilterSetId] =
    useState<string>();
  const { uuid } = useParams<{ uuid: string }>();
  const flags = useFlags();
  const [updateSolarProject] = useUpdateSolarProjectMutation();
  const [projectDetailsForm] = Form.useForm();
  const history = useHistory();
  const [isAnalysisViewOpen, setIsAnalysisViewOpen] = useState(false);

  const useGetSolarProject = flags.solarPricingPolicy
    ? useGetSolarProjectV3Query
    : useGetSolarProjectV2Query;
  const {
    data,
    isLoading,
    isFetching: isFetchingProject,
    error: errorGetProject,
  } = useGetSolarProject(
    { id: uuid },
    {
      // Added this ts-ignore because the type of pollingInterval is not correct.
      // Even when the return type is inside the type definition it is not being recognized
      pollingInterval:
        engineState === 'complete' || engineState === 'error'
          ? undefined
          : 1000,
    }
  );
  const [hiddenModulesFilters, setHiddenModulesFilters] = useState<Filter[]>(
    []
  );

  useEffect(() => {
    const filterSet = data?.project.module_filter_sets[0];
    if (!filterSet) return setCurrentModuleFilterSetId(undefined);
    setHiddenModulesFilters(getHiddenModuleFilters(filterSet.module_filters));
    return setCurrentModuleFilterSetId(filterSet.uuid);
  }, [data?.project.module_filter_sets]);

  const [upsertProjectFilter] = useUpsertFilterMutation();

  useEffect(() => {
    handleProjectError(errorGetProject);
  }, [errorGetProject]);

  const [offsetMenu, setOffsetMenu] = useState(0);
  const [updateProjectStatus, { isLoading: isUpdatingProjectStatus }] =
    useUpdateProjectStatusMutation();

  const { startFirstTimeIntro } = useIntro(introConstants.hookParams);

  useEffect(() => {
    const engine = data?.engine;
    if (engine) setEngineState(engine);
  }, [data?.engine]);

  useEffect(() => {
    const isProjectInProposal =
      data?.project?.project_details?.status === 'proposal';
    const isEngineStateComplete = data?.engine === 'complete';
    if (isProjectInProposal && isEngineStateComplete && !isFetchingProject)
      startFirstTimeIntro(introConstants.introOptions);
  }, [data?.project?.project_details?.status, data?.engine, isFetchingProject]);

  const handleOnOpenDrawer = () => setIsDrawerOpen(true);
  const handleOnCloseDrawer = (isUpdate?: boolean) => {
    if (isUpdate) setEngineState('active');
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    if (data) {
      // this is incase engine status is not updated we want to prevent stop polling
      if (data.engine) {
        setEngineState(data.engine);
      }

      const { project } = data;
      const { name } = project;
      const {
        uuid,
        project_type,
        size_in_kw,
        status,
        anticipated_purchase_date,
        arrival_date,
        locationModifier,
      } = project.project_details;
      const { city, state } = locationModifier;
      gtmSetCurrentProjectState(
        GTM_EVENTS.STAGE_CHANGED,
        GTM_SOURCE_IDS.ROOTS.SOLAR_PROJECT,
        {
          uuid,
          name,
          projectType: project_type,
          location: `${city}, ${state}`,
          size_in_kw,
          status,
          arrival_date: `${arrival_date}`,
          anticipated_purchase_date: `${anticipated_purchase_date}`,
        }
      );
    }
  }, [data]);

  if (isLoading) return <SpinnerMask />;
  // Here we should handle the case when the project is not found
  if (!data) return null;
  const { enableAdvanceInputs, showP360Content } = useSolarPermissions(
    data.permissions
  );

  const projectStatus = data.project.project_details.status;
  const isCreated =
    projectStatus === ProjectStatuses.CREATED.toLocaleLowerCase();
  const isProposal =
    projectStatus === ProjectStatuses.PROPOSAL.toLocaleLowerCase();
  const isLost = projectStatus === ProjectStatuses.LOST.toLocaleLowerCase();
  const isInactive =
    projectStatus === ProjectStatuses.INACTIVE.toLocaleLowerCase();

  const isEngineRunning = engineState === 'active' || !engineState;
  const isFetchingData = isFetchingProject || isUpdatingProjectStatus;
  const disableAllButtons =
    isFetchingData || isEngineRunning || currentLoadingCardState === 'loading';

  const disableEditProjectButton =
    disableAllButtons || !(isCreated || isProposal);

  const projectHeaderOffset = isUserAdmin(user)
    ? theme.elements.header.heightNumber +
      theme.elements.secondaryHeader.heightNumber
    : theme.elements.header.heightNumber;

  const handleSaveFilter = () => {
    const parsedUrlParams = getCurrentValuesFromParam(history);
    const module_filters = transformClientFiltersToFilterSets({
      initialFiltersValues:
        parsedUrlParams.initialFiltersValues as ClientFilters['initialFiltersValues'],
      tableFilters:
        parsedUrlParams?.tableFilters as ClientFilters['tableFilters'],
    });

    upsertProjectFilter({
      projectId: data.project.uuid,
      filters: [...hiddenModulesFilters, ...module_filters],
      selected_columns: parsedUrlParams?.initialP360FilterValues as Record<
        string,
        unknown[]
      >,
      // right now we are only supporting one module filter set and this could be empty
      module_filter_set_uuid:
        data.project.module_filter_sets?.[0]?.uuid || currentModuleFilterSetId,
    })
      .unwrap()
      .then((response) => {
        setCurrentModuleFilterSetId(response.module_filter_set_uuid);
        notification.success({
          duration: 3,
          message: 'Your project was saved.',
        });
      })
      .catch(() => {
        notification.error({
          message:
            'There was an error while saving your project. Please try again.',
        });
      });
  };

  const handleSaveHiddenFilter = (uuid: string, action: 'add' | 'remove') => {
    const hiddenModuleFilter = generateHiddenModuleFilter(uuid);
    setHiddenModulesFilters((prev) => {
      let moduleHiddenFilters = [];
      if (action === 'add') {
        moduleHiddenFilters = [...prev, hiddenModuleFilter];
      } else {
        moduleHiddenFilters = prev.filter(
          (f) => f.target_string_value !== uuid
        );
      }
      return moduleHiddenFilters;
    });
  };

  const handleSaveProject = () => {
    /// build the array for tax credits
    projectDetailsForm.submit();
    projectDetailsForm
      .validateFields()
      .then((values) => {
        const { is_larger_purchase, energy_terms, ...payload } =
          transformAdvancedInputs(values, data.project.advancedInputs);
        const tax_credits = [
          ...(energy_terms?.map((energyTerm: TaxCredit) => ({
            ...energyTerm,
            updated_by: user.id,
          })) || []),
          ...payload.tax_credits.map((taxCredit: TaxCredit) => ({
            ...taxCredit,
            updated_by: user.id,
          })),
        ];
        updateSolarProject({
          uuid: data.project.uuid,
          project_details: {
            ...pickProjectDetailsValues(payload),
            current_purchase_volume_kw: is_larger_purchase
              ? payload.current_purchase_volume_kw
              : null,
            project_type: data.project.project_details.project_type,
            project_address: data.project.project_details.project_address,
            status: data.project.project_details.status,
            tax_credits,
            is_itc: payload.is_itc === 'ITC',
          },
          ...omitProjectDetailsValues(payload),
        } as UpdateProjectArgs)
          .unwrap()
          .then(() => {
            setIsEditingProject(false);
          });
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <>
      <ProjectDetailsHeader
        setBanner={setBanner}
        showToggle={false}
        user={user}
        dataTestIdConfig={dataTestIdConfig}
        project={data.project}
        heroModuleItems={getSolarProjectDetailsItems(data.project)}
        offsetTop={projectHeaderOffset}
        setOffsetMenu={setOffsetMenu}
        lastUpdated={data.lastUpdated}
        hideBottomShadow
      >
        {!(flags.taxCredit && isEditingProject) && (
          <AdvancedViewSwitchbox
            engine={data.engine}
            loadingCardState={currentLoadingCardState}
            isAnalysisViewOpen={isAnalysisViewOpen}
            toggle={() => setIsAnalysisViewOpen((prev) => !prev)}
          />
        )}
        {flags.persistentFilters && !isEditingProject && (
          <SolarActions
            available_analytics_data={data.available_analytics_data}
            disabled={disableAllButtons}
            onEditProject={() => setIsEditingProject(true)}
            handleOnOpenDrawerCb={
              !flags.taxCredit ? handleOnOpenDrawer : undefined
            }
            handleSaveFilterCb={
              flags.persistentFilters ? handleSaveFilter : () => {}
            }
            isEditingProject={flags.taxCredit ? isEditingProject : undefined}
            isAnalysisViewOpen={isAnalysisViewOpen}
            onCancelClick={() => setIsEditingProject(false)}
          />
        )}
        {flags.taxCredit && isEditingProject && (
          <>
            <ButtonAnt
              onClick={() => {
                setIsEditingProject(false);
                projectDetailsForm.resetFields();
              }}
              size="middle"
            >
              Cancel
            </ButtonAnt>
            <Button type="primary" onClick={handleSaveProject} size="middle">
              Save
            </Button>
          </>
        )}
        {!flags.persistentFilters && (
          <IntroWrapper {...introSolarProposal.editProjectDetails}>
            <Button
              square
              disabled={disableEditProjectButton}
              onClick={handleOnOpenDrawer}
              data-testid={generateTestId({
                ...dataTestIdConfig,
                identifier: 'edit-project',
              })}
            >
              <IconPencil size={24} />
            </Button>
          </IntroWrapper>
        )}
      </ProjectDetailsHeader>
      <SolarDetails
        enableAdvanceInputs={enableAdvanceInputs}
        projectDetailsForm={projectDetailsForm}
        isEditingProject={isEditingProject}
        available_analytics_data={data.available_analytics_data}
        onLoadingCardStateChange={(state) => {
          setCurrentLoadingCardState(state);
        }}
        loadingCardState={currentLoadingCardState}
        offsetMenu={offsetMenu}
        project={data.project}
        engine={data.engine}
        rankingData={data.rankingData}
        onEditProjectClick={handleOnOpenDrawer}
        disableEditProjectButton={disableEditProjectButton}
        showP360Content={showP360Content}
        onHideModule={(uuid) => handleSaveHiddenFilter(uuid, 'add')}
        onUnhideModule={(uuid) => handleSaveHiddenFilter(uuid, 'remove')}
        hiddenModulesFilters={hiddenModulesFilters}
        viewHiddenModules={viewHiddenModules}
        isAnalysisViewOpen={isAnalysisViewOpen}
        toggleHiddenModules={() => setViewHiddenModules((prev) => !prev)}
      />
      {!flags.taxCredit && (
        <SolarProjectDrawer
          onCloseDrawer={handleOnCloseDrawer}
          isOpen={isDrawerOpen}
          projectData={data.project}
          advancedInputs={data.project.advancedInputs}
          enableAdvanceInputs={enableAdvanceInputs}
        />
      )}
    </>
  );
};

export default ProjectSolarDetails;
