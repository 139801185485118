import React, { useEffect, useRef } from 'react';
import { isEmpty, sortBy } from 'ramda';

import { Link } from 'react-router-dom';
import {
  EngineResult,
  ProjectStatus,
  SolarProject,
  SolarRankingData,
} from '~/store/project';
import { ButtonAnt as Button } from '~/UI';
import { ROUTER_PATHS } from '~/router/Paths';
import { MAILTO, ProjectStatuses, isUserAdmin, projectIsClosed } from '~/utils';
import { useFlags } from '~/utils/launchdarkly';
import useAuth from '~/hooks/useAuth';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TEST_DATA_PAGES,
  TestIdProps,
} from '~/utils/dataTestProps';
import StateCard from '~/components/StateCard';
import { LoadingCardState } from '~/components/LoadingCard';

import { ANZA_ENGINE_STATES } from '~/constants/engine';
import ModuleCard from './ModuleCard';
import { RankingContainer, ModuleList } from './styles';

const dataTestIdConfig: TestIdProps = {
  page: TEST_DATA_PAGES.CUSTOMER.SOLAR_PROJECT_DETAILS,
  component: TEST_DATA_COMPONENTS.TABLE,
};

export type RankingProps = {
  project: SolarProject;
  engine?: EngineResult;
  rankingData: SolarRankingData[];
  loading?: boolean;
  onEditProjectClick?: () => void;
  className?: string;
  disableEditButton: boolean;
  onHideModule?: (uuid: string) => void;
  onUnhideModule?: (uuid: string) => void;
  resetFilters: () => void;
  currentQuarter: string;
};

export const getShowLoadingCard = (
  loadingCardState: LoadingCardState,
  engine?: EngineResult
) => {
  const isEngineActive = engine === ANZA_ENGINE_STATES.ACTIVE || !engine;
  if (!isEngineActive && loadingCardState !== 'loading') return false;
  return true;
};

const Ranking = ({
  project,
  rankingData,
  loading,
  className,
  engine,
  disableEditButton,
  onHideModule,
  onUnhideModule,
  resetFilters,
  onEditProjectClick,
  currentQuarter,
}: RankingProps) => {
  const { user } = useAuth();
  const flags = useFlags();
  const listRef = useRef<HTMLDivElement>(null);

  // TODO: This will be used in the next release
  // const isSolarEngaged = user.company?.engaged_solar;
  useEffect(() => {}, [engine]);
  const sortedRankingDataByEffectivePrice = sortBy(
    (module) => module.module_effective_price_USD_per_W,
    rankingData
  );

  const projectStatus =
    project.project_details.status.toLocaleLowerCase() as ProjectStatus;
  const projectIsInactive =
    projectIsClosed(projectStatus) && !isUserAdmin(user);

  // TODO: This will be used in the next release
  // const shouldIncludeModuleLink = !!(
  //   isUserAdmin(user) ||
  //   (projectStatus !== ProjectStatuses.SUBMITTED.toLocaleLowerCase() &&
  //     projectStatus !== ProjectStatuses.CREATED.toLocaleLowerCase() &&
  //     isSolarEngaged)
  // );

  const shouldIncludeModuleLink = !!(
    isUserAdmin(user) ||
    projectStatus !== ProjectStatuses.CREATED.toLocaleLowerCase()
  );

  const showEmptyStateCard =
    !loading &&
    (isEmpty(rankingData) || engine === ANZA_ENGINE_STATES.error) &&
    !projectIsInactive;

  return (
    <RankingContainer margin={12} className={className}>
      {showEmptyStateCard ? (
        <StateCard
          type="empty"
          title="There are no modules available that match your search."
        >
          <Button
            disabled={disableEditButton}
            data-testid={generateTestId({
              ...dataTestIdConfig,
              component: TEST_DATA_COMPONENTS.BUTTON,
              identifier: 'edit-project',
            })}
            onClick={() => {
              if (flags.persistentFilters) {
                resetFilters?.();
              } else {
                onEditProjectClick?.();
              }
            }}
            size="large"
            style={{ marginRight: '1rem' }}
          >
            {flags.persistentFilters ? 'Reset All Filters' : 'Edit Project'}
          </Button>
        </StateCard>
      ) : null}

      {projectIsInactive && (
        <StateCard
          type="inactive"
          title="This project is no longer active."
          subtitle="We apologize for the inconvenience. If you believe this to be an error, please contact your account manager."
        >
          <Button type="default">
            <a
              href={`mailto:${MAILTO}?subject=Anza - Help`}
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
          </Button>
        </StateCard>
      )}

      {!loading && !isEmpty(rankingData) && !projectIsInactive ? (
        <ModuleList
          data-testid={generateTestId({
            ...dataTestIdConfig,
            identifier: 'modules',
          })}
          tabIndex={-1}
          ref={listRef}
        >
          {sortedRankingDataByEffectivePrice?.map((module, i) => {
            const card = (
              <ModuleCard
                onHideModule={onHideModule}
                onUnhideModule={onUnhideModule}
                shouldIncludeModuleLink={shouldIncludeModuleLink}
                tabIndex={i}
                key={module.moduleId}
                moduleData={module}
                project={project}
                index={i + 1}
                currentQuarter={currentQuarter}
              />
            );
            return shouldIncludeModuleLink && !flags.persistentFilters ? (
              <Link
                key={module.moduleId}
                to={{
                  pathname: ROUTER_PATHS.modules.MODULE_DETAILS(
                    module.moduleId
                  ),
                  state: { fromProject: project.uuid },
                }}
              >
                {card}
              </Link>
            ) : (
              card
            );
          })}
        </ModuleList>
      ) : null}
    </RankingContainer>
  );
};

export default Ranking;
