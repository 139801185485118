import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  createContext,
  useMemo,
} from 'react';
import { useHistory } from 'react-router';
import * as R from 'ramda';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import qs from 'qs';
import { TablePaginationConfig } from 'antd';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';
import styled from '@emotion/styled';

import {
  AnalyticsSchema,
  ProjectStatus,
  SolarProjectTransformedResultType,
  SolarRankingData,
} from '~/store/project';
import {
  IconAdd,
  IconChevronLeft,
  IconChevronRight,
  IconColumns,
} from '~/UI/Icons';
import { Affix, ButtonAnt, FormInstance, Skeleton, Space } from '~/UI';
import { GTM_SOURCE_TYPES, GTM_SOURCE_IDS } from '~/utils/gtm';
import {
  getCompressedB64String,
  getSolarPDPFilterValuesFromQs,
  getStringFromCompressedB64String,
  isUserAdmin,
  NODE_ENVS,
  projectIsClosed,
} from '~/utils';
import { generateTestId } from '~/utils/dataTestProps';
import IntroWrapper from '~/components/IntroWrapper';
import { introSolarProposal } from '~/constants/intro';
import LoadingCard, { LoadingCardState } from '~/components/LoadingCard';
import { getClosestQuarterInOrderedArray } from '~/utils/dates';
import useAuth from '~/hooks/useAuth';
import { ANZA_ENGINE_STATES } from '~/constants/engine';
import FilterMenu from '~/UI/Filters/FilterMenu';
import useTableColumns, { ColumnWithMenuFilter } from '~/hooks/useTableColumns';
import {
  generateSchemaColumns,
  generateSchemaFilters,
} from '~/utils/analyticsData';
import DisplayWrapper from '~/components/DisplayWrapper';
import type { FilterValues } from '~/UI/Filters/FilterForm';
import { useFlags } from '~/utils/launchdarkly';
import {
  Filter,
  applyIsModuleHiddenToModules,
  filterDataByAnalytics,
  filterHiddenModules,
  filterModule,
  transformFilterSetsToClientFilters,
} from '~/utils/filters';
import SelectableFilterMenu, {
  SelectableFilterConfig,
} from '~/UI/Filters/SelectableFilterMenu';
import SelectFilterMenu from '~/UI/Filters_WIP/SelectFilterMenu';
import FilterMenu_WIP from '~/UI/Filters_WIP/FilterMenu';

import {
  ModuleFilters,
  ModuleFiltersKeys,
  cellTechOptions,
  domesticContentOrAssemblyOptions,
  tier1Options,
  wattageRangeOptions,
  dataTestIdConfig,
  getManufacturerOptions,
} from './RankingFilter';
import {
  ArrowButton,
  Quarter,
  QuarterDate,
  RankingMenu,
  StyledModalText,
} from './styles';
import SolarAdvancedView from './SolarAdvancedView';
import SolarModulesView from './SolarModulesView';
import { getShowLoadingCard } from '../ranking/Ranking';
import { HiddenModulesButton } from './HiddenModulesButton';
import { getFilteredSchemas } from './utils';
import SolarProjectEditForm from './SolarProjectEditForm';
import { SortingMenu } from './SortingMenu';
import { getConfiguration } from '~/utils/configurations';

dayjs.extend(quarterOfYear);

export type SelectedFilter = {
  label: string | number | boolean | JSX.Element;
  title: string;
  filter: ModuleFiltersKeys;
  value: string | number | boolean;
};

export type TableSorterFiltersTypes = {
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue | null>;
  sorter:
    | SorterResult<any>
    | SorterResult<any>[]
    | SorterResult<SolarRankingData>
    | SorterResult<SolarRankingData>[];
  extra: TableCurrentDataSource<any>;
};
/**
 * Transforms the selected columns values to group them correctly based on the current p360 schemas
 * @param values selected columns values
 * @param available_analytics_data schemas
 * @returns selected columns values grouped
 */
const correctSelectedColumnsUiGroup = (
  values: FilterValues,
  available_analytics_data: Record<string, AnalyticsSchema>
) => {
  const selectedColumnKeys = R.reduce(
    (acc, elem) => {
      const current = elem || [];
      return [...acc, ...current];
    },
    [] as string[],
    R.values(values)
  );

  return R.reduce(
    (acc, schemaKey) => {
      const group = available_analytics_data[schemaKey]?.ui_group;
      // If there is no UI group or no schema we remove the filter form the selected options because it will not be visible
      if (!group) return acc;
      const currentValue = acc[group] || [];
      return {
        ...acc,
        [group]: [...currentValue, schemaKey],
      };
    },
    {} as FilterValues,
    selectedColumnKeys
  );
};

export interface HistoryType {
  location: {
    search: string;
  };
}
export const getCurrentValuesFromParam = (history: HistoryType) => {
  const params = qs.parse(history.location.search.slice(1));
  const decoded = decodeURIComponent(params?.filters?.toString() || '');
  const paramsString = getStringFromCompressedB64String(decoded);
  return qs.parse(paramsString);
};

export const AnalysisViewContext = createContext(false);

type FilterConfig = {
  moduleFilters?: FilterValues;
  analyticsFilters?: FilterValues;
};

const filterModules = (
  rankingData: SolarRankingData[],
  { analyticsFilters, moduleFilters }: FilterConfig
) => {
  const filteredByModuleFilters = moduleFilters
    ? rankingData.filter((module) => filterModule(module, moduleFilters))
    : rankingData;

  const filteredByAnalyticsFilters = analyticsFilters
    ? filteredByModuleFilters?.filter((module) =>
        filterDataByAnalytics(
          module,
          (analyticsFilters || {}) as Record<string, any[]>,
          {
            pathToAnalyticsData: ['analytics_data'],
          }
        )
      )
    : filteredByModuleFilters;
  return filteredByAnalyticsFilters;
};

export type QsFilters = {
  initialFiltersValues?: ModuleFilters;
  tableFilters?: {
    filters?: FilterValues;
  };
  initialP360FilterValues?: FilterValues;
  isAnalysisViewOpen?: boolean;
};

export type OnChangeFilters = (
  filters: {
    tableFilters: {
      filters: FilterValues;
    };
    initialFiltersValues: FilterValues;
  },
  selectedColumns: FilterValues,
  hiddenModuleFilter: Filter[]
) => void;

const StyledButton = styled(ButtonAnt)`
  font-weight: bold;
  text-decoration: underline;
`;

type SolarDetailsProps = Omit<
  SolarProjectTransformedResultType,
  'advancedInputs' | 'modules' | 'permissions'
> & {
  onEditProjectClick?: () => void;
  offsetMenu: number;
  onFinishedLoadingCard?: () => any;
  loadingCardState: LoadingCardState;
  onLoadingCardStateChange: (state: LoadingCardState) => void;
  disableEditProjectButton: boolean;
  showP360Content: boolean;
  onHideModule?: (uuid: string) => void;
  onUnhideModule?: (uuid: string) => void;
  hiddenModulesFilters: Filter[];
  viewHiddenModules: boolean;
  onChangeFilters?: OnChangeFilters;
  isAnalysisViewOpen: boolean;
  toggleHiddenModules: () => void;
  isEditingProject: boolean;
  projectDetailsForm: FormInstance;
  enableAdvanceInputs: boolean;
};

const DEFAULT_SELECTED_COLUMNS_FILTER_VALUES = {
  'Supply Chain': [
    'uflpa_risk',
    'china_adcvd_risk',
    'non_chinese_poly',
    'traceability_audit_qc',
  ],
  Counterparty: ['counterparty_retrade_risk', 'spa_down_deposit_qc'],
};

const SolarDetails = ({
  project,
  engine,
  rankingData: rankingDataByQuarter,
  onEditProjectClick,
  offsetMenu,
  loadingCardState,
  onLoadingCardStateChange,
  disableEditProjectButton,
  available_analytics_data,
  showP360Content,
  onHideModule,
  onUnhideModule,
  hiddenModulesFilters,
  viewHiddenModules,
  isAnalysisViewOpen,
  toggleHiddenModules,
  isEditingProject,
  projectDetailsForm,
  enableAdvanceInputs,
}: SolarDetailsProps) => {
  const { user } = useAuth();
  const flags = useFlags();

  const isLoading =
    engine === 'active' || !engine || loadingCardState === 'loading';
  const projectStatus =
    project.project_details.status.toLocaleLowerCase() as ProjectStatus;
  const isInactive = projectIsClosed(projectStatus) && !isUserAdmin(user);
  const isEngineInErrorState = engine === ANZA_ENGINE_STATES.error;
  const initialFiltersValuesFromDb = project?.module_filter_sets?.[0]
    ? transformFilterSetsToClientFilters(project?.module_filter_sets?.[0])
    : null;
  const initialSelectedColumnsFromDb =
    project?.module_filter_sets?.[0]?.selected_columns;

  const allQuartersList: string[] = R.pipe(
    R.keys,
    R.sort((a, b) => a.localeCompare(b))
  )(rankingDataByQuarter);

  // eslint-disable-next-line no-param-reassign
  const closestQuarterToProjectDeliveryDate: string =
    getClosestQuarterInOrderedArray(
      project.project_details.arrival_date,
      allQuartersList
    );

  const getQuartersForCustomer = (
    quarters: string[],
    targetQuarter: string
  ): string[] => {
    const targetIndex = quarters.indexOf(targetQuarter);
    if (targetIndex === -1) {
      return [];
    }

    const result: string[] = [];

    if (targetIndex - 1 >= 0) {
      result.push(quarters[targetIndex - 1]);
    }

    result.push(quarters[targetIndex]);

    if (targetIndex + 1 < quarters.length) {
      result.push(quarters[targetIndex + 1]);
    }

    return [...result];
  };

  const quartersList = isUserAdmin(user)
    ? [...allQuartersList]
    : getQuartersForCustomer(
        allQuartersList,
        closestQuarterToProjectDeliveryDate
      );

  const history = useHistory();
  const parsedUrlParams = getCurrentValuesFromParam(history) as QsFilters;
  const initialTableFilters = flags.persistentFilters
    ? (initialFiltersValuesFromDb?.tableFilters?.filters as FilterValues)
    : parsedUrlParams?.tableFilters?.filters;

  const filteredSchemas = getFilteredSchemas(available_analytics_data);

  // TODO: we should clean this hardcoded schema filtering at some point
  const filteredSchemasForFilterDropdown = R.omit(
    ['cell_technology', 'tier_1'],
    filteredSchemas
  );

  const [selectedQuarter, setSelectedQuarter] = useState(
    closestQuarterToProjectDeliveryDate
  );
  const rankingData =
    isLoading || isEngineInErrorState
      ? []
      : rankingDataByQuarter![selectedQuarter];

  const p360SchemaColumns = generateSchemaColumns(
    filteredSchemas,
    rankingData,
    {
      pathToSchemaValues: ['analytics_data'],
      activeColumns: [
        {
          key: 'uflpa_risk',
          active: 'selected',
        },
        {
          key: 'china_adcvd_risk',
          active: 'selected',
        },
        {
          key: 'counterparty_retrade_risk',
          active: 'selected',
        },
        {
          key: 'non_chinese_poly',
          active: 'selected',
        },
        {
          key: 'spa_down_deposit_qc',
          active: 'selected',
        },
        {
          key: 'traceability_audit_qc',
          active: 'selected',
        },
      ],
    }
  );

  const isDemo2 =
    getConfiguration('REACT_APP_DEPLOYMENT_ENV') === NODE_ENVS.DEMO2;

  const p360InitialColumns: ColumnWithMenuFilter<SolarRankingData>[] = [
    {
      title: '',
      key: 'rank',
      dataIndex: 'rank',
      active: 'always',
    },
    {
      title: 'Vendor',
      key: 'manufacturer',
      dataIndex: 'manufacturer',
      active: 'always',
      render: (value) => (isDemo2 ? <Skeleton paragraph={false} /> : value),
    },
    {
      title: 'Name',
      key: 'moduleName',
      dataIndex: ['moduleName'],
      active: 'always',
      render: (value) => (isDemo2 ? <Skeleton paragraph={false} /> : value),
    },
  ];

  const {
    initialValues: defaultInitialP360FilterValues,
    filters: p360Filters,
    updateColumns: updateP360Columns,
    clearAllOptionalColumns: clearAllP360OptionalColumns,
    clearColumnGroup: clearP360ColumnGroup,
    tableProps,
    setTableFilters,
  } = useTableColumns([...p360InitialColumns, ...p360SchemaColumns]);

  const initialModuleFilters = getSolarPDPFilterValuesFromQs(
    parsedUrlParams.initialFiltersValues,
    flags.persistentFilters
      ? initialFiltersValuesFromDb?.initialFiltersValues
      : {}
  );

  const [filterValues, setFilterValues] = useState<FilterValues>({
    ...initialModuleFilters,
    ...initialTableFilters,
  });

  const getInitialSelectedColumns = () => {
    let initial: FilterValues = DEFAULT_SELECTED_COLUMNS_FILTER_VALUES;

    if (flags.persistentFilters && initialSelectedColumnsFromDb) {
      initial = initialSelectedColumnsFromDb;
    }
    if (parsedUrlParams.initialP360FilterValues) {
      initial = parsedUrlParams.initialP360FilterValues;
    }
    return available_analytics_data && !R.isEmpty(available_analytics_data)
      ? correctSelectedColumnsUiGroup(initial, available_analytics_data)
      : initial;
  };

  const [selectedColumns, setSelectedColumns] = useState<FilterValues>(
    getInitialSelectedColumns()
  );

  const manufacturers = R.uniq(
    rankingData.map((x) => x.manufacturer || '')
  ).sort();

  const DEFAULT_FILTERS: SelectableFilterConfig[] = [
    {
      name: ModuleFiltersKeys.tier1Modules,
      title: 'Tier-1',
      dropdownGroupTitle: 'Tier-1',
      options: tier1Options,
      filterHeaderDataTestIdConfig: dataTestIdConfig,
      group: 'General',
    },
    {
      name: ModuleFiltersKeys.cellTechnology,
      title: 'Cell Technology',
      dropdownGroupTitle: 'Cell Technology',
      options: cellTechOptions,
      filterHeaderDataTestIdConfig: dataTestIdConfig,
      group: 'General',
    },
    {
      name: ModuleFiltersKeys.domesticContentOrAssembly,
      title: 'Domestic Content / Assembly',
      dropdownGroupTitle: 'Domestic',
      options: domesticContentOrAssemblyOptions,
      filterHeaderDataTestIdConfig: dataTestIdConfig,
      group: 'General',
    },
    {
      name: ModuleFiltersKeys.wattClassKw,
      title: 'Watt Class',
      dropdownGroupTitle: 'Watt Class',
      options: wattageRangeOptions,
      filterHeaderDataTestIdConfig: dataTestIdConfig,
      hasHeaderClearButton: true,
      group: 'General',
    },
    {
      name: ModuleFiltersKeys.manufacturer,
      title: 'Manufacturer',
      dropdownGroupTitle: 'Manufacturer',
      options: getManufacturerOptions(manufacturers),
      filterHeaderDataTestIdConfig: dataTestIdConfig,
      hasHeaderClearButton: true,
      group: 'General',
    },
  ];
  const defaultFiltersNames = DEFAULT_FILTERS.map((config) => config.name);

  const getModuleFiltersFromState = (filterValues: FilterValues) =>
    R.pick(defaultFiltersNames, filterValues || {});

  const getAdvancedFiltersFromState = (filterValues: FilterValues) =>
    R.omit(defaultFiltersNames, filterValues || {});

  const updateHistory = () => {
    const allFilters = {
      initialP360FilterValues: selectedColumns,
      tableFilters: {
        filters: getAdvancedFiltersFromState(filterValues),
      },
      initialFiltersValues: getModuleFiltersFromState(filterValues),
    };
    const newUrlValue = allFilters;
    const b64String = getCompressedB64String(qs.stringify(newUrlValue));
    const encoded = encodeURIComponent(b64String);
    const search = b64String?.length ? `?filters=${encoded}` : null;
    history.replace({ pathname: history.location.pathname, search });
  };

  useEffect(() => {
    updateHistory();
  }, [selectedColumns, hiddenModulesFilters, filterValues]);

  useEffect(() => {
    updateP360Columns(selectedColumns);
  }, [project]);

  useEffect(() => {
    setSelectedQuarter(closestQuarterToProjectDeliveryDate);
  }, [closestQuarterToProjectDeliveryDate]);

  useEffect(() => {
    const quarterToProjectDeliveryDate: string =
      getClosestQuarterInOrderedArray(
        project.project_details.arrival_date,
        allQuartersList
      );
    setSelectedQuarter(quarterToProjectDeliveryDate);
  }, [engine]);

  const currentSelectedQuarter = Number(selectedQuarter.split('-')[1]);
  const currentSelectedYear = Number(selectedQuarter.split('-')[0]);

  const menuHeight = useRef(0);
  const menuRef = useCallback((menuNode: HTMLDivElement) => {
    if (!menuNode) return;
    menuHeight.current = menuNode.getBoundingClientRect().height;
  }, []);

  const goToPreviousQuarter = () => {
    const currentIndex = quartersList.findIndex(
      (q: string) => q === selectedQuarter
    );
    setSelectedQuarter(quartersList[currentIndex - 1]);
  };

  const isPrevArrowDisabled =
    isLoading || !quartersList.length || selectedQuarter === quartersList[0];
  const isNextArrowDisabled =
    isLoading ||
    !quartersList.length ||
    selectedQuarter === R.last(quartersList);
  const goToNextQuarter = () => {
    const currentIndex = quartersList.findIndex(
      (q: string) => q === selectedQuarter
    );
    setSelectedQuarter(quartersList[currentIndex + 1]);
  };

  const quarterYearDisplay = `Q${currentSelectedQuarter} ${currentSelectedYear}`;

  const renderQuarterSelector = (
    <Quarter>
      <ArrowButton
        type="text"
        disabled={isPrevArrowDisabled || isInactive}
        onClick={goToPreviousQuarter}
      >
        <IconChevronLeft />
      </ArrowButton>
      <StyledModalText
        data-testid={generateTestId({
          ...dataTestIdConfig,
          identifier: 'delivery-quarter',
        })}
      >
        Delivery Quarter
      </StyledModalText>
      <QuarterDate
        data-testid={generateTestId({
          ...dataTestIdConfig,
          identifier: 'delivery-quarter-value',
        })}
      >
        {quarterYearDisplay}
      </QuarterDate>
      <ArrowButton
        type="text"
        disabled={isNextArrowDisabled || isInactive}
        onClick={goToNextQuarter}
      >
        <IconChevronRight />
      </ArrowButton>
    </Quarter>
  );

  const disableDropdowns =
    !engine ||
    engine === 'active' ||
    !rankingData?.length ||
    loadingCardState === 'loading' ||
    isInactive;

  // We apply isModuleHidden to each module hidden by uuid and ONLY by uuid
  const rankingWithHiddenData = applyIsModuleHiddenToModules(
    rankingData,
    hiddenModulesFilters
  );

  const filteredRankingData = filterModules(rankingWithHiddenData, {
    moduleFilters: getModuleFiltersFromState(filterValues),
    analyticsFilters: getAdvancedFiltersFromState(filterValues),
  });

  const rankingDataToShow = viewHiddenModules
    ? filteredRankingData
    : filterHiddenModules(filteredRankingData);

  const hideRanking = isAnalysisViewOpen;
  const showAnalyticsView = hideRanking;

  const updateTableFiltersAndSorter = (
    tableFilters: TableSorterFiltersTypes
  ) => {
    setTableFilters(tableFilters.filters);
  };

  useEffect(() => {
    // Update columns to what's in query string, overriding hook default `useTableColumns`
    const { initialP360FilterValues, tableFilters } =
      getCurrentValuesFromParam(history);
    updateP360Columns(initialP360FilterValues as FilterValues);

    if (tableFilters || initialFiltersValuesFromDb?.tableFilters) {
      updateTableFiltersAndSorter(
        (tableFilters as unknown as TableSorterFiltersTypes) ||
          initialFiltersValuesFromDb?.tableFilters
      );
    }
  }, []);

  const onValuesChangeAnalytics = (values: FilterValues) => {
    setSelectedColumns(values);
    updateP360Columns(values);
  };

  const currentMode = () => {
    return showAnalyticsView
      ? GTM_SOURCE_IDS.SOLAR_PROJECT.MODES.ADVANCED_VIEW
      : GTM_SOURCE_IDS.SOLAR_PROJECT.MODES.SIZZLE;
  };

  const resetFilters = () => {
    setFilterValues({});
  };

  const hiddenModulesLength = useMemo(
    () =>
      filteredRankingData?.length -
      filterHiddenModules(filteredRankingData)?.length,
    [filteredRankingData]
  );

  if (flags.taxCredit && isEditingProject) {
    return (
      <SolarProjectEditForm
        form={projectDetailsForm}
        project={project}
        defaultAdvancedInputs={project.advancedInputs}
        enableAdvanceInputs={enableAdvanceInputs}
      />
    );
  }

  return (
    <>
      <Affix
        offsetTop={offsetMenu}
        data-gtm-type={GTM_SOURCE_TYPES.MODE}
        data-gtm-id={currentMode()}
      >
        <RankingMenu ref={menuRef}>
          <IntroWrapper {...introSolarProposal.deliveryDateTool}>
            {renderQuarterSelector}
          </IntroWrapper>
          <IntroWrapper
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '5px',
              maxWidth: 1000,
            }}
            {...introSolarProposal.filters}
          >
            {flags.solarFilters ? (
              <SelectFilterMenu
                values={filterValues}
                filters={[
                  ...DEFAULT_FILTERS,
                  ...generateSchemaFilters(
                    filteredSchemasForFilterDropdown,
                    rankingData,
                    {
                      pathToSchemaValue: ['analytics_data'],
                    }
                  ),
                ]}
                onChange={setFilterValues}
              />
            ) : (
              <SelectableFilterMenu
                values={filterValues}
                filters={[
                  ...DEFAULT_FILTERS,
                  ...generateSchemaFilters(
                    filteredSchemasForFilterDropdown,
                    rankingData,
                    {
                      pathToSchemaValue: ['analytics_data'],
                    }
                  ),
                ]}
                onChange={setFilterValues}
                selectFilterDropdownType="modal"
                openSelectedDropdownOnSelection
                selectFilterDropdownPosition="start"
                selectFilterDropdownCloseOnClearAll
                selectFilterDropdownIcon={<IconAdd />}
                disabled={disableDropdowns}
              />
            )}
          </IntroWrapper>

          <Space
            size="xs"
            direction="horizontal"
            style={{
              marginLeft: 'auto',
            }}
          >
            {!isLoading && (
              <DisplayWrapper hide={!showAnalyticsView}>
                <FilterMenu
                  initialValues={getInitialSelectedColumns()}
                  defaultValues={defaultInitialP360FilterValues}
                  filters={p360Filters}
                  hideGroupDropdowns
                  onValuesChange={onValuesChangeAnalytics}
                  onClearAllFilters={clearAllP360OptionalColumns}
                  onClearFilter={clearP360ColumnGroup}
                  limit={7}
                  gtmId={GTM_SOURCE_IDS.SOLAR_PROJECT.ADVANCED_SELECT_COLUMNS}
                  gtmType={GTM_SOURCE_TYPES.POPUP}
                  includeGtmProperties
                  mainDropdownLabel={<IconColumns size={18} />}
                  mainDropdownActionsLocation="header"
                  mainDropdownAutoMaxWidth
                  mainDropdownPlacement="bottomRight"
                  showMainDropdownResetButton
                  hideMainDropdownClearAllButton={false}
                  hideDropdownCount
                  noContainer
                  buttonComponent={
                    <StyledButton type="link">Customize</StyledButton>
                  }
                />
              </DisplayWrapper>
            )}
          </Space>
          {!hideRanking && flags.projectSpecificPricingV2 && <SortingMenu />}
          {hiddenModulesLength > 0 && (
            <HiddenModulesButton
              active={viewHiddenModules}
              toggleHiddenModules={toggleHiddenModules}
              hiddenModulesLength={hiddenModulesLength}
            />
          )}
        </RankingMenu>
      </Affix>
      {getShowLoadingCard(loadingCardState, engine) && (
        <LoadingCard
          onChangeState={onLoadingCardStateChange}
          textLines={[
            'Collecting module pricing and availability',
            'Creating a design and bill of materials',
            'Gathering location and weather data',
            'Calculating module yield and BOS costs',
            'Modeling cash flow, opEx, and tax incentives',
            'Preparing your engine results',
          ]}
        />
      )}
      {!isLoading && (
        <SolarAdvancedView
          available_analytics_data={available_analytics_data}
          dataGTMid={currentMode()}
          showP360Content={showP360Content}
          rankingData={rankingDataToShow}
          showAnalyticsView={showAnalyticsView}
          resetFilters={resetFilters}
          tableProps={tableProps}
        />
      )}
      {!hideRanking && (
        <SolarModulesView
          hide={hideRanking}
          dataGTMId={currentMode()}
          isOpen={isAnalysisViewOpen}
          project={project}
          onEditProjectClick={onEditProjectClick}
          isLoading={isLoading}
          disableEditProjectButton={disableEditProjectButton}
          rankingData={rankingDataToShow}
          onHideModule={onHideModule}
          onUnhideModule={onUnhideModule}
          resetFilters={resetFilters}
          currentQuarter={selectedQuarter}
        />
      )}
    </>
  );
};

export default SolarDetails;
