import React, { useState } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { useWatch } from 'antd/es/form/Form';
import {
  AddressSearch,
  Divider,
  Form,
  FormInstance,
  InputAnt as Input,
  InputNumber,
  Space,
} from '~/UI';
import { EnergyTerm, SolarAdvancedInputs, SolarProject } from '~/store/project';
import {
  TEST_DATA_COMPONENTS,
  TestIdProps,
  generateTestId,
} from '~/utils/dataTestProps';
import PROJECT_INPUT_CONFIG from '~/constants/inputs/project/projectInputs';
import { disabledDateByQuarter, isUserAdmin } from '~/utils';
import useAuth from '~/hooks/useAuth';
import SOLAR_INPUT_CONFIG from '~/constants/inputs/project/solarInputs';
import { StyledDatePicker } from '~/UI/DatePicker';
import { rackingTypes } from '~/utils/rackingTypes';
import { AddressSearchResponseType } from '~/types/addressSearchTypes';
import { useFlags } from '~/utils/launchdarkly';
import { GenericLabel, ProjectLocationLabel } from '../FormGroup';
import { StyledRadioButton, StyledRadioGroup } from '../shared/styles';
import { TaxCredits } from './EditComponents/TaxCredits';
import { EnergyTerms } from './EditComponents/EnergyTerms';

const StyledForm = styled(Form)`
  display: grid;
  padding-top: 20px;
  grid-template-columns: 2fr 1fr;
  .ant-input-number-disabled,
  .ant-radio-button-wrapper-disabled,
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: black;
  }
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 50%;
  }
`;

export const StyledFormHeader = styled.h2`
  font-weight: 600;
`;

export const StyledFormRadioGroup = styled(StyledRadioGroup)`
  .ant-radio-button-checked {
    background-color: #10697d1f;
    border: #70a5b1 !important;
  }
`;

export const StyledEnergyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    height: fit-content;
    font-size: 1.2em;
    padding: 0.6em 1em;
  }
`;

interface Props {
  project: SolarProject;
  enableAdvanceInputs: boolean;
  defaultAdvancedInputs: SolarAdvancedInputs;
  disabledAdvancedInputs?: boolean;
  form: FormInstance;
}

const SolarProjectEditForm = ({
  project,
  defaultAdvancedInputs,
  form,
}: Props) => {
  const { user } = useAuth();
  const flags = useFlags();
  // eslint-disable-next-line
  const [displayPricingBanner, setDisplayPricingBanner] = useState(false);
  const [locationError, setLocationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const maybeShowDisplayPricingBanner = () => {
    const projectSize = project.project_details.size_in_kw;
    const previousPurchaseVolumeKw = project.previous_purchase_volume_kw;
    const currentPurchaseVolume = form.getFieldValue(
      'current_purchase_volume_kw'
    );
    setDisplayPricingBanner(
      currentPurchaseVolume + previousPurchaseVolumeKw >= projectSize
    );
  };

  const handleSelectLocation = (location: AddressSearchResponseType) => {
    setLocationError('');
    if (location?.error?.message) {
      setLocationError(location?.error?.message);
      form.setFieldsValue({
        latitude: null,
        longitude: null,
        google_place_id: null,
        state: null,
      });
      form.validateFields(['project_address']);
      return;
    }
    form.setFieldsValue({
      project_address: location.address,
      latitude: location.lat,
      longitude: location.lng,
      google_place_id: location.placeId || '',
      state: location.state,
    });
    form.validateFields(['project_address']);
  };

  const disabledInputs =
    !isUserAdmin(user) && user.company?.id !== project.owner.domain.company.id;

  const testIdData = { component: TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER };

  const taxCredits = project?.tax_credits?.filter(
    (item) => item.credit_type !== 'ENERGY_TERM'
  );

  const isAdmin = isUserAdmin(user);
  const isEngagedSolar = user?.company?.engaged_solar;
  const isProjectOnProposal = project.project_details.status === 'proposal';

  const enableAdvanceInputs =
    (isEngagedSolar && isProjectOnProposal) || isAdmin;

  const currentEnergyTerms: EnergyTerm[] = useWatch('energy_terms', form);
  return (
    <StyledForm
      name="solar-project-details"
      form={form}
      initialValues={{
        name: project.name || '',
        project_address: project.project_details.project_address || '',
        arrival_date: project.project_details.arrival_date
          ? dayjs(project.project_details.arrival_date)
          : null,
        anticipated_purchase_date: project.project_details
          .anticipated_purchase_date
          ? dayjs(project.project_details.anticipated_purchase_date)
          : null,
        size_in_kw: project.project_details.size_in_kw,
        racking_type: project.project_details.racking_type,
        latitude: project.project_details.latitude,
        longitude: project.project_details.longitude,
        location_id: project.project_details.location_id,
        state: project.project_details.state,
        is_larger_purchase:
          !!project.project_details.current_purchase_volume_kw,
        current_purchase_volume_kw:
          project.project_details.current_purchase_volume_kw,
        baseline_ac_size_kW: project.advancedInputs?.baseline_ac_size_kW,
        target_rate_of_return_percent:
          project.advancedInputs?.target_rate_of_return_percent,
        energy_rate_value_USD_per_kWh:
          project.advancedInputs?.energy_rate_value_USD_per_kWh,
        energy_rate_escalator_percent:
          project.advancedInputs?.energy_rate_escalator_percent,
        project_term_years: project.advancedInputs?.project_term_years,
        baseline_ground_coverage_ratio:
          project.advancedInputs?.baseline_ground_coverage_ratio,
        tax_credits: taxCredits?.map((item) => ({
          base_credit: item.base_credit,
          credit_type: item.credit_type,
          escalation_rate: item.escalation_rate,
          project_id: item.project_id,
        })),
        is_itc: project.project_details.is_itc ? 'ITC' : 'PTC',
        energy_terms: project?.tax_credits
          ?.filter((item) => item.credit_type === 'ENERGY_TERM')
          .map((item) => ({
            base_credit: item.base_credit,
            credit_type: item.credit_type,
            duration: item.duration,
            escalation_rate: item.escalation_rate,
            project_id: item.project_id,
            start_year: item.start_year,
          })),
      }}
      layout="vertical"
      onBlur={maybeShowDisplayPricingBanner}
    >
      <StyledFormContainer>
        <StyledFormHeader>Project Details</StyledFormHeader>
        <StyledSpace size="xs">
          <Form.Item
            name="name"
            label={
              <GenericLabel
                testIdData={{
                  component: TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER,
                  identifier: 'project-name',
                }}
                title="Project Name"
                fontSize="14px"
              />
            }
            labelCol={{ span: 24 }}
            rules={PROJECT_INPUT_CONFIG.name.rules}
            required={false}
          >
            <Input
              {...PROJECT_INPUT_CONFIG.name.inputProps}
              project-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'project-name',
              })}
              disabled={disabledInputs}
            />
          </Form.Item>
          <Form.Item
            name="size_in_kw"
            label={
              <GenericLabel
                title="Project Size"
                testIdData={
                  {
                    ...testIdData,
                    identifier: 'project-size',
                  } as TestIdProps
                }
                fontSize="14px"
              />
            }
            rules={SOLAR_INPUT_CONFIG.sizeInKw.rules}
            required={false}
          >
            <InputNumber
              {...SOLAR_INPUT_CONFIG.sizeInKw.inputNumberProps}
              data-testid={generateTestId({
                component: `${testIdData.component}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'project-size',
              })}
              disabled={disabledInputs}
            />
          </Form.Item>
        </StyledSpace>

        <StyledSpace size="xs">
          <Form.Item
            name="arrival_date"
            label={
              <GenericLabel
                title="Delivery Date"
                popoverContent="The date you expect products to arrive onsite."
                testIdData={
                  { ...testIdData, identifier: 'delivery-date' } as TestIdProps
                }
                fontSize="14px"
              />
            }
            rules={PROJECT_INPUT_CONFIG.arrivalDate.rules}
            required={false}
          >
            <StyledDatePicker
              {...PROJECT_INPUT_CONFIG.arrivalDate.datePickerProps}
              disabled={disabledInputs}
              data-testid={generateTestId({
                component: `${testIdData.component}-${TEST_DATA_COMPONENTS.DATE_PICKER}`,
                identifier: 'delivery-date',
              })}
              disabledDate={disabledDateByQuarter}
              inputReadOnly
            />
          </Form.Item>
          <Form.Item
            name="anticipated_purchase_date"
            label={
              <GenericLabel
                title="Anticipated Purchase Date"
                popoverContent="The date you expect to execute a contract with Anza."
                testIdData={
                  {
                    ...testIdData,
                    identifier: 'anticipated-purchase-date',
                  } as TestIdProps
                }
                fontSize="14px"
              />
            }
            rules={PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyRules(
              () => form.getFieldsValue(['arrival_date']).arrival_date
            )}
            required={false}
          >
            <StyledDatePicker
              {...PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyDatePickerProps(
                () => form.getFieldsValue(['arrival_date']).arrival_date
              )}
              data-testid={generateTestId({
                component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.DATE_PICKER}`,
                identifier: 'anticipated-date',
              })}
              inputReadOnly
              disabled={disabledInputs}
            />
          </Form.Item>
        </StyledSpace>
        <StyledSpace size="xs">
          <Form.Item
            name="project_address"
            label={
              <ProjectLocationLabel
                testIdData={{
                  component: TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER,
                }}
                fontSize="14px"
              />
            }
            rules={PROJECT_INPUT_CONFIG.projectAddress.applyRules(
              isLoading,
              form,
              locationError
            )}
            required={false}
          >
            <AddressSearch
              disabled
              onSelectLocation={handleSelectLocation}
              isLocationLoading={setIsLoading}
              data-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.AUTOCOMPLETE}`,
                identifier: 'address-search',
              })}
              onValueChange={(value) => {
                if (value) {
                  form.setFieldsValue({
                    latitude: null,
                    longitude: null,
                    google_place_id: null,
                  });
                }
              }}
            />
          </Form.Item>
        </StyledSpace>
        <Form.Item
          name="racking_type"
          label={
            <GenericLabel
              testIdData={
                {
                  ...testIdData,
                  identifier: 'racking-type',
                } as TestIdProps
              }
              title="Racking Type"
              fontSize="14px"
            />
          }
          labelCol={{ span: 24 }}
          rules={SOLAR_INPUT_CONFIG.rackingType.rules}
          required={false}
        >
          <StyledFormRadioGroup
            buttonStyle="outline"
            size="large"
            disabled={disabledInputs}
            data-testid={generateTestId({
              component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.RADIO_GROUP}`,
              identifier: 'project-type',
            })}
          >
            {rackingTypes.map((rackingType, index) => (
              <StyledRadioButton
                value={rackingType}
                data-testid={generateTestId({
                  component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.RADIO_BUTTON}`,
                  identifier: 'racking-type',
                  indices: index.toString(),
                })}
                key={rackingType}
              >
                {rackingType}
              </StyledRadioButton>
            ))}
          </StyledFormRadioGroup>
        </Form.Item>

        <StyledSpace size="xs">
          <Form.Item
            name="baseline_ac_size_kW"
            initialValue={defaultAdvancedInputs?.baseline_ac_size_kW}
            label={
              <GenericLabel
                testIdData={
                  {
                    ...testIdData,
                    identifier: 'ac-size',
                  } as TestIdProps
                }
                title="AC Size"
                fontSize="14px"
                popoverContent="The combined power of inverters."
              />
            }
            labelCol={{ span: 24 }}
            rules={SOLAR_INPUT_CONFIG.advancedInputs.baseline_ac_size_kW.rules}
            required={false}
          >
            <InputNumber
              {...SOLAR_INPUT_CONFIG.advancedInputs.baseline_ac_size_kW
                .inputNumberProps}
              data-testid={generateTestId({
                component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'ac-size',
              })}
              disabled={disabledInputs || !enableAdvanceInputs}
            />
          </Form.Item>
          <Form.Item
            name="baseline_ground_coverage_ratio"
            initialValue={defaultAdvancedInputs?.baseline_ground_coverage_ratio}
            label={
              <GenericLabel
                testIdData={
                  {
                    ...testIdData,
                    identifier: 'ground-coverage-ratio',
                  } as TestIdProps
                }
                title="Ground Coverage Ratio"
                fontSize="14px"
                popoverContent="The % of ground area covered by PV modules."
              />
            }
            labelCol={{ span: 24 }}
            rules={
              SOLAR_INPUT_CONFIG.advancedInputs.baseline_ground_coverage_ratio
                .rules
            }
            required={false}
          >
            <InputNumber
              {...SOLAR_INPUT_CONFIG.advancedInputs
                .baseline_ground_coverage_ratio.inputNumberProps}
              data-testid={generateTestId({
                component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'ground-coverage-ratio',
              })}
              disabled={disabledInputs || !enableAdvanceInputs}
            />
          </Form.Item>
        </StyledSpace>

        <StyledSpace size="xs">
          <Form.Item
            name="target_rate_of_return_percent"
            initialValue={defaultAdvancedInputs?.target_rate_of_return_percent}
            label={
              <GenericLabel
                testIdData={
                  {
                    ...testIdData,
                    identifier: 'energy-rate-discount',
                  } as TestIdProps
                }
                title="Discount Rate"
                fontSize="14px"
                popoverContent="The Discount rate is applied to future cash flows to adjust for the time value of money. "
              />
            }
            labelCol={{ span: 24 }}
            rules={
              SOLAR_INPUT_CONFIG.advancedInputs.target_rate_of_return_percent
                .rules
            }
            required={false}
          >
            <InputNumber
              {...SOLAR_INPUT_CONFIG.advancedInputs
                .target_rate_of_return_percent.inputNumberProps}
              data-testid={generateTestId({
                component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'energy-rate-discount',
              })}
              disabled={disabledInputs || !enableAdvanceInputs}
            />
          </Form.Item>
          <Form.Item
            name="project_term_years"
            initialValue={defaultAdvancedInputs?.project_term_years}
            label={
              <GenericLabel
                testIdData={
                  {
                    ...testIdData,
                    identifier: 'project-term',
                  } as TestIdProps
                }
                title="Project Term"
                fontSize="14px"
                popoverContent="Number of years to include in the cashflow model"
              />
            }
            labelCol={{ span: 24 }}
            rules={SOLAR_INPUT_CONFIG.advancedInputs.project_term_years.generateRules(
              currentEnergyTerms
            )}
            required={false}
          >
            <InputNumber
              {...SOLAR_INPUT_CONFIG.advancedInputs.project_term_years
                .inputNumberProps}
              data-testid={generateTestId({
                component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'project-term',
              })}
              disabled={disabledInputs || !enableAdvanceInputs}
            />
          </Form.Item>
        </StyledSpace>

        <Divider />

        <TaxCredits
          form={form}
          testIdData={testIdData}
          disabledInputs={disabledInputs || !enableAdvanceInputs}
        />

        <Divider />
        {flags.energyTerms && (
          <EnergyTerms
            form={form}
            project={project}
            disableEdition={!enableAdvanceInputs}
          />
        )}
        <Form.Item
          name="state"
          className="no-padding no-border"
          labelCol={{ span: 24 }}
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="latitude"
          className="no-padding no-border"
          labelCol={{ span: 24 }}
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="longitude"
          className="no-padding no-border"
          labelCol={{ span: 24 }}
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="google_place_id"
          className="no-padding no-border"
          labelCol={{ span: 24 }}
          hidden
        >
          <Input />
        </Form.Item>
      </StyledFormContainer>
      {/* TODO: Bring back when is ready */}
      {/* <StyledNoteContainer>
        <NoteCard />
      </StyledNoteContainer> */}
    </StyledForm>
  );
};

export default SolarProjectEditForm;
