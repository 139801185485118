import * as R from 'ramda';
import { CompanyModulesDataWithYearsDisplayAndKey } from '~/components/SolarModules/ModulePricingFormGroup';
import { ModuleType, Quarter } from '~/types/modules';
import { FormInstance } from '~/UI';

export const addQuarterYearKeysToModulePricingData = (
  moduleData: ModuleType
) => {
  const moduleDataWithQuarterYearKeys = {
    ...moduleData,
  };

  const yearsToDisplay = new Set();
  moduleDataWithQuarterYearKeys.quarters = moduleData?.quarters?.map(
    (quarterPricingData: Quarter) => {
      let yearDisplayValue = quarterPricingData.year.toString();
      // Keep track of the years we are already displaying in the table, if we already have that year value in the table, clear out the display value.
      // This allows us to only render the year value in the table for the first quarter of that year, not all of them.
      if (yearsToDisplay.has(yearDisplayValue)) {
        yearDisplayValue = '';
      } else {
        yearsToDisplay.add(yearDisplayValue);
      }

      return {
        ...quarterPricingData,
        yearDisplay: yearDisplayValue,
        key: `q${quarterPricingData.quarter}-${quarterPricingData.year}`,
      };
    }
  );

  return moduleDataWithQuarterYearKeys;
};

export const getQuartersWithUpdates = (
  originalQuarterData: Quarter[],
  quarterDataToCompare: Quarter[]
) => {
  const quartersWithChanges: Quarter[] = [];

  quarterDataToCompare.forEach((quarter) => {
    const originalQuarter = originalQuarterData.find(
      (el) => el.key === quarter.key
    );

    if (
      !R.isNil(originalQuarter) &&
      (originalQuarter.availability_mw !== quarter.availability_mw ||
        originalQuarter.ddp_east_coast_port !== quarter.ddp_east_coast_port)
    ) {
      quartersWithChanges.push(quarter);
    }
  });

  return quartersWithChanges;
};

export const getModulePricingFromForm = (
  modulePricingDataWithQuarterYearKeys: CompanyModulesDataWithYearsDisplayAndKey,
  form: FormInstance
): CompanyModulesDataWithYearsDisplayAndKey => {
  const formValues = form.getFieldsValue();

  return {
    ...modulePricingDataWithQuarterYearKeys!,
    site_shipping_standard_adder: formValues.site_shipping_standard_adder,
    site_shipping_high_adder: formValues.site_shipping_high_adder,
    west_coast_port_adder: formValues.west_coast_port_adder,
    midwest_coast_port_adder: formValues.midwest_coast_port_adder,
    quarters: modulePricingDataWithQuarterYearKeys!.quarters!.map((quarter) => {
      const ddp_east_coast_port =
        formValues[`ddp_east_coast_port_${quarter.key}`];
      return {
        ...quarter,
        availability_mw: formValues[`availability_mw_${quarter.key}`],
        ddp_east_coast_port:
          typeof ddp_east_coast_port === 'number'
            ? ddp_east_coast_port.toFixed(4)
            : ddp_east_coast_port,
      };
    }),
  };
};
