import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';

import {
  Form,
  InputNumber,
  DatePicker,
  Option,
  FormItemLabel,
  FormInstance,
  ButtonAnt as Button,
} from '~/UI';
import { theme } from '~/utils';
import { rackingTypes } from '~/utils/rackingTypes';
import { disabledDateByQuarter } from '~/utils/quarter-utils';
import { ProjectDetails } from '~/store/project';
import { SelectFormItem } from '~/UI/Select';
import { BoxTitle } from '~/UI/Typography';
import { ROUTER_PATHS } from '~/router/Paths';
import { IconView } from '~/UI/Icons';
import SOLAR_INPUT_CONFIG from '~/constants/inputs/project/solarInputs';
import PROJECT_INPUT_CONFIG from '~/constants/inputs/project/projectInputs';

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 46px;
  .ant-picker-input input {
    height: 48px;
    color: ${theme.colors.charcoal};
    &::placeholder {
      color: ${theme.colors.stone};
    }
    font-size: 16px;
  }
`;

interface SolarDetailsFormGroupProps {
  form: FormInstance;
  projectStatus: ProjectDetails['status'];
  projectUuid: string;
}

const SolarDetailsFormGroup: React.FC<SolarDetailsFormGroupProps> = ({
  form,
  projectUuid,
}) => {
  const history = useHistory();
  const isLargePurchase = Form.useWatch<true | false>(
    'is_larger_purchase',
    form
  );

  useEffect(() => {
    if (R.is(Boolean, isLargePurchase) && !isLargePurchase) {
      // we need to make sure it's a boolean, otherwise we can override current_purchase_volume_kw
      form.setFieldValue('current_purchase_volume_kw', null);
    }
  }, [isLargePurchase]);

  const goToSolarProjectPage = () => {
    history.push(ROUTER_PATHS.projects.solar.PROJECT_DETAILS(projectUuid));
  };

  return (
    <div style={{ marginBottom: '2rem' }}>
      <BoxTitle style={{ display: 'flex', alignItems: 'center' }}>
        Solar Details
        <Button
          style={{ marginLeft: 12 }}
          size="small"
          onClick={goToSolarProjectPage}
        >
          <IconView />
        </Button>
      </BoxTitle>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: 'auto',
          gap: '1rem',
        }}
      >
        <Form.Item
          name="size_in_kw"
          label={<FormItemLabel>Project Size</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={SOLAR_INPUT_CONFIG.sizeInKw.rules}
        >
          <InputNumber
            name="size_in_kw"
            onBlur={() => form.validateFields(['current_purchase_volume_kw'])}
            {...SOLAR_INPUT_CONFIG.sizeInKw.inputNumberProps}
          />
        </Form.Item>
        <SelectFormItem
          name="racking_type"
          label={<FormItemLabel>Racking Type</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={SOLAR_INPUT_CONFIG.rackingType.rules}
          selectProps={SOLAR_INPUT_CONFIG.rackingType.selectProps}
        >
          {rackingTypes?.map((value) => (
            <Option value={value} key={`${Math.random()}`}>
              {value}
            </Option>
          ))}
        </SelectFormItem>
        <Form.Item
          name="arrival_date"
          label={<FormItemLabel>Delivery Date</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={PROJECT_INPUT_CONFIG.arrivalDate.rules}
        >
          <StyledDatePicker
            disabledDate={disabledDateByQuarter}
            name="arrival_date"
            {...PROJECT_INPUT_CONFIG.arrivalDate.datePickerProps}
          />
        </Form.Item>
        <Form.Item
          name="anticipated_purchase_date"
          label={<FormItemLabel>Anticipated Purchase Date</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyRules(
            () =>
              form.getFieldsValue(['arrival_date']).arrival_date || undefined
          )}
        >
          <StyledDatePicker
            name="anticipated_purchase_date"
            {...PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyDatePickerProps(
              () =>
                form.getFieldsValue(['arrival_date']).arrival_date || undefined
            )}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default SolarDetailsFormGroup;
