import React from 'react';
import DisplayWrapper from '~/components/DisplayWrapper';
import { GTM_SOURCE_TYPES } from '~/utils/gtm';
import {
  SolarProject,
  SolarProjectTransformedResultType,
  SolarRankingData,
} from '~/store/project';
import { AnalysisViewContext } from './SolarDetails';
import Ranking from '../ranking/Ranking';

type Props = Pick<SolarProjectTransformedResultType, 'engine'> & {
  hide: boolean;
  dataGTMId: string;
  isOpen: boolean;
  project: SolarProject;
  onEditProjectClick?: () => void;
  isLoading: boolean;
  disableEditProjectButton: boolean;
  rankingData: SolarRankingData[];
  onHideModule?: (uuid: string) => void;
  onUnhideModule?: (uuid: string) => void;
  resetFilters: () => void;
  currentQuarter: string;
};
const SolarModulesView = ({
  hide,
  dataGTMId,
  isOpen,
  engine,
  project,
  onEditProjectClick,
  isLoading,
  disableEditProjectButton,
  rankingData,
  onHideModule,
  onUnhideModule,
  resetFilters,
  currentQuarter,
}: Props) => {
  return (
    <DisplayWrapper
      hide={hide}
      data-gtm-type={GTM_SOURCE_TYPES.MODE}
      data-gtm-id={dataGTMId}
    >
      <AnalysisViewContext.Provider value={isOpen}>
        <Ranking
          engine={engine}
          project={project}
          onEditProjectClick={() => onEditProjectClick && onEditProjectClick()}
          rankingData={rankingData || []}
          loading={isLoading}
          disableEditButton={disableEditProjectButton}
          onHideModule={onHideModule}
          onUnhideModule={onUnhideModule}
          resetFilters={resetFilters}
          currentQuarter={currentQuarter}
        />
      </AnalysisViewContext.Provider>
    </DisplayWrapper>
  );
};

export default SolarModulesView;
