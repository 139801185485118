import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router';
import * as R from 'ramda';
import dayjs from 'dayjs';
import { message } from 'antd';
import styled from '@emotion/styled';
import { CompanyModulesData, QuartersData } from '~/services/api/anza';
import {
  addQuarterYearKeysToModulePricingData,
  getModulePricingFromForm,
  getQuartersWithUpdates,
} from '~/utils/modulePricingAvailability';
import { ModuleType, Quarter } from '~/types/modules';
import ModulePricingFormGroup, {
  CompanyModulesDataWithYearsDisplayAndKey,
} from '~/components/SolarModules/ModulePricingFormGroup';
import { catchError, GA, gaVendorEvent, theme } from '~/utils';
import { ButtonAnt as Button, Form, FormInstance } from '~/UI';
import { Box } from '~/components/Modules';
import useModulePricingFormGroupRules from '~/hooks/useModulePricingFormGroupRules';
import {
  useUpdateModulePricingMutation,
  useConfirmModulePricingMutation,
} from '~/store/api/vendor/vendorApi';

export interface ModulePricingProps {
  moduleData: ModuleType;
  onModuleChanged?: (data: CompanyModulesDataWithYearsDisplayAndKey) => void;
}

export interface ModulePricingCosts {
  site_shipping_high_adder: string;
  west_coast_port_adder: string;
  site_shipping_standard_adder: string;
  midwest_coast_port_adder: string;
}

interface ModulePricingData extends ModulePricingCosts {
  quarters: Quarter[];
}

interface ModulePricingFormData extends ModulePricingCosts {
  [key: string]: string | number | null;
}

const Container = styled(Box)`
  width: 100%;
  padding: 1rem 2rem;
  margin: 0 auto 1rem;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: right;
  align-items: end;
`;

const isModulePricingUpdateOverdue = (lastUpdatedDate: Date) => {
  if (R.isNil(lastUpdatedDate)) {
    return false;
  }
  const twoWeeksAgo = dayjs(new Date()).subtract(14, 'days');
  return twoWeeksAgo > dayjs(lastUpdatedDate);
};

const buildFormData = (modulePricingRecord: ModulePricingData) => {
  const formData: ModulePricingFormData = {
    site_shipping_high_adder: modulePricingRecord.site_shipping_high_adder,
    west_coast_port_adder: modulePricingRecord.west_coast_port_adder,
    site_shipping_standard_adder:
      modulePricingRecord.site_shipping_standard_adder,
    midwest_coast_port_adder: modulePricingRecord.midwest_coast_port_adder,
  };

  modulePricingRecord!.quarters!.forEach((quarter: Quarter) => {
    formData[`availability_mw_${quarter.key}`] = quarter.availability_mw;
    formData[`ddp_east_coast_port_${quarter.key}`] =
      quarter.ddp_east_coast_port;
  });
  return formData;
};

const ModulePricing: React.FC<ModulePricingProps> = ({
  moduleData,
  onModuleChanged,
}) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const modulePricingDataWithQuarterYearKeys =
    addQuarterYearKeysToModulePricingData(moduleData);
  const formData = buildFormData(modulePricingDataWithQuarterYearKeys);
  const [updateModulePricing] = useUpdateModulePricingMutation();
  const [confirmModulePricing] = useConfirmModulePricingMutation();

  useEffect(() => {
    form.validateFields();
  }, []);

  const [modulePricingData, setModulePricingData] = React.useState(
    modulePricingDataWithQuarterYearKeys
  );
  const [lastUpdated, setLastUpdated] = useState(
    modulePricingData.pricing_or_availability_last_updated_at ||
      modulePricingData.updated_at
  );
  const [isPricingOverdue, setIsPricingOverdue] = useState(
    isModulePricingUpdateOverdue(lastUpdated)
  );
  const checkForUnsavedChanges = (modulePricingForm: FormInstance) => {
    setHasUnsavedChanges(modulePricingForm.isFieldsTouched());
  };

  useEffect(() => {
    setIsPricingOverdue(isModulePricingUpdateOverdue(lastUpdated));
  }, [lastUpdated]);

  const getUpdatedModulePricingFromForm = () => {
    return getModulePricingFromForm(
      modulePricingDataWithQuarterYearKeys as unknown as CompanyModulesDataWithYearsDisplayAndKey,
      form
    );
  };

  const {
    priceRequiredFormNames,
    availabilityRequiredFormNames,
    onValuesChange: onModulePricingValuesChange,
  } = useModulePricingFormGroupRules();

  const onFormValuesChange = (
    changedValues: unknown,
    values?: Record<string, unknown> | null
  ) => {
    checkForUnsavedChanges(form);
    if (!R.isNil(onModuleChanged)) {
      onModuleChanged(getUpdatedModulePricingFromForm());
    }
    onModulePricingValuesChange(changedValues, values);
  };

  const saveModulePricing = async () => {
    setIsSaving(true);
    try {
      const updatedModulePricingData = getUpdatedModulePricingFromForm();
      const {
        west_coast_port_adder,
        midwest_coast_port_adder,
        site_shipping_high_adder,
        site_shipping_standard_adder,
      } = updatedModulePricingData;
      const adders: Partial<CompanyModulesData> = {
        west_coast_port_adder,
        midwest_coast_port_adder,
        site_shipping_high_adder,
        site_shipping_standard_adder,
      };
      const quarters = getQuartersWithUpdates(
        modulePricingData.quarters,
        updatedModulePricingData.quarters as unknown as Quarter[]
      ) as unknown as QuartersData[];

      const { uuid } = updatedModulePricingData;

      const result =
        R.isEmpty(quarters) && !hasUnsavedChanges
          ? await confirmModulePricing({ uuid }).unwrap()
          : await updateModulePricing({
              uuid,
              quarters,
              adders,
            }).unwrap();

      gaVendorEvent({
        action: GA.actions.CHANGE_MODULE_PRICES,
        label: uuid,
      });

      setLastUpdated(result.pricing_availability_confirmed_at);
      setHasUnsavedChanges(false);
      // Set the module pricing data state in case the user makes several saves. This will allow us to detect on the
      // changes that haven't been sent to the API.
      setModulePricingData(updatedModulePricingData as unknown as ModuleType);
    } catch (error) {
      catchError({
        error,
        method: 'saveModulePricing',
        location: 'pages/anza/ourmodules',
      });
      message.error(
        `An error happened while saving module ${modulePricingData.name} data. Please try again.`
      );
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Container>
      <Prompt
        when={hasUnsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Form
        form={form}
        name={`module-pricing-${modulePricingData.uuid}`}
        initialValues={formData}
        onValuesChange={onFormValuesChange}
        onFinish={saveModulePricing}
      >
        <ModulePricingFormGroup
          moduleData={
            modulePricingData as unknown as CompanyModulesDataWithYearsDisplayAndKey
          }
          lastUpdated={lastUpdated}
          isActive={!modulePricingData.is_anza_deactivated}
          priceRequiredFormNames={priceRequiredFormNames}
          availabilityRequiredFormNames={availabilityRequiredFormNames}
          form={form}
        />
        <ButtonRow style={{ gridColumn: 2 }}>
          <Button
            data-testid="confirm-pricing-availability"
            loading={isSaving}
            disabled={isSaving}
            color={
              isPricingOverdue ? theme.colors.warning : theme.colors.primary
            }
            style={{ minWidth: '250px' }}
            htmlType="submit"
          >
            {hasUnsavedChanges
              ? 'Save & Confirm'
              : 'Confirm Pricing and Availability'}
          </Button>
        </ButtonRow>
      </Form>
    </Container>
  );
};

export default ModulePricing;
