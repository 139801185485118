import React, { useState } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import * as R from 'ramda';

import { AxiosCrudV2 } from '~/api/axios';
import { routes } from '~/api/routes';
import {
  ButtonAnt as Button,
  notification,
  Switch,
  Overlay,
  Spinner,
  DownloadButton,
  Row,
  ModalAnt as Modal,
} from '~/UI';
import { IconAdd, IconDownload, IconClose } from '~/UI/Icons';
import { booleanColumnSorter, columnSorter, theme } from '~/utils';
import { currencyFormatter } from '~/utils/formatters';
import {
  generateTestId,
  TEST_DATA_PAGES,
  TEST_DATA_CATEGORIES,
  TEST_DATA_COMPONENTS,
  TestIdProps,
} from '~/utils/dataTestProps';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import { H2 } from '~/UI/Typography';
import { ROUTER_PATHS } from '~/router/Paths';
import { AdminListPageProps } from '~/types/admin';
import { useUpdateModuleMutation } from '~/store/api';
import type { ModuleType, Quarter } from '~/types/modules';

import AdminTable, { Column } from '../AdminTable/AdminTable';
import { AdminModuleUpdateLog } from './AdminModuleUpdateLog';

const dataTestIdConfig: TestIdProps = {
  category: TEST_DATA_CATEGORIES.ADMIN,
  page: TEST_DATA_PAGES.ADMIN.MODULES.LIST,
  component: '',
};

const renderCurrencyData = (value: string) => {
  const quarterValue = currencyFormatter(+value, 4, 4);
  if (!quarterValue) return 'N/A';
  return quarterValue;
};

const renderAvailabilityData = (value: number) => {
  const quarterValue = typeof value === 'number' ? value.toFixed(1) : null;
  if (!quarterValue) return 'N/A';
  return `${quarterValue} MW`;
};

const StyledTabelColumnTitle = styled(
  ({
    className,
    title,
    subTitle,
  }: {
    className?: string;
    title: string;
    subTitle: string;
  }) => (
    <span className={className}>
      <span className="title">{title}</span>
      <span>{subTitle}</span>
    </span>
  )
)`
  .title {
    display: block;
    white-space: nowrap;
  }
`;

const CloseIcon = styled(() => (
  <IconClose
    data-testid={generateTestId({
      ...dataTestIdConfig,
      component: TEST_DATA_COMPONENTS.NOTIFICATION,
      identifier: 'close',
    })}
  />
))``;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const renderQuarterColumnTitle = (key: string, subTitle: string) => {
  const renderedKey = key.split('-');
  return (
    <StyledTabelColumnTitle
      title={`Q${renderedKey[1]}-${renderedKey[0]}`}
      subTitle={subTitle}
    />
  );
};

const getYearAndQuarter = ({ year, quarter }: Quarter) => `${year}-${quarter}`;

const findQuarterData = (record: ModuleType, yearAndQuarter: string) =>
  record.quarters.find((value) => getYearAndQuarter(value) === yearAndQuarter);

type ModuleColumn = Column<ModuleType>;

const AdminModulesList: React.FC<AdminListPageProps<ModuleType>> = (props) => {
  const { service, history } = props;
  const [refresh, setRefresh] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isReranking, setIsReranking] = useState(false);
  const [isUpdateLogModalVisible, setIsUpdateLogModalVisible] = useState(false);
  const [records, setRecords] = useState<ModuleType[]>([]);
  const [updateModule] = useUpdateModuleMutation();

  const showUpdateLogModal = () => {
    setIsUpdateLogModalVisible(true);
  };

  const handleCancel = () => {
    setIsUpdateLogModalVisible(false);
  };

  const BifacialOptionMapper = {
    true: 'True',
    false: 'False',
  };

  const downloadPanFile = (_: any, record: ModuleType) => {
    const { signed_download_url, pan_file_model_name } = record;
    if (!signed_download_url) return null;
    return (
      <a href={signed_download_url}>
        <DownloadButton
          type="link"
          tooltipText={pan_file_model_name}
          data-testid={generateTestId({
            ...dataTestIdConfig,
            component: TEST_DATA_COMPONENTS.BUTTON,
            identifier: 'download-pan',
          })}
        >
          {pan_file_model_name}
          <IconDownload />
        </DownloadButton>
      </a>
    );
  };

  const downloadJsonFileButton = () => {
    if (!records || records.length === 0) {
      return <></>;
    }
    const href = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(records)
    )}`;
    const filename = `modules.json`;
    return (
      <a href={href} download={filename}>
        <Button
          color={theme.colors.gray}
          data-testid={generateTestId({
            ...dataTestIdConfig,
            component: TEST_DATA_COMPONENTS.BUTTON,
            identifier: 'download-data',
          })}
        >
          <span style={{ color: theme.colors.black }}>
            {' '}
            Download Module Data
          </span>
        </Button>
      </a>
    );
  };

  const mainColumns: ModuleColumn[] = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: '200px',
      isSortable: true,
      isFilterable: true,
      render: R.propOr('', 'name'),
      fixed: 'left',
      defaultSortOrder: 'ascend',
      sorter: columnSorter(['company', 'name']),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      isSortable: true,
      isFilterable: true,
      fixed: 'left',
      width: '250px',
      defaultSortOrder: 'ascend',
      sorter: columnSorter(['name']),
    },
    {
      title: 'Watts',
      dataIndex: 'watts',
      key: 'watts',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['watts']),
    },
  ];

  const columns: ModuleColumn[] = [
    {
      title: 'Shipping High Adder',
      dataIndex: 'site_shipping_high_adder',
      key: 'site_shipping_high_adder',
      isSortable: true,
      width: '250px',
      sorter: columnSorter(['site_shipping_high_adder']),
    },
    {
      title: 'Shipping Standard Adder',
      dataIndex: 'site_shipping_standard_adder',
      key: 'site_shipping_standard_adder',
      isSortable: true,
      width: '250px',
      sorter: columnSorter(['site_shipping_standard_adder']),
    },
    {
      title: 'Midwest Port Adder',
      dataIndex: 'midwest_coast_port_adder',
      key: 'midwest_coast_port_adder',
      isSortable: true,
      width: '250px',
      sorter: columnSorter(['midwest_coast_port_adder']),
    },
    {
      title: 'West Port Adder',
      dataIndex: 'west_coast_port_adder',
      key: 'west_coast_port_adder',
      isSortable: true,
      width: '250px',
      sorter: columnSorter(['west_coast_port_adder']),
    },
    {
      title: 'VOC',
      dataIndex: 'voc',
      key: 'voc',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['voc']),
    },
    {
      title: 'Efficiency',
      dataIndex: 'efficiency',
      key: 'efficiency',
      isSortable: true,
      width: '150px',
      render: R.pipe(
        Number,
        new Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format
      ),
      sorter: columnSorter(['efficiency']),
    },
    {
      title: 'Frame Width',
      dataIndex: 'frame_width',
      key: 'frame_width',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['frame_width']),
    },
    {
      title: 'Short Edge',
      dataIndex: 'short_edge',
      key: 'short_edge',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['short_edge']),
    },
    {
      title: 'Long Edge',
      dataIndex: 'long_edge',
      key: 'long_edge',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['long_edge']),
    },
    {
      title: 'Temperature Coefficient of Voltage',
      dataIndex: 'temperature_coeffient_voltage',
      key: 'temperature_coeffient_voltage',
      isSortable: true,
      width: '250px',
      sorter: columnSorter(['temperature_coeffient_voltage']),
    },
    {
      title: 'Warrantied Annual Degradation (%)',
      dataIndex: 'yearly_degradation',
      key: 'yearly_degradation',
      isSortable: true,
      width: '350px',
      render: R.pipe(
        Number,
        new Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format
      ),
      sorter: columnSorter(['yearly_degradation']),
    },
    {
      title: 'Is Bifacial',
      dataIndex: 'is_bifacial',
      key: 'is_bifacial',
      isSortable: true,
      width: '250px',
      render: (status: string) =>
        R.propOr(status, status, BifacialOptionMapper),
      sorter: booleanColumnSorter(['is_bifacial']),
    },
    {
      title: 'PAN File Manufacturer',
      dataIndex: 'pan_file_manufacturer',
      key: 'pan_file_manufacturer',
      isSortable: true,
      width: '250px',
      sorter: columnSorter(['pan_file_manufacturer']),
    },
    {
      title: 'PAN File Model Name',
      dataIndex: 'pan_file_model_name',
      key: 'pan_file_model_name',
      isSortable: true,
      width: '250px',
      sorter: columnSorter(['pan_file_model_name']),
    },
    {
      title: 'PAN File Lid Loss',
      dataIndex: 'pan_file_lid_loss',
      key: 'pan_file_lid_loss',
      isSortable: true,
      width: '250px',
      sorter: columnSorter(['pan_file_lid_loss']),
    },
    {
      title: 'PAN File Module Quality Loss',
      dataIndex: 'pan_file_module_quality_loss',
      key: 'pan_file_module_quality_loss',
      isSortable: true,
      width: '250px',
      sorter: columnSorter(['pan_file_module_quality_loss']),
    },
    {
      title: 'PAN File Cell Cut',
      dataIndex: 'pan_file_cell_cut',
      key: 'pan_file_cell_cut',
      isSortable: true,
      width: '250px',
      sorter: columnSorter(['pan_file_cell_cut']),
    },
    {
      title: 'PAN File',
      dataIndex: 'signed_download_url',
      key: 'signed_download_url',
      width: '200px',
      className: 'download',
      render: downloadPanFile,
    },
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
      isSortable: true,
      width: '350px',
      sorter: columnSorter(['uuid']),
    },
    {
      title: 'Last Updated',
      dataIndex: 'pricing_or_availability_last_updated_at',
      key: 'pricing_or_availability_last_updated_at',
      isSortable: true,
      width: '150px',
      render: (date: string) =>
        date ? dayjs(date, 'YYYY/MM/DD').format('MM/DD/YYYY') : '',
      sorter: columnSorter(['pricing_or_availability_last_updated_at']),
    },
  ];

  const anzaDeactivationHandler = async (id: number, status: boolean) => {
    setIsLoading(true);
    await updateModule({
      route: `${id}/${status ? 'anza-activate' : 'anza-deactivate'}`,
    })
      .unwrap()
      .then(() => setRefresh(refresh + 1))
      .catch((err) => {
        if (err?.status === 422) {
          notification.error({
            message: 'There was an error activating this module',
            description:
              'We need additional information in order to activate the module',
          });
        } else {
          notification.error({
            message: 'There was an error activating this module',
            description: err.data.message,
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  const anzaSwitch = ({
    id,
    is_anza_deactivated,
  }: {
    id: number;
    is_anza_deactivated: boolean;
  }) => {
    return (
      <Switch
        checked={!is_anza_deactivated}
        onChange={() => anzaDeactivationHandler(id, is_anza_deactivated)}
        data-testid={generateTestId({
          ...dataTestIdConfig,
          component: TEST_DATA_COMPONENTS.SWITCH,
          indices: id.toString(),
        })}
      />
    );
  };

  const deactivationColumns: Column[] = [
    {
      title: 'Is Active',
      dataIndex: '',
      key: 'id',
      width: '120px',
      isSortable: true,
      onFilter: (
        value: string | number | boolean,
        record: { is_anza_deactivated: boolean }
      ) => {
        return R.equals(
          R.toLower(value.toString()),
          R.isNil(record.is_anza_deactivated)
            ? ''
            : R.toString(record.is_anza_deactivated)
        );
      },
      filters: [
        {
          text: 'Active',
          value: 'false',
        },
        {
          text: 'Not Active',
          value: 'true',
        },
      ],
      render: anzaSwitch,
      fixed: 'right',
      sorter: booleanColumnSorter(['is_anza_deactivated']),
    },
  ];

  const [updatedColumns, setUpdatedColumns] = useState([
    ...mainColumns,
    ...columns,
    ...deactivationColumns,
  ]);

  const generateQuarterlyAlternateColumns = (quarters: string[]) => {
    const priceColumns: ModuleColumn[] = [];
    const availabilityColumns: ModuleColumn[] = [];
    quarters.forEach((value) => {
      priceColumns.push({
        title: renderQuarterColumnTitle(value, 'Price'),
        dataIndex: 'quarters',
        key: `quarters-${value}`,
        render: (_, record) =>
          renderCurrencyData(
            findQuarterData(record, value)?.ddp_east_coast_port as string
          ),
        isSortable: true,
        width: '100px',
      });
      availabilityColumns.push({
        title: renderQuarterColumnTitle(value, 'Availability'),
        dataIndex: 'quarters',
        key: `quarters-availability_mw-${value}`,
        render: (_, record) =>
          renderAvailabilityData(
            findQuarterData(record, value)?.availability_mw as number
          ),
        isSortable: true,
        width: '120px',
      });
    });
    return [...priceColumns, ...availabilityColumns];
  };

  const addColumnsFromData = (data: ModuleType[]) => {
    const projectsQuarters = data.map(R.prop('quarters')).flat();
    const quarters = projectsQuarters.map(
      ({ year, quarter }: { year: number; quarter: number }) =>
        `${year}-${quarter}`
    );
    const uniqueQuarters = R.uniq(quarters);
    const sortedQuarters = R.sortWith(
      [R.ascend((quarter) => quarter)],
      uniqueQuarters as string[]
    );

    setUpdatedColumns([
      ...mainColumns,
      ...generateQuarterlyAlternateColumns(sortedQuarters),
      ...columns,
      ...deactivationColumns,
    ]);
  };

  const handleViewLog: React.MouseEventHandler<HTMLButtonElement> = () => {
    showUpdateLogModal();
  };

  const triggerModuleReranking: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    setIsReranking(true);
    try {
      await AxiosCrudV2(routes.admin.triggerEngineRuns()).create(null);
      notification.success({
        message:
          'Engine Runs Triggered! Please note these can take some time to complete',
        closeIcon: <CloseIcon />,
        props: {
          'data-testid': generateTestId({
            ...dataTestIdConfig,
            component: TEST_DATA_COMPONENTS.NOTIFICATION,
            identifier: 'completed',
          }),
        },
      });
    } catch (error) {
      notification.error({
        message: 'Module Reranking could not be performed',
        closeIcon: <CloseIcon />,
        props: {
          'data-testid': generateTestId({
            ...dataTestIdConfig,
            component: TEST_DATA_COMPONENTS.NOTIFICATION,
            identifier: 'failed',
          }),
        },
      });
    } finally {
      setIsReranking(false);
    }
  };

  const generateRowTestId = (_: any, index: number) => ({
    'data-testid': generateTestId({
      ...dataTestIdConfig,
      component: TEST_DATA_COMPONENTS.TABLE_ROW,
      indices: index.toString(),
    }),
  });

  return (
    <>
      <div>
        {isLoading && (
          <Overlay style={{ position: 'fixed', height: '100vh', zIndex: 9999 }}>
            <Spinner />
          </Overlay>
        )}
        <Row justify="space-between">
          <H2>{props.pageName}</H2>
          <ButtonRow>
            <Button
              color={theme.colors.gray}
              onClick={handleViewLog}
              data-testid={generateTestId({
                ...dataTestIdConfig,
                component: TEST_DATA_COMPONENTS.BUTTON,
                identifier: 'update',
              })}
            >
              <span style={{ color: theme.colors.black }}>Update Log</span>
            </Button>
            {downloadJsonFileButton()}
            <Button
              color={theme.colors.gray}
              onClick={triggerModuleReranking}
              loading={isReranking}
              data-testid={generateTestId({
                ...dataTestIdConfig,
                component: TEST_DATA_COMPONENTS.BUTTON,
                identifier: 'rerank',
              })}
            >
              <span style={{ color: theme.colors.black }}>
                Trigger Engine Runs
              </span>
            </Button>
            <Button
              onClick={() => history.push(ADMIN_ROUTES.MODULES.path.new)}
              data-testid={generateTestId({
                ...dataTestIdConfig,
                component: TEST_DATA_COMPONENTS.BUTTON,
                identifier: 'new',
              })}
            >
              <IconAdd style={{ marginRight: '0.25rem' }} /> New Module
            </Button>
          </ButtonRow>
        </Row>

        <AdminTable<ModuleType>
          refresh={refresh}
          service={service}
          columns={updatedColumns}
          setColumnsWithData={addColumnsFromData}
          onRow={generateRowTestId}
          customActions={{
            view: {
              onHref: (module) =>
                ROUTER_PATHS.modules.MODULE_DETAILS(module.uuid),
            },
            edit: {
              onHref: (module) =>
                ADMIN_ROUTES.MODULES.path.edit(module.id.toString()),
            },
          }}
          onDataChange={(result) => setRecords(result)}
          testIdCategory={dataTestIdConfig.category}
          testIdPage={dataTestIdConfig.page}
        />
      </div>
      <Modal
        title="Update Log"
        open={isUpdateLogModalVisible}
        destroyOnClose
        width="max-content"
        footer={null}
        onCancel={handleCancel}
      >
        <AdminModuleUpdateLog />
      </Modal>
    </>
  );
};

export default AdminModulesList;
