import { Rule } from 'antd/lib/form';
import { DDP_EAST_USD_PER_W_MIN } from '~/constants/projects';
import { InputNumberConfig } from '~/types/inputs';
import { inRangeValidator } from '~/utils/numberValidators';

const custom_price: InputNumberConfig = {
  rules: [
    inRangeValidator(
      DDP_EAST_USD_PER_W_MIN.min,
      DDP_EAST_USD_PER_W_MIN.max,
      true,
      `Introduce a value between ${DDP_EAST_USD_PER_W_MIN.min} and ${DDP_EAST_USD_PER_W_MIN.max}`,
      false
    ),
  ],
  inputNumberProps: {
    name: 'custom_price',
    precision: 3,
    suffix: '$/W',
  },
};

const PSP_INPUT_CONFIG = { custom_price };

export default PSP_INPUT_CONFIG;
