import { useFlags as useFlagsLD } from 'launchdarkly-react-client-sdk';

export const useFlags = useFlagsLD<{
  acBlock: boolean;
  dcBlockPricing: boolean;
  maintenanceMode: boolean;
  persistentFilters: boolean;
  pricingEngine: boolean;
  solarPricingPolicy: boolean;
  storageModule: boolean;
  storageEngineVersion: string;
  moduleCardRowMenu: boolean;
  taxCredit: boolean;
  pricingPolicyAnnouncement: boolean;
  solarPpuBanner: boolean;
  solarPricingVisualization: boolean;
  energyTerms: boolean;
  storageFilters: boolean;
  solarFilters: boolean;
  marketIntelVisualization: boolean;
  projectSpecificPricingV2: boolean;
}>;
